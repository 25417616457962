<template>
  <v-card flat>
    <v-row no-gutters class="mt-5">
      <v-col cols="12" class="d-flex justify-space-between">
        <v-btn class="actionButton" x-large icon @click="$router.push('/')">
          <v-icon color="#343434">mdi-chevron-left</v-icon>
        </v-btn>
        <user-profilepicture
          class="mt-5"
          @updatedPicture="updateUserPicture"
          :width="120"
          :height="120"
          v-if="user"
          :picture="user.bild"></user-profilepicture>
        <user-menu v-if="user" :user="user"></user-menu>
      </v-col>

      <v-col cols="12" class="d-flex justify-center">
        <v-card-title class="font-weight-bold h5 mt-2" v-if="user">
          {{ user.vorname }} {{ user.nachname }}
          <v-icon class="pl-2" color="primary">mdi-check-decagram</v-icon>
        </v-card-title>
      </v-col>
      <v-col cols="12" class="mb-5">
        <v-row no-gutters class="mt-0"><user-training-stats :user="user"></user-training-stats></v-row>
      </v-col>
      <v-col cols="12">
        <v-btn-toggle v-model="selected" mandatory class="custom-toggle">
          <v-btn
            v-for="(item, index) in items"
            :key="item"
            class="custom-btn rounded-lg mx-1"
            :disabled="item === 'Vertrag' && !transactions && !nutrition">
            <span :class="{ 'custom-toggle--active': index === selected }" style="z-index: 100">
              {{ item }}
            </span>
          </v-btn>
          <span class="highlight" :class="{ left: selected === 0, right: selected === 1 }"></span>
        </v-btn-toggle>
        <v-tabs-items classt="mt-2" v-model="selected">
          <v-tab-item v-for="(item, index) in items" :key="index + 'tab'" :value="index">
            <user-profile-training-card v-if="item === 'Training'" :user="user"></user-profile-training-card>
            <user-profile-contract-card
              v-if="item === 'Vertrag'"
              :transactions="transactions"
              :nutrition="nutrition"
              :user="user"
              :contractInformations="contractInformations.MemberDetail"></user-profile-contract-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      <v-col v-if="transactions && false" class="justify-content-center text-center" cols="12">
        <!-- @click="$router.push({ name: 'userPayments' })" -->
        <v-chip x-large color="primary" class="font-weight-bold">
          Badge Guthaben: {{ transactions.balance ? formatCurrency1(transactions.balance) : 0 }} CHF
        </v-chip>
      </v-col>

      <v-col cols="12" class="py-0 my-0">
        <payment-home></payment-home>
      </v-col>
    </v-row>
    <v-dialog v-model="showContractCreatedDialog" transition="dialog-bottom-transition" max-width="400">
      <v-card>
        <v-toolbar color="primary" dark>Neuer Vertrag</v-toolbar>
        <v-card-text class="mt-5 pb-0">
          Hallo {{ user.vorname }}, super, dein Vertrag wurde erstellt! Im nächsten Schritt wird dieser nun von unserer Administration überprüft und
          dann bist du bereits ganz offiziell Teil der {{ studio.art.replace("AG", "") }}-Community! Du erhältst umgehend nach der Bearbeitung
          Bescheid.
          <br />
          <br />
          <span v-if="referralActive">
            In der Zwischenzeit kannst du dich bereits mit unserer Trainings App roody vertraut machen oder vielleicht hier sogar einen Freund, eine
            Freundin oder ein Familienmitglied zu einem 10tägigen kostenlosen Training einladen, womit du dir bei einem Vertragsabschluss sofort CHF
            {{ leadSetting.price.value }}.- bar auf die Hand sichern kannst.
            <br />

            <v-btn color="primary" class="my-2 mx-auto align-center" @click="$router.push('/referral')"> Jetzt weiterempfehlen </v-btn>
          </span>
          <br />
          Bei Fragen zum Vertrag erreichst du uns unter {{ studio.telefon }} oder schreibe uns eine Email an {{ studio.email }}.
          <br />
          <br />
          Bis bald im {{ studio.art.replace("AG", "") }}
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="showContractCreatedDialog = false">Schliessen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog overlay-opacity="0.8" max-width="500" :value="forceVerifyEmail" persistent>
      <v-card>
        <v-card-title class="primary white--text">Verifiziere deine E-Mail</v-card-title>
        <v-btn absolute top right icon color="white" @click="logout">
          <v-icon>mdi-logout-variant</v-icon>
        </v-btn>
        <verify-email @verifying="verifying = true" @emailVerified="forceVerifyEmail = false"></verify-email>
        <v-card-actions class="justify-content-center">
          <v-btn color="primary" v-if="!verifying" @click="$router.push('/')">Zur Startseite</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex"
import UserMenu from "@/views/user/components/UserMenu.vue"
import UserProfilepicture from "@/views/user/components/UserProfilepicture.vue"
import UserWeightCard from "@/views/user/components/UserWeightCard.vue"
import UserCheckinCard from "@/views/user/components/UserCheckinCard.vue"
import UserTrainingStats from "@/views/user/components/UserTrainingStats.vue"
import PaymentHome from "@/views/dashboard/payment/PaymentHome.vue"
import PurchasesProfileButton from "@/views/shop/PurchasesProfileButton.vue"
import VerifyEmail from "@/components/verification/verifyEmail.vue"
import UserPayments from "./views/UserPayments.vue"
import UserProfileTrainingCard from "./views/UserProfileTrainingCard.vue"
import UserProfileContractCard from "./views/UserProfileContractCard.vue"
import { formatCurrency } from "@/moduls/currency/currency.js"

import api from "@/api"
export default {
  name: "UserProfile",

  data() {
    return {
      selected: 1,
      items: ["Vertrag", "Training"],
      verifying: false,
      forceVerifyEmail: false,
      showContractCreatedDialog: false,
      user: null,
      userBackup: null,
      referralActive: true,
      leadSetting: { price: { value: 0 } },
      studio: {
        art: "Fitness",
        telefon: "",
        email: "",
      },
      transactions: null,
      nutrition: null,
      transactionsDialog: false,
      contractInformations: {},
    }
  },
  components: {
    UserProfileTrainingCard,
    UserProfileContractCard,
    UserProfilepicture,
    UserWeightCard,
    UserCheckinCard,
    UserTrainingStats,
    UserMenu,
    PaymentHome,
    PurchasesProfileButton,
    VerifyEmail,
    UserPayments,
  },
  mounted() {
    if (this.navigationVisible) this.toggleNavigationBarVisibility()
  },
  beforeDestroy() {
    if (!this.navigationVisible) this.toggleNavigationBarVisibility()
  },
  async created() {
    let status = await api.fetchUserEmailVerificationStatus()
    await this.fetchUser()
    if (status.verified) {
      this.fetchNutrition()
      let fetchedTrans = await api.fetchAccountTransactionsForUser()
      if (fetchedTrans.error) {
        alert(
          "Hoppla! Wir haben leider keine Mitgliedschaft für die E-Mail-Adresse: " +
            this.user.email +
            " gefunden. Das könnte daran liegen, dass du in der roody App vielleicht eine andere E-Mail-Adresse verwendet hast als die, die in deinem Vertrag hinterlegt ist.  Schnapp dir einfach die Trainer in deinem Studio, die bringen das im Handumdrehen in Ordnung."
        )
      } else {
        this.transactions = fetchedTrans
      }
      this.contractInformations = await api.fetchTACMemberOwnContract()
    } else {
      // ÄNDERN UNBEDING!!
      this.forceVerifyEmail = true
    }
    this.fetchStudioAndReferral()
    if (this.$route.query.contractCreated) {
      setTimeout((e) => {
        this.showContractCreatedDialog = true
      }, 3000)
    }
  },

  methods: {
    ...mapActions("navigationStoreNotPersisted", ["toggleNavigationBarVisibility", "setNavigationBarText", "setNavigationbarVisibility"]),
    async fetchNutrition() {
      try {
        let nutritionContract = await api.fetchOwnNutritionContract()
        if (nutritionContract.verified && nutritionContract.nutrition) {
          this.nutrition = nutritionContract.nutrition
          console.log(this.nutrition)
        }
      } catch (e) {
        console.log(e)
        console.log("Error fetching nutrition contract")
      }
    },
    logout() {
      if (confirm("Möchtest du dich wirklich ausloggen?") === false) return
      localStorage.removeItem("token")
      localStorage.removeItem("user")

      location.reload()
    },
    formatCurrency1(value) {
      return formatCurrency(value)
    },
    newTargetWeight(val) {
      this.user.targetWeight = val
    },

    updateUserPicture(name) {
      this.user.bild = name
    },
    async fetchStudioAndReferral() {
      let studio = await api.getUserStudio()
      studio = studio.studio
      this.studio = studio
      if (studio && studio.leadSetting && studio.leadSetting.active) {
        this.leadSetting = studio.leadSetting
      } else {
        this.referralActive = false
      }
    },
    async fetchUser() {
      try {
        let user = await api.getUser()
        this.user = user.user

        localStorage.setItem("user", JSON.stringify({ user: user.user }))

        this.userBackup = JSON.parse(JSON.stringify(user.user))
      } catch (e) {
        console.log(e)
        this.$toast.error("Fehler beim Laden")
      }
    },
  },
  computed: {
    ...mapState("navigationStoreNotPersisted", ["navigationItems", "navigationVisible"]),
    getStudioName() {
      let name = "Fitness"
      if (this.user && this.user.studio_id) {
        if (this.user.studio_id === "pfungen" || this.user.studio_id === "winterthur") name = "winti FIT"
        else name = "well come FIT"
      }
      return name
    },
    userAge() {
      let age = "-"
      if (this.user && this.user.geburtstag) {
        age = this.$moment().diff(this.$moment(this.user.geburtstag), "years")
      }
      return age
    },
    bmiValue() {
      return ((Number(this.userWeight) / (Number(this.user.groesse) * Number(this.user.groesse))) * 10000).toFixed(2)
    },
    bmiColor() {
      if (this.bmiValue < 18.5) return "color: 'cornflowerblue';"
      if (this.bmiValue < 24) return " color: 'limegreen'; "
      if (this.bmiValue < 30) return " color: 'green'; "
      if (this.bmiValue < 35) return " color: 'orange'; "
      if (this.bmiValue < 40) return " color: 'darkgoldenrod'; "
      return "color: 'red'; "
    },
    userWeight() {
      if (this.user.weights && this.user.weights.length) return this.user.weights[this.user.weights.length - 1].value
      if (this.user.gewicht) {
        return this.user.gewicht
      }
      return 0
    },
  },
}
</script>

<style scoped lang="scss">
.actionButton.v-btn--outlined {
  // border: thin solid #8888888a;
}

.button-switcher {
  position: relative;
  width: 300px;
  /* Adjust the width as needed */
}

.custom-toggle {
  position: relative;
  width: 100%;
}

.custom-btn {
  flex: 1 1 50%;
  color: #000;
  /* Text color */
  font-weight: bold;
  transition: color 0.3s;
}

.highlight {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 50%;
  background-color: #656565;
  /* Highlight color */
  border-radius: 8px;
  transition: all 0.3s ease;
}

.highlight.right {
  left: 50%;
}

.highlight.left {
  left: 0;
}

.custom-toggle--active {
  color: #fff !important;
  /* Active text color */
}

::v-deep .slim-btn-edit {
  display: none;
}
</style>
