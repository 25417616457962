<template>
    <!-- Dialog, wird angezeigt sobald fetchedPopups nicht leer ist -->
    <v-dialog v-model="popupDialog" max-width="600px" persistent>
        <v-card max-height="80vh" class="d-flex flex-column">
            <!-- Carousel wenn nur Kurztext angezeigt werden soll -->
            <v-carousel height="100%" v-if="!showFullText && fetchedPopups.length > 0"
                :show-arrows="fetchedPopups.length > 1" v-model="currentPopupIndex" hide-delimiters cycle="false"
                prev-icon="mdi-chevron-left" next-icon="mdi-chevron-right">
                <v-carousel-item v-for="(popup, index) in fetchedPopups" :key="index">
                    <div class="text-center">
                        <img v-if="popup.image && popup.image.length > 2"
                            :src="'https://app.wellcomefit.ch/img/' + popup.image" class="w-100" />
                        <v-card-title class="pt-5 pb-0">{{ popup.title }}</v-card-title>
                        <v-card-text>
                            <div v-html="getShortText(popup.text)" />
                        </v-card-text>
                    </div>
                </v-carousel-item>
            </v-carousel>

            <!-- Volltext Ansicht -->
            <div v-else-if="showFullText && fetchedPopups.length > 0" class="flex-grow-1"
                style="overflow-y: auto; padding: 16px">
                <v-card-title class="pt-5 pb-0">{{ fetchedPopups[currentPopupIndex].title }}</v-card-title>
                <v-card-text v-html="fetchedPopups[currentPopupIndex].text"></v-card-text>
            </div>

            <v-card-actions class="d-flex justify-center pb-5">
                <v-btn v-if="!showFullText && fetchedPopups.length > 0" flat color="primary"
                    @click="showFullText = true">
                    Mehr anzeigen
                </v-btn>
                <v-btn v-else-if="fetchedPopups.length > 0" flat color="primary" @click="showFullText = false">
                    Weniger anzeigen
                </v-btn>
                <v-btn style="background-color: #313131; color: white;" @click="closePopup">
                    Schliessen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from '@/api'

export default {
    data() {
        return {
            fetchedPopups: [],
            popupDialog: false,
            currentPopupIndex: 0,
            showFullText: false
        }
    },
    async created() {
        // this.fetchPopups()
    },
    methods: {
        async fetchPopups() {
            try {
                let popups = await api.PopupService.getActive()
                this.fetchedPopups = popups
                this.cleanupLocalStorage() // Bereinige LocalStorage
                this.filterPopupsToShow() // Zeige relevante Popups basierend auf Cooldown
            } catch (e) {
                console.log("error fetching popups")
            }
        },
        filterPopupsToShow() {
            const cooldown = 24 * 60 * 60 * 1000 // 24 Stunden in Millisekunden
            const now = new Date().getTime()

            // Filtere alle Popups, die den Cooldown bestanden haben
            const popupsToShow = this.fetchedPopups.filter(popup => {
                const lastShownKey = `popup_last_shown_${popup._id}`
                const lastShown = localStorage.getItem(lastShownKey)
                return !lastShown || (now - parseInt(lastShown)) > cooldown
            })

            // Wenn es Popups gibt, die angezeigt werden sollen, setze sie und öffne den Dialog
            if (popupsToShow.length > 0) {
                this.fetchedPopups = popupsToShow
                this.popupDialog = true

                // Aktualisiere LocalStorage für angezeigte Popups
                popupsToShow.forEach(popup => {
                    const lastShownKey = `popup_last_shown_${popup._id}`
                    localStorage.setItem(lastShownKey, now.toString())
                })
            }
        },
        cleanupLocalStorage() {
            const currentIds = this.fetchedPopups.map(p => p._id)
            const keysToRemove = []

            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i)
                if (key && key.startsWith("popup_last_shown_")) {
                    const popupId = key.replace("popup_last_shown_", "")
                    if (!currentIds.includes(popupId)) {
                        keysToRemove.push(key)
                    }
                }
            }

            keysToRemove.forEach(key => {
                localStorage.removeItem(key)
            })
        },
        closePopup() {
            this.popupDialog = false
            this.showFullText = false
        },
        getShortText(text) {
            const maxLength = 0
            return text.length > maxLength ? text.substring(0, maxLength) : text
        }
    }
}
</script>

<style scoped>
.w-100 {
    width: 100%;
}
</style>
