<template><v-dialog persistent :value="true" fullscreen>
    <v-card dark>
        <v-row no-gutters style="height: 10vh">
            <v-col cols="11" class="align-self-center">
                <v-card-title class="font-weight-bold">Ernährungsplan erstellen</v-card-title>
            </v-col>
            <v-col cols="1" class="align-self-center">
                <v-btn icon><v-icon color="grey" @click="$emit('close')">mdi-close</v-icon></v-btn>
            </v-col>
        </v-row>
        <v-stepper v-model="stepperModel" style="height: 90vh; overflow: auto">
            <v-stepper-header style="flex-wrap: nowrap !important; overflow: scroll">
                <template v-for="(step, index) of stepperSteps">
                    <v-stepper-step :key="'step-' + index" :complete="stepperModel > index + 1" :step="index + 1">
                        {{ step }}
                    </v-stepper-step>

                    <v-divider :key="'divider-' + index"></v-divider>
                </template>
            </v-stepper-header>
            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-row no-gutters>
                        <v-col cols="4">
                            <v-text-field class="mr-1 ml-2" filled rounded :value="mealPlan.calculatedCalories + 'kcal'"
                                disabled :label="'Geschätzter Grundumsatz ' + height + 'cm, ' + weight + 'kg, ' + age + ' Jahre'
                                    "></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-select class="mx-1" v-model="mealPlan.selectedPal" :items="palItems" filled
                                rounded></v-select>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field class="mx-1" filled rounded
                                :value="Math.round(mealPlan.calculatedCalories * mealPlan.selectedPal) + 'kcal'"
                                disabled :label="'Geschätzter Grundumsatz mit Pal'"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-text-field :label="'Kalorienverbrauch / Tag'" class="ml-2" v-model="mealPlan.dailyCalories"
                        @blur="calculateSubstances()" suffix="kcal" number filled rounded></v-text-field>
                    <v-row no-gutters v-if="mealPlan.dailyCalories && calculated">
                        <v-col cols="auto">
                            <v-select class="ml-2" filled rounded @change="calculateSubstances()"
                                :items="percentagePossibilities" item-value="name" item-text="name"
                                v-model="mealPlan.selectedPossibility"></v-select>
                        </v-col>
                        <v-col cols="auto">
                            <v-switch class="ml-2" filled rounded v-model="percentOrValueSwitch" v-if="percentages"
                                :label="percentOrValueSwitch ? 'Prozent' : 'Wert in Gramm'"></v-switch>
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-if="mealPlan.dailyCalories && calculated">
                        <v-col cols="auto">
                            <v-text-field class="mx-1" filled rounded @change="
                                adjustNutrient('protein', percentOrValueSwitch ? 'percentage' : 'value', $event)
                                " :value="mealPlan.substanceDistribution.protein[
                                    percentOrValueSwitch && percentages ? 'percentage' : 'value'
                                ]
                                    " label="Protein" :suffix="percentages
                                            ? percentOrValueSwitch
                                                ? '% oder ' + mealPlan.substanceDistribution.protein.value + 'g'
                                                : 'g oder ' + mealPlan.substanceDistribution.protein.percentage + '%'
                                            : 'g'
                                            "></v-text-field>
                        </v-col>
                        <v-col cols="auto">
                            <v-text-field class="mx-1" filled rounded @change="
                                adjustNutrient('fat', percentOrValueSwitch ? 'percentage' : 'value', $event)
                                " :value="mealPlan.substanceDistribution.fat[
                                    percentOrValueSwitch ? 'percentage' : 'value'
                                ]
                                    " label="Fett" :suffix="percentOrValueSwitch
                                            ? '% oder ' + mealPlan.substanceDistribution.fat.value + 'g'
                                            : 'g oder ' + mealPlan.substanceDistribution.fat.percentage + '%'
                                            "></v-text-field>
                        </v-col>
                        <v-col cols="auto">
                            <v-text-field class="mx-1" filled rounded :value="mealPlan.substanceDistribution.carbs[
                                percentOrValueSwitch ? 'percentage' : 'value'
                            ]
                                " label="Kohlenhydrate" @change="
                                    adjustNutrient('carbs', percentOrValueSwitch ? 'percentage' : 'value', $event)
                                    " :suffix="percentOrValueSwitch
                                            ? '% oder ' + mealPlan.substanceDistribution.carbs.value + 'g'
                                            : 'g oder ' + mealPlan.substanceDistribution.carbs.percentage + '%'
                                            "></v-text-field>
                        </v-col>
                    </v-row>
                    <v-select v-if="mealPlan.dailyCalories && false" label="Ziel" v-model="mealPlan.target"
                        :items="mealPlanTargetItems" filled rounded></v-select>

                    <div v-if="mealPlan.dailyCalories">
                        <v-card-title class="px-0 pb-1">Ernährungsplan erstellen für folgende Tage</v-card-title>
                        <v-chip-group v-model="mealPlan.selectedDays" @change="setMealplanDays()" multiple
                            active-class="primary white--text">
                            <v-chip v-for="(day, index) of dayPossibilities" :disabled="daysSaved" :value="day.value"
                                :key="'tag' + index">
                                {{ day.text }}
                            </v-chip>
                            <v-btn :color="daysSaved ? 'red' : 'primary'" @click="daysSaved = !daysSaved" icon>
                                <v-icon>{{ daysSaved ? 'mdi-close' : 'mdi-check' }}</v-icon>
                            </v-btn>
                        </v-chip-group>
                    </div>
                    <div v-if="mealPlan.selectedDays && daysSaved">
                        <v-card-title class="px-0">
                            Mahlzeiten auswählen für
                            <v-select v-model="dayActive" style="max-width: 200px" class="ml-2" :items="mealPlan.days"
                                item-text="dayText" item-value="day"></v-select>
                        </v-card-title>
                        <v-row no-gutters>
                            <v-col cols="8">
                                <v-select label="Welche Mahlzeiten" multiple filled rounded return-object
                                    @change="resetMealDistribution()"
                                    v-model="mealPlan.days.find((mpDay) => mpDay.day === dayActive).mealCategories"
                                    :items="mealCategories"></v-select>
                            </v-col>
                            <v-col cols="4">
                                <v-btn class="mx-1 my-2" rounded v-if="!copyActive" @click="copyActive = true"
                                    color="primary">
                                    von anderem Tag übernehmen
                                </v-btn>
                                <v-select v-else :items="mealPlan.days" item-value="day" item-text="dayText"
                                    @change="copyFromDay" label="Kopieren von anderem Tag"></v-select>
                            </v-col>
                        </v-row>

                        <v-row v-for="(category, index) of mealPlan.days.find((mpDay) => mpDay.day === dayActive)
                            .mealDistribution" :key="'distribution' + index" no-gutters>
                            <v-col cols="6">
                                <v-text-field class="pa-2" v-model="category.text" disabled filled
                                    rounded></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field class="pa-2" v-model="category.percentage" filled number rounded
                                    suffix="%"></v-text-field>
                            </v-col>
                            <v-col cols="3" v-if="mealPlan.dailyCalories">
                                <v-text-field class="pa-2" :value="Math.round((mealPlan.dailyCalories / 100) * category.percentage * 100) / 100
                                    " disabled filled rounded suffix="kcal"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-if="mealPlan.dailyCalories">
                            <v-col cols="9"></v-col>
                            <v-col cols="3">
                                <v-text-field filled rounded :value="Math.round(
                                    mealPlan.days
                                        .find((mpDay) => mpDay.day === dayActive)
                                        .mealDistribution.reduce((total, item) => {
                                            return Number(total) + Number(item.percentage)
                                        }, 0)
                                ) + '%'
                                    " disabled prefix="Total: "></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                </v-stepper-content>
                <v-stepper-content v-for="(step, stepIndex) of mealPlan.days" :step="stepIndex + 2"
                    :key="'step' + stepIndex">
                    <v-card-title class="px-0">Einstellungen für {{ step.dayText }}</v-card-title>
                    <v-expansion-panels>
                        <v-expansion-panel v-for="(meal, index) of step.mealDistribution" :key="'meal' + index">
                            <v-expansion-panel-header>
                                {{ meal.text }}
                                <span class="orange--text ml-2">
                                    {{
                                        ' (' +
                                        Math.round((mealPlan.dailyCalories / 100) * meal.percentage) +
                                        'kcal)'
                                    }}
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-text-field type="number" min="1" label="Wie viele Variationen sollen gesucht werden?"
                                    v-model="meal.variationCount"></v-text-field>
                                <v-combobox multiple label="Nach Begriffen filtern" :items="keyWordFilters"
                                    @change="meal.offset = 0" v-model="meal.filters.keyWords"></v-combobox>
                                <v-row no-gutters>
                                    <v-col cols="4" class="d-flex pr-2">
                                        <v-text-field :disabled="!substanceFilter.protein.active"
                                            v-model="substanceFilter.protein.percentage" type="Number"
                                            @change="meal.offset = 0" label="Protein" suffix="%"></v-text-field>
                                        <v-checkbox v-model="substanceFilter.protein.active" label="Filtern"
                                            @change="meal.offset = 0"></v-checkbox>
                                    </v-col>
                                    <v-col cols="4" class="d-flex px-2">
                                        <v-text-field :disabled="!substanceFilter.fat.active"
                                            v-model="substanceFilter.fat.percentage" Number @change="meal.offset = 0"
                                            label="Fett" suffix="%"></v-text-field>
                                        <v-checkbox v-model="substanceFilter.fat.active" label="Filtern"
                                            @change="meal.offset = 0"></v-checkbox>
                                    </v-col>
                                    <v-col cols="4" class="pl-2 d-flex">
                                        <v-text-field :disabled="!substanceFilter.carbs.active"
                                            v-model="substanceFilter.carbs.percentage" Number @change="meal.offset = 0"
                                            label="Kohlenhydrate" suffix="%"></v-text-field>
                                        <v-checkbox @change="meal.offset = 0" v-model="substanceFilter.carbs.active"
                                            label="Filtern"></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="4">
                                        <v-select class="mr-2" @change="meal.offset = 0" label="Kalorienfilter"
                                            v-model="meal.filters.kcalSetting" :items="kcalSettingFilters"></v-select>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field class="mx-2" @change="meal.offset = 0" label="Wert 1"
                                            v-model="meal.filters.kcalValue1"></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field class="ml-2" @change="meal.offset = 0" label="Wert 2"
                                            v-model="meal.filters.kcalValue2"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-text-field label="Erlaubte Abweichung in %" Number placeholder="10" suffix="%"
                                    v-model="meal.filters.deviation"></v-text-field>
                                <v-select label="Gang" :items="courseFilters" @change="meal.offset = 0" multiple
                                    v-model="meal.filters.course"></v-select>
                                <v-select label="Schwierigkeitsgrad der Zubereitung" :items="difficultyFilters"
                                    @change="meal.offset = 0" multiple v-model="meal.filters.difficulty"></v-select>
                                <v-select clearable label="maximale Gesamtzeit pro Mahlzeit" :items="maxTimeFilters"
                                    @change="meal.offset = 0" v-model="meal.filters.maxTime"></v-select>
                                <v-text-field disabled label="Personendaten" :value="customerData"></v-text-field>
                                <v-card-actions class="justify-content-center">
                                    <v-btn color="primary" :loading="searchingForRecipes && false"
                                        @click="searchCookbutlerForRecipes(meal)" rounded>
                                        Nach Rezepten suchen
                                    </v-btn>
                                    <v-btn v-if="meal.recipes && meal.recipes.length" color="primary" rounded
                                        @click="deleteRecipesForMeal(meal)">
                                        Bestehende Rezepte entfernen
                                    </v-btn>
                                    <v-btn color="primary" @click="addNoteForMeal(meal)" rounded>
                                        {{ meal.note ? 'Notiz bearbeiten ' : 'Notiz hinzufügen' }}
                                    </v-btn>
                                    <v-btn v-if="stepIndex > 0 && false" color="primary"
                                        :loading="searchingForRecipes && false"
                                        @click="copyRecipesFromDayBefore(stepIndex, meal)" rounded>
                                        Rezepte von vorherigem Tag kopieren
                                    </v-btn>
                                </v-card-actions>
                                <v-row no-gutters v-if="meal.recipes">
                                    <v-col lg="4" xl="4" md="6" xs="12" sm="12" class="pa-3"
                                        v-for="(recipe, recipeIndex) of meal.recipes" :key="'recipe' + recipeIndex + 1">
                                        <v-card class="elevation-7 fill-height" rounded>
                                            <v-img height="150px" v-if="recipe && recipe.media"
                                                :src="recipe.media.preview[0]"></v-img>
                                            <v-card-title>
                                                <v-col class="px-0 h6" cols="7" style="
                                                            overflow: hidden;
                                                            text-wrap: nowrap;
                                                            text-overflow: ellipsis;
                                                        ">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span v-bind="attrs" v-on="on">
                                                                {{
                                                                    recipe.recipe
                                                                        ? recipe.recipe.title
                                                                        : recipe.optional.title
                                                                }}
                                                            </span>
                                                        </template>
                                                        <span>
                                                            {{
                                                                recipe.recipe
                                                                    ? recipe.recipe.title
                                                                    : recipe.optional.title
                                                            }}
                                                        </span>
                                                    </v-tooltip>
                                                </v-col>
                                                <v-col cols="1" class="pa-0">
                                                    <v-btn icon color="primary"
                                                        @click="searchNewRecipe(meal, recipeIndex, index)">
                                                        <v-icon>mdi-reload</v-icon>
                                                    </v-btn>
                                                </v-col>
                                                <v-col cols="1" class="pa-0">
                                                    <v-btn icon color="orange" @click="toggleSearchActive(recipeIndex)">
                                                        <v-icon>mdi-find-replace</v-icon>
                                                    </v-btn>
                                                </v-col>
                                                <v-col cols="1" class="pa-0" v-if="!recipe.ingredients">
                                                    <v-btn icon color="blue" @click="
                                                        toggleSearchFullRecipe(meal, recipeIndex, recipe.id)
                                                        ">
                                                        <v-icon>mdi-nutrition</v-icon>
                                                    </v-btn>
                                                </v-col>
                                                <v-col cols="1" class="pa-0" v-else>
                                                    <v-btn icon color="blue"
                                                        @click="adjustRecipeForCalories(meal, recipeIndex, recipe)">
                                                        <v-icon>mdi-scale-balance</v-icon>
                                                    </v-btn>
                                                </v-col>
                                                <v-col cols="1" class="pa-0">
                                                    <v-btn icon @click="addRecipeToNutritionFavorites(recipe)">
                                                        <v-icon :color="userRecipeFavorites.find(
                                                            (favorit) =>
                                                                Number(favorit.id) === Number(recipe.id) ||
                                                                (recipe.recipe &&
                                                                    Number(favorit.id) ===
                                                                    Number(recipe.recipe.id_recipe))
                                                        )
                                                            ? 'yellow'
                                                            : 'white'
                                                            ">
                                                            mdi-star
                                                        </v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-card-title>
                                            <user-dashboard-nutrition-meal-plan-form-search v-if="
                                                activeSearches[recipeIndex] &&
                                                activeSearches[recipeIndex] === true
                                            " :favoriteRecipes="userRecipeFavorites" :allergens="allergens"
                                                :diet="diet" :substanceFilter="substanceFilter"
                                                :addFilters="meal.filters"
                                                @newReceipt="updateReceipt($event, step, recipeIndex, index)"></user-dashboard-nutrition-meal-plan-form-search>
                                            <v-card-subtitle>
                                                {{ 'Variation ' + (recipeIndex + 1) }}
                                            </v-card-subtitle>
                                            <v-card-text>
                                                <span>
                                                    Dauer:
                                                    {{
                                                        recipe.time
                                                            ? recipe.time.find((time) => time.timetype === 'Gesamt')
                                                                .value + ' Minuten'
                                                            : recipe.optional.time_total + ' Minuten'
                                                    }}
                                                </span>
                                                <p></p>
                                                <v-row v-if="recipe.ingredients" class="justify-space-around"
                                                    no-gutters>
                                                    <v-switch :disabled="!recipe.diagramToggle"
                                                        :value="recipe.substanceToggle"
                                                        @change="changeSubstanceToggle(recipe, $event)"
                                                        :label="recipe.substanceToggle ? 'Rezept' : 'Portion'"></v-switch>
                                                    <v-switch :value="recipe.diagramToggle"
                                                        @change="changeDiagramToggle(recipe, $event)"
                                                        :label="recipe.diagramToggle ? 'Inhaltsstoffe' : 'Zutaten'"></v-switch>
                                                </v-row>
                                                <v-row no-gutters v-if="recipe.ingredients && recipe.diagramToggle">
                                                    <apexchart v-if="recipe.substances" type="pie" width="300"
                                                        :options="chartOptions" :series="[
                                                            Math.round(
                                                                recipe.substances.find(
                                                                    (substance) =>
                                                                        substance.substance ===
                                                                        'Kohlenhydrate, resorbierbar'
                                                                ).portion.amount *
                                                                4 *
                                                                10
                                                            ) / 10,
                                                            Math.round(
                                                                recipe.substances.find(
                                                                    (substance) =>
                                                                        substance.substance === 'Eiweiß (Protein)'
                                                                ).portion.amount *
                                                                4 *
                                                                10
                                                            ) / 10,
                                                            Math.round(
                                                                recipe.substances.find(
                                                                    (substance) => substance.substance === 'Fett'
                                                                ).portion.amount *
                                                                9 *
                                                                10
                                                            ) / 10,
                                                        ]"></apexchart>
                                                    <v-list>
                                                        <v-list-item class="pa-0" v-for="(value, key) in recipe.substances.filter(
                                                            (subs) =>
                                                                completeRecipeSubstaneFilter.includes(
                                                                    subs.substance
                                                                )
                                                        )" :key="key">
                                                            <v-list-item-content class="py-1 px-0">
                                                                <v-list-item-title>
                                                                    {{ value.substance }}
                                                                </v-list-item-title>
                                                                <v-list-item-subtitle v-if="value.unit_short !== 'mg'">
                                                                    {{
                                                                        value[
                                                                            recipe.substanceToggle
                                                                                ? 'recipe'
                                                                                : 'portion'
                                                                        ].amount
                                                                    }}
                                                                    {{ value.unit_short }}
                                                                </v-list-item-subtitle>
                                                                <v-list-item-subtitle v-else>
                                                                    {{
                                                                        Math.round(
                                                                            value[
                                                                                recipe.substanceToggle
                                                                                    ? 'recipe'
                                                                                    : 'portion'
                                                                            ].amount / 100
                                                                        ) / 10
                                                                    }}
                                                                    g
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-row>
                                                <v-row no-gutters v-if="!recipe.diagramToggle && recipe.ingredients">
                                                    <v-col cols="12">
                                                        <span class="h5">
                                                            Zutaten für {{ recipe.recipe.serving }}
                                                            {{
                                                                recipe.recipe.serving === 1
                                                                    ? ' Portion'
                                                                    : 'Portionen'
                                                            }}
                                                        </span>
                                                    </v-col>

                                                    <v-col class="pa-1" v-for="(value, key) in recipe.ingredients"
                                                        :key="key" cols="12">
                                                        <span class="white--text">
                                                            {{ value.quantity1 }}
                                                            {{
                                                                value.unit_short +
                                                                ' ' +
                                                                value.item_attribute +
                                                                ' ' +
                                                                value.product +
                                                                ' ' +
                                                                value.item_addition
                                                            }}
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-if="recipe.optional" class="justify-space-around" no-gutters>
                                                    <v-switch :value="recipe.substanceToggle"
                                                        @change="changeSubstanceToggle(recipe, $event)"
                                                        :label="recipe.substanceToggle ? 'Rezept' : 'Portion'"></v-switch>
                                                    <v-switch :value="recipe.diagramToggle"
                                                        @change="changeDiagramToggle(recipe, $event)"
                                                        :label="recipe.diagramToggle ? 'Diagramm' : 'Liste'"></v-switch>
                                                </v-row>
                                                <apexchart v-if="recipe.diagramToggle && recipe.optional" type="pie"
                                                    width="380" :options="chartOptions" :series="[
                                                        Math.round(
                                                            (recipe.optional.substances['carbohydrates,available'][
                                                                recipe.substanceToggle ? 'recipe' : 'portion'
                                                            ].amount *
                                                                4) /
                                                            100
                                                        ) / 10,
                                                        Math.round(
                                                            (recipe.optional.substances['protein'][
                                                                recipe.substanceToggle ? 'recipe' : 'portion'
                                                            ].amount *
                                                                4) /
                                                            100
                                                        ) / 10,
                                                        Math.round(
                                                            (recipe.optional.substances['fat,total'][
                                                                recipe.substanceToggle ? 'recipe' : 'portion'
                                                            ].amount *
                                                                9) /
                                                            100
                                                        ) / 10,
                                                    ]"></apexchart>
                                                <v-list v-else-if="recipe.optional">
                                                    <v-list-item class="pa-0"
                                                        v-for="(value, key) in recipe.optional.substances" :key="key">
                                                        <v-list-item-content class="py-1 px-0">
                                                            <v-list-item-title>
                                                                {{ value.substance }}
                                                            </v-list-item-title>
                                                            <v-list-item-subtitle v-if="value.unit_short !== 'mg'">
                                                                {{
                                                                    value[
                                                                        recipe.substanceToggle
                                                                            ? 'recipe'
                                                                            : 'portion'
                                                                    ].amount
                                                                }}
                                                                {{ value.unit_short }}
                                                            </v-list-item-subtitle>
                                                            <v-list-item-subtitle v-else>
                                                                {{
                                                                    Math.round(
                                                                        value[
                                                                            recipe.substanceToggle
                                                                                ? 'recipe'
                                                                                : 'portion'
                                                                        ].amount / 100
                                                                    ) / 10
                                                                }}
                                                                g
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </v-card-text>
                                            <v-textarea class="pa-5" label="Notiz" v-model="recipe.note"></v-textarea>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-stepper-content>
                <v-stepper-content :step="mealPlan.days.length ? mealPlan.days.length + 2 : 2"
                    :key="'step' + mealPlan.days.length + 2" class="text-center justify-content-center">
                    <v-card-title class="px-0 text-center justify-content-center">
                        Ernährungsplan Zusammenfassung
                    </v-card-title>
                    <v-expansion-panels v-if="stepperModel === mealPlan.days.length + 2">
                        <v-expansion-panel v-for="(day, stepIndex) of mealPlan.days" :key="'stepsummary' + stepIndex">
                            <v-expansion-panel-header>
                                {{ day.dayText }}
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row no-gutters v-for="(meal, mealIndex) of day.mealDistribution"
                                    :key="'mealdist' + mealIndex">
                                    <v-col cols="12">
                                        <v-card-title>{{ meal.text }}</v-card-title>
                                        <v-card-text v-if="meal.note">
                                            <v-text-field class="pt-0" filled label="Notiz" disabled
                                                v-model="meal.note"></v-text-field>
                                        </v-card-text>
                                    </v-col>
                                    <v-col cols="auto" v-if="!meal.recipes">
                                        <v-alert type="info">Es wurden keine Rezepte ausgewählt</v-alert>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-card-title class="h6">Durchschnitts Nährwerte</v-card-title>
                                        <v-row no-gutters class="justify-space-around">
                                            <v-col cols="auto" style="min-width: 300px">
                                                <span class="font-weight-bold">
                                                    Gewünscht
                                                    {{
                                                        '(' +
                                                        Math.round(
                                                            (mealPlan.dailyCalories / 100) * meal.percentage
                                                        ) +
                                                        ' kcal)'
                                                    }}
                                                </span>
                                                <apexchart type="pie" width="300" :options="chartOptions" :series="[
                                                    Math.round(meal.substanceDistributions.carbs.value * 4),
                                                    Math.round(meal.substanceDistributions.fat.value * 9),
                                                    Math.round(meal.substanceDistributions.protein.value * 4),
                                                ]"></apexchart>
                                            </v-col>
                                            <v-col v-if="meal.recipes" cols="auto" style="min-width: 300px">
                                                <span class="font-weight-bold">
                                                    {{
                                                        'Tatsächlich (' +
                                                        calculatedAverageCalories(meal.recipes) +
                                                        'kcal)'
                                                    }}
                                                </span>
                                                <apexchart type="pie" width="300" :options="chartOptions"
                                                    :series="calculatedAveragePieChart(meal.recipes)"></apexchart>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-divider></v-divider>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <v-btn @click="saveNewNutritionPlan()" class="mt-3" color="primary">
                        Ernährungsplan erstellen
                    </v-btn>
                </v-stepper-content>
                <v-card-actions class="justify-space-around">
                    <v-btn text v-if="stepperModel !== 1" @click="stepperModel--">Zurück</v-btn>
                    <v-btn color="primary" v-if="stepperModel !== mealPlan.days.length + 2" @click="nextStep()">
                        Weiter
                    </v-btn>
                </v-card-actions>
            </v-stepper-items>
        </v-stepper>
    </v-card>
    <v-dialog max-width="300px" v-model="isFetchingReceipts">
        <v-card dark>
            <v-card-title>Rezepte werden Geladen</v-card-title>
            <v-card-text class="justify-content-center text-center">
                <p>{{ fetchingReceipt.day + ' - ' + fetchingReceipt.mealCategory }}</p>
                <v-progress-circular :size="100" :width="15"
                    :value="(100 / fetchingReceipt.receiptCount) * fetchingReceipt.receiptState"
                    color="primary"></v-progress-circular>
                <p>{{ fetchingReceipt.receipt }}</p>
            </v-card-text>
        </v-card>
    </v-dialog>
    <v-dialog max-width="300px" v-model="showNoteDialog" @click:outside="$toast.success('Notiz wurde gespeichert')">
        <v-card dark>
            <v-card-title>Notiz</v-card-title>
            <v-card-text class="justify-content-center text-center">
                <v-textarea v-model="addNoteMeal.note"></v-textarea>
            </v-card-text>
        </v-card>
    </v-dialog>
</v-dialog></template>
<script>
import api from '../../../api'
import userService from '../../../services/userService'
import userDashboardNutritionMealPlanFormSearch from './userDashboardNutritionMealPlanFormSearch.vue'

export default {
    components: {
        userDashboardNutritionMealPlanFormSearch,
    },
    props: {
        difficultyFilters: {
            type: Array,
        },
        maxTimeFilters: {
            type: Array,
        },
        user: {},
        allergens: {},
        diet: {},
        existingPlan: {},
    },
    data() {
        return {
            completeRecipeSubstaneFilter: [
                'Fett',
                'Eiweiß (Protein)',
                'Kohlenhydrate, resorbierbar',
                'Zucker (gesamt)',
                'Energie (Kilojoule)',
                'Energie (Kilokalorien)',
            ],
            chartOptions: {
                chart: {
                    type: 'pie',
                },
                legend: {
                    labels: {
                        colors: ['#fff'],
                    },
                },
                labels: ['Kohlenhydrate', 'Proteine', 'Fett'],
            },
            calculated: false,
            percentagePossibilities: [
                {
                    name: 'Ausgewogene Ernährung',
                    distribution: {
                        carbs: 50,
                        protein: 20,
                        fat: 30,
                    },
                },
                {
                    name: 'Low-Carb, High-Protein',
                    distribution: {
                        carbs: 30,
                        protein: 40,
                        fat: 30,
                    },
                },
                {
                    name: 'High-Carb, Low-Fat',
                    distribution: {
                        carbs: 60,
                        protein: 25,
                        fat: 15,
                    },
                },
                {
                    name: 'Ketogene Diät',
                    distribution: {
                        carbs: 5,
                        protein: 20,
                        fat: 75,
                    },
                },
                {
                    name: 'Moderate Low-Carb',
                    distribution: {
                        carbs: 40,
                        protein: 30,
                        fat: 30,
                    },
                },
                {
                    name: 'High-Protein, Moderate-Carb',
                    distribution: {
                        carbs: 40,
                        protein: 35,
                        fat: 25,
                    },
                },
                {
                    name: 'Eigene angabe (Keine automatische Anpassungen)',
                    distribution: false,
                },
            ],
            kcalSettingFilters: [
                { text: 'Nicht filtern', value: null },
                { text: 'Grösser oder gleich', value: 'gte_' },
                { text: 'Weniger oder gleich', value: 'lte_' },
                { text: 'Zwischen zwei Werten', value: 'bwe_' },
            ],
            caloriesPerGram: {
                carbs: 4,
                protein: 4,
                fat: 9,
            },
            activeSearches: [],
            dayActive: '',
            percentOrValueSwitch: false,
            daysSaved: false,
            searchingForRecipes: false,
            dayPossibilities: [
                {
                    text: 'Trainingstag',
                    value: 'trainingDay',
                },
                { text: 'Erholungstag', value: 'recoveryDay' },
                //{ text: 'Cheatday', value: 'cheatDay' },
            ],
            /* weekDays: [
                { text: 'Montag', value: 'monday' },
                { text: 'Dienstag', value: 'tuesday' },
                { text: 'Mittwoch', value: 'wednesday' },
                { text: 'Donnerstag', value: 'thursday' },
                { text: 'Freitag', value: 'friday' },
                { text: 'Samstag', value: 'saturday' },
                { text: 'Sonntag', value: 'sunday' },
            ], */

            mealPlan: {
                substanceDistribution: null,

                selectedPossibility: 'Ausgewogene Ernährung',
                mealCategories: [],
                mealDistribution: [],
                days: [],
                calculatedCalories: 0,
                selectedPal: 1.2,
            },
            keyWordFilters: ['Müsli', 'Hühnchen'],
            palItems: [
                { text: '1.2 Reine Schreibtischarbeit', value: 1.2 },
                { text: '1.3 Schreibtischarbeit < 5000 Schritte', value: 1.3 },
                { text: '1.4 Schreibtisch und stehend < 10000 Schritte', value: 1.4 },
                { text: '1.5 Schreibtisch und stehend 10000 - 15000 Schritte', value: 1.5 },
                { text: '1.6 Schreibtisch und stehend > 15000 Schritte', value: 1.6 },
            ],
            mealPlanTargetItems: [
                {
                    text: 'Abnehmen',
                    value: 'loseWeight',
                },
                {
                    text: 'Erhalt',
                    value: 'keepWeight',
                },
                {
                    text: 'Zunehmen',
                    value: 'gainWeight',
                },
            ],
            courseFilters: [
                { text: 'Vorspeise', value: 'starter' },
                { text: 'Hauptgericht', value: 'main_course' },
                { text: 'Dessert', value: 'dessert' },
            ],
            mealCategories: [
                {
                    text: 'Frühstück',
                    value: 'breakfast',
                    filters: { course: ['starter'], deviation: 10 },
                },
                {
                    text: 'Morgen Snack',
                    value: 'morningSnack',
                    filters: { course: [], deviation: 10 },
                },
                {
                    text: 'Mittagessen',
                    value: 'lunch',
                    filters: { course: ['main_course'], deviation: 10 },
                },
                {
                    text: 'Nachmittags Snack',
                    value: 'afternoonSnack',
                    filters: { course: [], deviation: 10 },
                },
                {
                    text: 'Abendessen',
                    value: 'dinner',
                    filters: { course: ['main_course'], deviation: 10 },
                },
                {
                    text: 'Abend Snack',
                    value: 'eveningSnack',
                    filters: { course: [], deviation: 10 },
                },
            ],
            creationFormActive: false,
            stepperModel: 1,
            copyActive: false,
            fetchingReceipt: {
                day: '',
                mealCategory: '',
                receipt: '',
                receiptCount: 0,
                receiptState: 0,
            },
            isFetchingReceipts: false,
            height: 0,
            weight: 0,
            age: 0,
            substanceFilter: {},
            userRecipeFavorites: [],
            addNoteMeal: {},
            showNoteDialog: false,
        }
    },
    async created() {
        if (this.existingPlan && this.existingPlan.createdAt) {
            this.mealPlan = this.existingPlan
            this.dayActive = this.existingPlan.days[0].day
            this.calculateSubstances()
        }
        this.height = Number(this.user.groesse)
        if (this.user.weights && this.user.weights.length) {
            this.weight = Number(this.user.weights[this.user.weights.length - 1].value)
        }
        if (this.user.geburtstag) {
            this.age = this.$moment().diff(this.$moment(this.user.geburtstag), 'years')
        }

        if (this.height && this.weight && this.age) {
            if (this.user.geschlecht === 'm') {
                this.mealPlan.calculatedCalories = Math.round(
                    66.47 + 13.7 * this.weight + 5 * this.height - 6.8 * this.age
                )
            } else {
                this.mealPlan.calculatedCalories = Math.round(
                    655.1 + 9.6 * this.weight + 1.8 * this.height - 4.7 * this.age
                )
            }
        }
        this.userRecipeFavorites = await api.getUserNutritionFavorites()
    },
    computed: {
        customerData() {
            return (
                'Gesamtumsatz: ' +
                this.mealPlan.calculatedCalories +
                'kcal, Alter: ' +
                this.age +
                ' Jahre, Gewicht: ' +
                this.weight +
                'kg, Grösse: ' +
                this.height +
                'cm'
            )
        },
        percentages() {
            return this.percentagePossibilities.find((poss) => poss.name === this.mealPlan.selectedPossibility)
                .distribution
        },
        stepperSteps() {
            if (this.stepperModel === 1) {
                return ['Allgemeine Informationen', 'Mahlzeiten planen', 'Abschliessen']
            } else {
                let returnArray = ['Allgemeine Informationen']
                this.mealPlan.days.forEach((day) => returnArray.push(day.dayText))
                returnArray.push('Abschliessen')
                return returnArray
            }
        },
    },
    methods: {
        calculatedAveragePieChart(recipes) {
            let proteins = 0
            let carbs = 0
            let fat = 0

            for (let recipe of recipes) {
                if (recipe.substances) {
                    carbs +=
                        recipe.substances.find((substance) => substance.substance === 'Kohlenhydrate, resorbierbar')[
                            'portion'
                        ].amount * 4
                    proteins +=
                        recipe.substances.find((substance) => substance.substance === 'Eiweiß (Protein)')['portion']
                            .amount * 4
                    fat += recipe.substances.find((substance) => substance.substance === 'Fett')['portion'].amount * 9
                } else {
                    carbs += recipe.optional.substances['carbohydrates,available']['portion'].amount * 4
                    proteins += recipe.optional.substances['protein']['portion'].amount * 4
                    fat += recipe.optional.substances['fat,total']['portion'].amount * 9
                }
            }
            carbs = Math.round(carbs / recipes.length)
            proteins = Math.round(proteins / recipes.length)
            fat = Math.round(fat / recipes.length)
            return [carbs, proteins, fat]
        },
        calculatedAverageCalories(recipes) {
            if (!recipes || !recipes.length) return 'Keine Rezepte'
            const averageCalories =
                recipes.reduce((acc, recipe) => {
                    const kcal = recipe.substances
                        ? recipe.substances.find((s) => s.substance === 'Energie (Kilokalorien)')['portion'].amount
                        : recipe.optional.substances['energy kcal']['portion'].amount
                    return kcal ? acc + kcal : acc
                }, 0) / recipes.length

            return averageCalories.toFixed(1)
        },
        deleteRecipesForMeal(meal) {
            if (confirm('Möchtest du die vorhanden Rezepte entfernen?')) meal.recipes = []
        },
        test(recipe) {
            return ''
            console.log(
                this.userRecipeFavorites.find((favorit) => {
                    console.log(favorit.id, recipe.id, recipe.recipe.id_recipe)
                    return (
                        Number(favorit.id) === Number(recipe.id) ||
                        Number(favorit.id) === Number(recipe.recipe.id_recipe)
                    )
                })
            )
            console.log(this.userRecipeFavorites)
            console.log(recipe)
        },
        calculateSubstances() {
            this.calculated = false
            let totalCalories = this.mealPlan.dailyCalories
            if (this.percentages) {
                this.mealPlan.substanceDistribution = {
                    carbs: {
                        value: ((totalCalories * this.percentages.carbs) / 100 / this.caloriesPerGram.carbs).toFixed(2),
                        percentage: this.percentages.carbs,
                    },
                    protein: {
                        value: (
                            (totalCalories * this.percentages.protein) /
                            100 /
                            this.caloriesPerGram.protein
                        ).toFixed(2),
                        percentage: this.percentages.protein,
                    },
                    fat: {
                        value: ((totalCalories * this.percentages.fat) / 100 / this.caloriesPerGram.fat).toFixed(2),
                        percentage: this.percentages.fat,
                    },
                }
                this.mealPlan.substanceDistribution.protein.active = true
                this.mealPlan.substanceDistribution.carbs.active = true
                this.mealPlan.substanceDistribution.fat.active = true
            }
            this.calculated = true
            this.substanceFilter = JSON.parse(JSON.stringify(this.mealPlan.substanceDistribution))
        },
        adjustNutrient(nutrientType, valueType, newValue) {
            if (this.percentages) {
                let totalCalories = this.mealPlan.dailyCalories
                let targetPercentages = this.percentages
                newValue = Number(newValue)
                if (valueType === 'value') {
                    this.mealPlan.substanceDistribution[nutrientType].value = parseFloat(newValue.toFixed(2))
                    this.mealPlan.substanceDistribution[nutrientType].percentage = parseFloat(
                        (((newValue * this.caloriesPerGram[nutrientType]) / totalCalories) * 100).toFixed(2)
                    )
                } else if (valueType === 'percentage') {
                    this.mealPlan.substanceDistribution[nutrientType].percentage = parseFloat(newValue.toFixed(2))
                    this.mealPlan.substanceDistribution[nutrientType].value = parseFloat(
                        (((newValue / 100) * totalCalories) / this.caloriesPerGram[nutrientType]).toFixed(2)
                    )
                }

                let adjustedCalories =
                    this.mealPlan.substanceDistribution[nutrientType].value * this.caloriesPerGram[nutrientType]
                let remainingCalories = totalCalories - adjustedCalories

                let otherNutrients = ['carbs', 'protein', 'fat'].filter((nutrient) => nutrient !== nutrientType)
                let totalTargetPercentageOfOthers = otherNutrients.reduce(
                    (sum, nutrient) => sum + targetPercentages[nutrient],
                    0
                )
                let currentPercentageOfTotal = this.mealPlan.substanceDistribution[nutrientType].percentage

                otherNutrients.forEach((nutrient) => {
                    let targetPercentageOfThisNutrient =
                        (targetPercentages[nutrient] / totalTargetPercentageOfOthers) * (100 - currentPercentageOfTotal)
                    this.mealPlan.substanceDistribution[nutrient].percentage = parseFloat(
                        targetPercentageOfThisNutrient.toFixed(2)
                    )
                    this.mealPlan.substanceDistribution[nutrient].value = parseFloat(
                        (
                            ((targetPercentageOfThisNutrient / 100) * totalCalories) /
                            this.caloriesPerGram[nutrient]
                        ).toFixed(2)
                    )
                })
            }

            this.substanceFilter = JSON.parse(JSON.stringify(this.mealPlan.substanceDistribution))
            if (this.mealPlan.selectedPossibility.toString() === 'Eigene angabe (Keine automatische Anpassungen)') {
                this.substanceFilter[nutrientType].percentage =
                    Math.round(
                        ((Number(newValue) * this.caloriesPerGram[nutrientType]) /
                            Number(this.mealPlan.dailyCalories)) *
                        1000
                    ) / 10
            }
        },
        async saveNewNutritionPlan() {
            let finishedMealPlan = JSON.parse(JSON.stringify(this.mealPlan))
            this.fetchingReceipt.receiptCount = finishedMealPlan.days.reduce((total, day) => {
                return (
                    total +
                    day.mealDistribution.reduce((subTotal, distribution) => {
                        let count = distribution.variationCount || 0
                        return subTotal + count
                    }, 0)
                )
            }, 0)
            this.isFetchingReceipts = true
            this.fetchingReceipt.receiptState = 0

            for (const day of finishedMealPlan.days) {
                this.fetchingReceipt.day = day.dayText
                for (const distribution of day.mealDistribution) {
                    let index = 0
                    this.fetchingReceipt.mealCategory = distribution.text
                    if (!distribution.recipes || !distribution.recipes.length) {
                        let totalCalories = (this.mealPlan.dailyCalories / 100) * distribution.percentage
                        distribution.substanceDistributions = {
                            carbs: {
                                value: (
                                    (totalCalories * this.percentages.carbs) /
                                    100 /
                                    this.caloriesPerGram.carbs
                                ).toFixed(2),
                                percentage: this.percentages.carbs,
                            },
                            protein: {
                                value: (
                                    (totalCalories * this.percentages.protein) /
                                    100 /
                                    this.caloriesPerGram.protein
                                ).toFixed(2),
                                percentage: this.percentages.protein,
                            },
                            fat: {
                                value: (
                                    (totalCalories * this.percentages.fat) /
                                    100 /
                                    this.caloriesPerGram.fat
                                ).toFixed(2),
                                percentage: this.percentages.fat,
                            },
                        }
                        continue
                    }
                    for (const receipt of distribution.recipes) {
                        this.fetchingReceipt.receiptState++
                        if (receipt.optional && receipt.optional.title) {
                            this.fetchingReceipt.receipt = receipt.optional.title
                            let recipe = await api.getReceiptNutritionApi(receipt.id, this.mealPlan.dailyCalories)
                            distribution.recipes[index] = recipe.data
                        }
                        index++
                    }
                }
            }
            try {
                await api.saveMealPlanToNutritionUser({ mealPlan: finishedMealPlan, user: this.user._id })
                this.$toast.success('Ernährungsplan wurde gespeichert')
                this.$emit('close', finishedMealPlan)
            } catch (e) {
                console.log(e)
                this.$toast.error('Fehler beim speichern des Ernährungsplans')
            }
        },
        changeSubstanceToggle(recipe, value) {
            this.$set(recipe, 'substanceToggle', value)
            this.$forceUpdate()
        },
        changeDiagramToggle(recipe, value) {
            this.$set(recipe, 'diagramToggle', value)
            this.$forceUpdate()
        },
        copyRecipesFromDayBefore(stepIndex, meal) {
            return alert('DEAKTIVIERT')
            try {
                let dayBefore = this.mealPlan.days[stepIndex - 1]
                let mealDayBefore = dayBefore.mealDistribution.find((distri) => distri.text === meal.text)
                let recipes = JSON.parse(JSON.stringify(mealDayBefore.recipes))
                if (recipes) {
                    this.$set(meal, 'recipes', recipes)
                } else {
                    throw Error('no recipes Found')
                }
            } catch (e) {
                this.$toast.error('Konnte keine Rezepte vom Vortag laden')
            }
        },
        copyFromDay(val) {
            this.$set(
                this.mealPlan.days.find((mpDay) => mpDay.day === this.dayActive),
                'mealDistribution',
                JSON.parse(JSON.stringify(this.mealPlan.days.find((mpDay) => mpDay.day === val).mealDistribution))
            )
            this.$set(
                this.mealPlan.days.find((mpDay) => mpDay.day === this.dayActive),
                'mealCategories',
                JSON.parse(JSON.stringify(this.mealPlan.days.find((mpDay) => mpDay.day === val).mealCategories))
            )
            this.copyActive = false
        },
        setMealplanDays() {
            this.$set(
                this.mealPlan,
                'days',
                this.mealPlan.selectedDays.map((day) => {
                    return {
                        day: day,
                        dayText: this.dayPossibilities.find((x) => x.value === day).text,
                        mealCategories: [],
                        mealDistribution: [],
                    }
                })
            )
            this.dayActive = this.mealPlan.days[0].day
        },
        toggleSearchActive(index) {
            if (this.activeSearches[index]) {
                this.activeSearches[index] = false
            } else {
                this.activeSearches[index] = true
            }
            this.$forceUpdate()
        },
        async addNoteForMeal(meal) {
            this.addNoteMeal = meal
            this.showNoteDialog = true
        },
        async searchCookbutlerForRecipes(step) {
            if (!step.variationCount) step.variationCount = 6
            step.filters.diet = this.diet
            step.filters.allergens = this.allergens
            step.filters.substanceDistribution = this.substanceFilter
            let totalCalories = (this.mealPlan.dailyCalories / 100) * step.percentage
            step.substanceDistributions = {
                carbs: {
                    value: ((totalCalories * this.percentages.carbs) / 100 / this.caloriesPerGram.carbs).toFixed(2),
                    percentage: this.percentages.carbs,
                },
                protein: {
                    value: ((totalCalories * this.percentages.protein) / 100 / this.caloriesPerGram.protein).toFixed(2),
                    percentage: this.percentages.protein,
                },
                fat: {
                    value: ((totalCalories * this.percentages.fat) / 100 / this.caloriesPerGram.fat).toFixed(2),
                    percentage: this.percentages.fat,
                },
            }
            this.searchingForRecipes = true
            let recipes = await api.getReceiptByFilter(step)
            step.recipes = recipes.data.recipes
            step.offset = step.offset ? step.offset + step.variationCount : step.variationCount
            if (recipes.data.total.value < step.offset) {
                step.offset = 0
            }
            if (!recipes?.data?.recipes?.length)
                this.$toast.error(
                    'Es wurden keine Ergebnisse gefunden. Erlaube eine Höhere Abweichung um mehr Resultate zu finden'
                )
            this.searchingForRecipes = false
        },
        async searchNewRecipe(step, recipeIndex, mealDistributionIndex) {
            let protoStep = JSON.parse(JSON.stringify(step))
            step.offset += 1
            protoStep.variationCount = 1
            let recipes = await api.getReceiptByFilter(protoStep)

            if (recipes.data.recipes[0]) {
                step.recipes[recipeIndex] = recipes.data.recipes[0]
                if (recipes.data.total.value < step.offset) {
                    step.offset = 0
                }
            } else {
                this.$toast.error('Keine Rezepte mit den angegebenen Filtern gefunden')
            }
            this.searchingForRecipes = false
            this.$forceUpdate()
        },
        async toggleSearchFullRecipe(step, recipeIndex, recipeID) {
            let recipe = await api.getReceiptNutritionApi(
                recipeID,
                Math.round((this.mealPlan.dailyCalories / 100) * step.percentage)
            )
            step.recipes[recipeIndex] = recipe.data
            this.$forceUpdate()
        },
        async addRecipeToNutritionFavorites(recipe) {
            try {
                let favoritedRecipe = {
                    id: recipe.id ? recipe.id : recipe.recipe.id_recipe,
                    title: recipe.optional ? recipe.optional.title : recipe.recipe.title,
                    img: recipe.media ? recipe.media.search[0] : recipe.recipe.media.search[0],
                }
                // Funktion zur Überprüfung, ob das Rezept bereits im Favoriten-Array vorhanden ist
                await api.addRecipeToNutritionFavorites(favoritedRecipe)
                let index = this.userRecipeFavorites.findIndex((item) => item.id === favoritedRecipe.id)

                if (index !== -1) {
                    // Rezept ist bereits im Favoriten-Array, also entfernen
                    this.userRecipeFavorites.splice(index, 1)
                    this.$toast.success('Rezept wurde aus den Favoriten entfernt')
                } else {
                    // Rezept ist nicht im Favoriten-Array, also hinzufügen
                    this.$toast.success('Rezept wurde zu Favoriten hinzugefügt')
                    this.userRecipeFavorites.push(favoritedRecipe)
                }
            } catch (e) {
                console.log(e)
                this.$toast.error('Fehler beim hinzufügen des Favoriten')
            }
            this.$forceUpdate()
        },
        updateReceipt(newReceipt, step, recipeIndex, stepIndex) {
            step.mealDistribution[stepIndex].recipes[recipeIndex] = newReceipt
            this.$forceUpdate()
        },
        nextStep() {
            if (!this.mealPlan || !this.mealPlan.selectedDays || !this.mealPlan.selectedDays.length) {
                return this.$toast.error('Wähle mindestens einen Tag aus.')
            }
            let step = this.stepperModel + 1
            if (step === 2) {
                let dayWithError = ''
                this.mealPlan.days.forEach((day) => {
                    let is100 =
                        Math.round(
                            (day.mealDistribution.reduce((total, item) => {
                                return Number(total) + Number(item.percentage)
                            }, 0) *
                                100) /
                            100
                        ) === 100
                    if (!is100) dayWithError += day.dayText + ' '
                })
                dayWithError
                    ? this.$toast.error(dayWithError + ': Das Total muss jeweils 100% ergeben')
                    : this.stepperModel++
            } else {
                this.stepperModel++
            }
        },
        adjustRecipeForCalories(step, index, recipe, maxCalorieDeviation = 100) {
            let targetCaloriesPerPortion = Math.round((this.mealPlan.dailyCalories / 100) * step.percentage)

            // 1. Aktuelle Kalorien pro Portion berechnen:
            const currentCaloriesPerPortion = recipe.substances.find((s) => s.substance === 'Energie (Kilokalorien)')
                .portion.amount

            // 2. Skalierungsfaktor ermitteln:
            let scaleFactor = targetCaloriesPerPortion / currentCaloriesPerPortion

            // 3. Skalierungsfaktor anpassen, um innerhalb der Kalorienabweichung zu bleiben:
            let adjustedScaleFactor = Math.round(scaleFactor * 2) / 2 // Auf 0.5er Schritte runden
            let scaledCalories = currentCaloriesPerPortion * adjustedScaleFactor

            let iterationCount = 0 // Zähler für Schleifendurchläufe
            let maxIterations = 10
            while (
                Math.abs(scaledCalories - targetCaloriesPerPortion) > maxCalorieDeviation &&
                iterationCount < maxIterations
            ) {
                if (scaledCalories < targetCaloriesPerPortion) {
                    adjustedScaleFactor += 0.5
                } else {
                    adjustedScaleFactor -= 0.5
                }
                scaledCalories = currentCaloriesPerPortion * adjustedScaleFactor
                iterationCount++
            }

            // Wenn die Schleife aufgrund zu vieler Iterationen abgebrochen wurde, gib eine Warnung aus:
            if (iterationCount >= maxIterations) {
                if (
                    confirm(
                        'Die Kalorien des Rezepts konnten nicht angepasst werden, ohne, dass es zu Kommastellen bei Zutaten kommen kann. Möchtest du es trotzdem anpassen?'
                    )
                ) {
                    adjustedScaleFactor = Math.round(scaleFactor * 10) / 10 // Auf 1 Nachkommastelle runden
                    scaledCalories = currentCaloriesPerPortion * adjustedScaleFactor

                    // Abweichung überprüfen und ggf. korrigieren:
                    if (Math.abs(scaledCalories - targetCaloriesPerPortion) > maxCalorieDeviation) {
                        // Wenn die Abweichung zu groß ist, den Skalierungsfaktor direkt so anpassen, dass die Zielkalorien erreicht werden:
                        adjustedScaleFactor = targetCaloriesPerPortion / currentCaloriesPerPortion
                    }
                } else {
                    return
                }
            }

            // 4. Rezept anpassen:
            for (const substance of recipe.substances) {
                substance.portion.amount = Math.round(substance.portion.amount * adjustedScaleFactor * 100) / 100
                substance.recipe.amount = Math.round(substance.recipe.amount * adjustedScaleFactor * 100) / 100 // Für Gesamtmenge
            }

            // 6. Zutaten anpassen (optional):
            for (const ingredient of recipe.ingredients) {
                if (ingredient.quantity1) {
                    ingredient.quantity1 = Math.round(ingredient.quantity1 * adjustedScaleFactor * 10) / 10
                }
            }
            this.$set(step, index, recipe)
            this.$forceUpdate()
        },
        resetMealDistribution() {
            let activeMealPlanDay = this.mealPlan.days.find((day) => day.day === this.dayActive)

            let distriWithRecipes = activeMealPlanDay.mealDistribution.map((distri) => {
                return JSON.parse(JSON.stringify({ value: distri.value, recipes: distri.recipes }))
            })

            let newMealDistribution = activeMealPlanDay.mealCategories.map((cat) => ({
                ...cat,
                percentage: Math.round((100 / activeMealPlanDay.mealCategories.length) * 1000) / 1000,
            }))

            for (let distri of newMealDistribution) {
                distri.recipes =
                    distriWithRecipes.find((distriRecipes) => distriRecipes.value === distri.value)?.recipes || []
            }

            this.$set(
                this.mealPlan.days.find((day) => day.day === this.dayActive),
                'mealDistribution',
                newMealDistribution
            )
        },
    },
}
</script>
