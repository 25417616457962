<template>
    <div>
        <v-btn fab dark color="white" @click="$router.push('/shop')">
            <v-icon color="black">mdi-chevron-left</v-icon>
        </v-btn>
        <v-card-title class="font-weight-bold" style="word-break: break-word">
            Personalisiere deine Produkte vor dem Kauf
        </v-card-title>

        <v-divider></v-divider>
        <v-form @submit.prevent ref="shopCheckoutForm" class="ma-auto" style="max-width: 800px" lazy-validation
            novalidate>
            <v-row no-gutters>
                <v-col cols="12" class="mb-2 py-1" v-for="(product, index) in mappedCartProducts"
                    :key="product.title + index" link>
                    <v-row no-gutters>
                        <v-col cols="8">
                            <v-card-title style="font-weight: bold">{{ product.title }}</v-card-title>
                            <v-card-subtitle>{{ product.shortDescription }}</v-card-subtitle>
                        </v-col>
                        <v-col cols="4" class="align-self-center">
                            <v-img :aspect-ratio="1.6" :src="'/img/' + product.picture"></v-img>
                        </v-col>
                        <v-col cols="12">
                            <v-card-title>Für wen ist dieses Produkt?</v-card-title>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field class="pr-2" label="Vorname" filled rounded :rules="rules.required"
                                v-model="productPersonalisations[index].prename"></v-text-field>
                            <br />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field class="pl-2" label="Nachname" filled rounded :rules="rules.required"
                                v-model="productPersonalisations[index].lastname"></v-text-field>
                            <br />
                        </v-col>
                        <v-col cols="12">
                            <v-textarea filled rounded label="Text auf dem Gutschein" :rules="rules.required"
                                counter="550" v-model="productPersonalisations[index].text"></v-textarea>
                        </v-col>
                        <v-col cols="6" class="align-self-center">
                            <v-btn @click="createTestPrintversion(product, index)" color="primary">
                                Beispiel Ausdruck
                                <v-icon>mdi-eye</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-card-title class="h3 primary--text" style="justify-content: right">
                                CHF {{ product.price }}.-
                            </v-card-title>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                </v-col>
            </v-row>
        </v-form>
        <v-card-actions class="justify-content-center">
            <v-btn color="primary" rounded x-large @click="buyProducts()">
                <span class="pa-2" style="word-break: break-word">Für {{ cartTotalPrice }} CHF Kaufen</span>
            </v-btn>
        </v-card-actions>
        <v-dialog style="overflow: hidden;" @click:outside="paymentInitiated = false" v-model="paymentInitiated"
            persistent>
            <payment-dialog @completed="adyenPaymentCompleted" :amount="paymentAmount" :resortID="paymentResortID"
                :studio="paymentStudio" :purchaseID="purchaseID" :profileID="profileID"
                v-if="paymentInitiated"></payment-dialog>
        </v-dialog>
    </div>
</template>

<script>
import api from '../../api'
import { mapGetters, mapActions, mapState } from 'vuex'
import PaymentDialog from "../payment/paymentDialog.vue";


export default {
    beforeDestroy() {
        //window.removeEventListener('beforeunload', this.preventPageReload)
    },
    components: {
        PaymentDialog
    },
    data() {
        return {
            productPersonalisations: [],
            paymentInitiated: false,
            purchaseID: null,
            profileID: null,
            paymentAmount: null,
            paymentInitiated: false,
            paymentStudio: '5f326a24ae8863640c859e75',
            paymentResortID: '1',
            drawer: false,
            products: [],
            rules: {
                required: [(v) => !!v || 'Dieses Feld ist erforderlich'],
                maxLength750: [(v) => v.length <= 550 || 'Nicht mehr als 550 Zeichen'],
            },
        }
    },
    async created() {
        //window.addEventListener('beforeunload', this.preventPageReload)

        if (!this.cartProducts.length) this.$router.push('/shop')
        let amount = this.cartProducts.reduce((a, b) => a + b.amount, 0)
        for (let i = 0; i < amount; i++) {
            this.productPersonalisations.push({ prename: '', lastname: '', text: '' })
        }
    },
    computed: {
        ...mapState('shopStore', ['cartVisibilityToggle', 'cartProducts']),
        ...mapGetters('shopStore', ['cartTotalPrice']),
        mappedCartProducts() {
            let mappedProducts = []
            this.cartProducts.forEach((product) => {
                for (let i = 0; i < product.amount; i++) {
                    mappedProducts.push(product)
                }
            })
            return mappedProducts
        },
    },
    methods: {
        ...mapActions('shopStore', ['addProductToCart', 'removeProductFromCart', 'openProductFromStore']),
        /*    preventPageReload(event) {
                // Check if the dialog is open, and if so, prevent the page reload
    
                event.preventDefault()
                event.returnValue = ''
            },*/
        async createTestPrintversion(product, index) {
            if (
                this.productPersonalisations[index].prename &&
                this.productPersonalisations[index].lastname &&
                this.productPersonalisations[index].text
            ) {
                await api.productExamplePrintVersion({ ...product, ...this.productPersonalisations[index] })
            } else {
                this.$toast.error('Fülle alle Felder aus')
            }
        },
        async buyProducts() {
            if (!this.$refs.shopCheckoutForm.validate()) {
                this.$toast.error('Fülle alle Felder aus.')
                return
            }
            let preparedProducts = this.cartProducts.map((product, index) => {
                return {
                    ...product,
                    personalization: this.productPersonalisations[index],
                }
            })
            let createdPayment = await api.createProductPayment(preparedProducts)
            this.paymentAmount = createdPayment.amount
            this.purchaseID = createdPayment._id

            this.paymentInitiated = true
        },
        openProduct(id) {
            this.openProductFromStore(id)
        },
    },
    watch: {
        cartVisibilityToggle() {
            this.drawer = true
        },
    },
}
</script>