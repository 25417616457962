<!-- Payment.vue -->
<template>
    <v-card flat>
        <v-row class="mt-5">
            <v-col cols="12" class="d-flex justify-start align-center">
                <v-btn x-large icon @click="$router.push('/user')"><v-icon color="#343434">mdi-chevron-left</v-icon></v-btn>
                <span class="h6 mb-0">Einkäufe</span>
            </v-col>
        </v-row>
        <v-row v-if="purchases">
            <v-expansion-panels v-model="purchasesModel" dark v-if="purchases">
                <v-expansion-panel v-for="(purchase, i) of purchases" :key="i + '-purchaseIndex'">
                    <v-expansion-panel-header
                        ><span
                            >Einkauf vom: {{ $moment(purchase.createdAt).format('DD.MM.YY HH:mm') }} Uhr |
                            {{ purchase.products.length }} {{ purchase.products.length > 1 ? 'Produkte' : 'Produkt' }}
                            <v-btn icon color="white" @click="pushToRoute(purchase)"><v-icon>mdi-eye</v-icon></v-btn>
                        </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-col cols="12" v-for="(product, index) of purchase.products" :key="'Product-' + index">
                            <v-card-title
                                >{{ product.title }} |
                                <span class="primary--text ml-2"> {{ ' ' + product.price }} CHF </span>
                                <v-btn icon
                                    ><v-icon color="white" @click="getDownloadForProduct()">mdi-file-download</v-icon></v-btn
                                ></v-card-title
                            >
                        </v-col>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
        <v-row v-else>
            <v-alert type="info">Keine Gutscheine gefunden</v-alert>
        </v-row>
    </v-card>
</template>
   
<script>
import axios from 'axios'
import api from '@/api'
import userService from '@/services/userService'

export default {
    data() {
        return {
            purchasesModel: null,
            purchases: [],
        }
    },
    async created() {
        this.purchases = await api.fetchAllPurchasesDetails()
        if (!this.purchases || !this.purchases.length) {
            this.$toast.error('Es wurden keine Einkäufe gefunden.')
            this.$router.push('/user')
        }
    },
    methods: {
        pushToRoute(purchase) {
            this.$router.push('/shop/purchase/infos/' + purchase.transactionId)
            console.log(purchase)
        },
    },
    computed: {},
}
</script>
  