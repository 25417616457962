<template>
    <div class="popups">
        <v-card dark>
            <v-row>
                <v-col cols="12" class="d-flex justify-center">
                    <v-btn @click="createPopup" color="primary">Erstellen</v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pa-7" cols="12" md="6" v-for="popup of popups" :key="popup._id">
                    <v-card class="fill-height " outlined @click="editPopup(popup._id)">
                        <v-row no-gutters>
                            <v-col class="d-flex justify-center" cols="12">
                                <v-chip style="margin-top: -20px" :color="popupStatusColor(popup)">
                                    {{ popupStatus(popup) }}
                                </v-chip>
                            </v-col>
                        </v-row>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-chip v-bind="attrs" v-on="on" small class="ml-2" color="blue" outlined>
                                    {{ popup.studios.length }} Studios
                                </v-chip>
                            </template>
                            <span>
                                {{ popupStudioNames(popup).join(', ') }}
                            </span>
                        </v-tooltip>
                        <v-card-title>{{ popup.title }}</v-card-title>
                        <v-card-subtitle> {{ popupLocationText(popup.popupLocation) }}</v-card-subtitle>
                        <v-card-subtitle v-if="popup.employeesOnly"> Nur für Mitarbeiter</v-card-subtitle>
                        <v-card-subtitle>{{ popupTime(popup) }}</v-card-subtitle>

                    </v-card>

                </v-col>
            </v-row>

        </v-card>
        <v-dialog v-model="showPopupDialog" fullscreen persistent>


            <v-card class="mx-auto" dark>
                <v-container>
                    <v-card-title>
                        Popup
                    </v-card-title>
                    <div>
                        <v-text-field class="mx-5 mt-1" v-model="popup.title" label="Titel"
                            placeholder="11 Tages Kampagne"></v-text-field>

                        <!-- Bild -->
                        <slim-cropper :key="popup.image" style="width: 300px; margin: auto" class="d-table"
                            :options="slimOptionsExercise">
                            <img :key="popup.image" v-if="popup.image" :src="'/img/' + popup.image" alt="" />
                            <input type="file" accept="image/*" />
                        </slim-cropper>
                        <v-card-subtitle class="text-center">Format: 16:9 (1920x1080)</v-card-subtitle>

                        <!-- Studios -->
                        <v-select class="mx-5" v-model="popup.studios" @change="updateStudios" :items="studios"
                            item-text="name" item-value="_id" attach chips label="Studios" multiple></v-select>

                        <!-- Anzeige Ort -->
                        <v-radio-group @change="changePopupLocation" class="mx-5" v-model="popup.popupLocation">
                            <v-radio label="nur Webseite" value="website"></v-radio>
                            <v-radio label="nur roody" value="roody"></v-radio>
                            <v-radio label="auf beiden anzeigen" value="both"></v-radio>
                        </v-radio-group>


                        <v-switch v-if="popup.popupLocation === 'website' || popup.popupLocation === 'both'"
                            class="mx-5 mt-1" color="primary" v-model="popup.visibility.everywhere"
                            label="auf Startseite (Webseite)"></v-switch>

                        <v-switch v-if="popup.popupLocation === 'roody'" class="mx-5 mt-1" color="primary"
                            v-model="popup.employeesOnly" label="Nur für Mitarbeiter"
                            @change="setOnlyEmployee"></v-switch>

                        <v-row class="mb-5">
                            <v-col cols="6"> <!-- Datumsauswahl Show After -->
                                <v-menu ref="showAfterMenu" v-model="showAfterMenu" :close-on-content-click="false"
                                    transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="mx-5 mt-1" v-model="formattedShowAfter"
                                            label="Ab dem Datum anzeigen" prepend-icon="mdi-calendar" readonly
                                            v-bind="attrs" v-on="on" clearable
                                            @click:clear="clearShowAfter"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="popup.showAfter" @input="showAfterMenu = false" locale="de"
                                        scrollable>
                                        <v-btn text color="primary" @click="setShowAfterNull" class="ma-2">Datum
                                            entfernen</v-btn>
                                    </v-date-picker>
                                </v-menu></v-col>
                            <v-col cols="6"><!-- Datumsauswahl Hide After -->
                                <v-menu ref="hideAfterMenu" v-model="hideAfterMenu" :close-on-content-click="false"
                                    transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="mx-5 mt-1" v-model="formattedHideAfter"
                                            label="Nach dem Datum ausblenden" prepend-icon="mdi-calendar" readonly
                                            v-bind="attrs" v-on="on" clearable
                                            @click:clear="clearHideAfter"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="popup.hideAfter" @input="hideAfterMenu = false" locale="de"
                                        scrollable>
                                        <v-btn text color="primary" @click="setHideAfterNull" class="ma-2">Datum
                                            entfernen</v-btn>
                                    </v-date-picker>
                                </v-menu></v-col>
                        </v-row>


                        <vue-editor :editorToolbar="customToolbar" v-model="popup.text"></vue-editor>

                    </div>
                    <v-card-actions class="my-5">
                        <v-btn @click="cancel" text>Abbrechen</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn :disabled="!formValidated" @click="save" color="primary">Speichern</v-btn>
                    </v-card-actions>
                </v-container>
            </v-card>

        </v-dialog>
    </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import Slim from '../../../plugins/slimImage/slim/slim.vue'
import api from '@/api'
import moment from 'moment'

export default {
    name: 'popups',
    components: { VueEditor, 'slim-cropper': Slim },
    data() {
        return {

            studios: [],
            popup: {
                popupLocation: 'roody',
                hideAfter: null,
                showAfter: null,
                visibility: {
                    everywhere: false,
                    paths: [],
                },
                title: '',
                text: '',
                image: '',
                employeesOnly: false,
                link: '',
                linkText: '',
                studios: [],
            },
            showPopupDialog: false,
            popups: [],
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
                [{ indent: '-1' }, { indent: '+1' }],
                [{ color: [] }, { background: [] }],
                ['link', 'video'],
                ['clean'],
            ],
            slimOptionsExercise: {
                ratio: '16:9',
                initialImage: '/img/einmalig/imageUploadPlaceholder.jpg',
                buttonCancelLabel: 'Abbrechen',
                buttonConfirmLabel: 'Bestätigen',
                label: 'Bild hochladen',
                labelLoading: 'Bild wird hochgeladen',
                statusUploadSuccess: 'gespeichert',
                service: this.uploadPicture,
                serviceFormat: 'file',
                instantEdit: true,
                push: true,
            },
            showAfterMenu: false,
            hideAfterMenu: false
        }
    },
    async created() {
        this.fetchPopups()
        let studios = await api.fetchStudios();
        studios = studios.filter((e) => e.code !== "alibey" && e.code !== "ot");
        this.studios = studios;
    },

    methods: {
        popupStudioNames(popup) {
            return popup.studios.map(studioId => {
                const studio = this.studios.find(s => s._id === studioId)
                return studio ? studio.name : 'unbekanntes Studio'
            })
        },
        popupTime(popup) {
            const { showAfter, hideAfter } = popup
            const format = (date) => moment(date).format('DD.MM.YYYY')

            if (showAfter && hideAfter) {
                return `Von ${format(showAfter)} bis ${format(hideAfter)}`
            } else if (showAfter && !hideAfter) {
                return `Ab ${format(showAfter)}`
            } else if (!showAfter && hideAfter) {
                return `Bis ${format(hideAfter)}`
            } else {
                return 'Keine Zeit festgelegt'
            }
        },
        popupLocationText(location) {
            if (location === 'roody') return 'nur Roody'
            if (location === 'website') return 'nur Webseite'
            return 'Webseite und roody'
        },
        popupStatus(popup) {
            const now = moment()
            if (popup.hideAfter && moment(popup.hideAfter).isBefore(now, 'day')) {
                return 'ausgeblendet'
            } else if (popup.showAfter && moment(popup.showAfter).isAfter(now, 'day')) {
                const diff = moment(popup.showAfter).diff(now, 'days') + 1
                return `startet in ${diff} Tag${diff > 1 ? 'en' : ''}`
            } else {
                return 'aktiv'
            }
        },
        popupStatusColor(popup) {
            const status = this.popupStatus(popup)
            if (status === 'aktiv') return 'green'
            if (status.includes('startet')) return 'orange'
            return 'grey'
        },
        changePopupLocation() {
            this.popup.employeesOnly = false
            let val = this.popup.popupLocation
            if (val === 'both') {
                this.setWebsiteStudios()
            } else if (val === 'website') {
                this.setWebsiteStudios()
            } else {
                this.popup.visibility = {
                    everywhere: false,
                    paths: [],
                }
            }
        },
        async createPopup() {
            this.resetPopup()
            this.showPopupDialog = true
        },
        async editPopup(id) {
            this.resetPopup()
            try {
                let popup = await api.PopupService.get(id)
                if (popup && popup._id) {

                    if (popup.showAfter) popup.showAfter = this.$moment(popup.showAfter).format('YYYY-MM-DD')
                    if (popup.hideAfter) popup.hideAfter = this.$moment(popup.hideAfter).format('YYYY-MM-DD')

                    this.popup = popup
                    this.showPopupDialog = true
                } else {
                    throw Error("Fehler beim Laden")
                }
            } catch (e) {
                this.$toast.error("Fehler beim Laden")
            }
        },
        cancel() {
            this.resetPopup()
            this.showPopupDialog = false
        },
        async save() {
            if (this.popup._id) {
                try {
                    let updatedPopup = await api.PopupService.update(this.popup._id, this.popup)

                    this.cancel()
                    this.fetchPopups()

                } catch (e) {
                    this.$toast.error("Fehler beim Speichern")
                }

            } else {
                try {
                    let savedPopup = await api.PopupService.create(this.popup)
                    this.cancel()
                    this.fetchPopups()
                } catch (e) {
                    this.$toast.error("Fehler beim Erstellen")
                }

            }
        },
        setOnlyEmployee(val) {
            if (val) {
                this.popup.popupLocation = 'roody'
            }
        },
        updateStudios() {
            if (this.popup.popupLocation !== 'roody')
                this.setWebsiteStudios()
        },
        setWebsiteStudios() {
            this.popup.visibility.paths = this.popup.studios.map((studioId) => {
                let studio = this.studios.find((e) => e._id === studioId)
                if (studio && studio.websitePath) return studio.websitePath
                return '/studioerror'
            })
        },
        resetPopup() {
            this.popup = {
                popupLocation: 'roody',
                hideAfter: null,
                showAfter: null,
                visibility: {
                    everywhere: false,
                    paths: [],
                },
                title: '',
                text: '',
                image: '',
                employeesOnly: false,
                link: '',
                linkText: '',
                studios: [],
            }
        },
        async fetchPopups() {
            try {
                this.popups = await api.PopupService.getAll()
            } catch (e) {
                console.log(e)
                this.$toast.error("Fehler beim Laden")
            }
        },
        uploadPicture(formdata, progress, success, failure, slim) {
            const formData = new FormData()
            formData.append('file', formdata[0])

            let uploadimg = {}
            uploadimg.art = 'popup'
            uploadimg.formData = formData

            api.uploadImage(uploadimg)
                .then((data) => {
                    if (data.status) {
                        this.popup.image = data.name
                        success(data)
                    }
                })
                .catch((error) => {
                    failure(error)
                    console.log(error)
                })
        },
        setShowAfterNull() {
            this.popup.showAfter = null
        },
        setHideAfterNull() {
            this.popup.hideAfter = null
        },
        clearShowAfter() {
            this.popup.showAfter = null
        },
        clearHideAfter() {
            this.popup.hideAfter = null
        }
    },
    computed: {
        formattedShowAfter() {
            return this.popup.showAfter ? moment(this.popup.showAfter).format('DD.MM.YYYY') : ''
        },
        formattedHideAfter() {
            return this.popup.hideAfter ? moment(this.popup.hideAfter).format('DD.MM.YYYY') : ''
        },
        formValidated() {
            return (this.popup.title.length > 3 && this.popup.studios.length > 0 && (this.popup.image.length > 3 || this.popup.text.length > 3))
        }
    },
    watch: {

    },
}
</script>

<style lang="scss" scoped></style>