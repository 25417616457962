const moment = require('moment')
const api = require('../../api').default
export class formUpdateHabit {
	constructor(
		formUpdateHabit = {
			label: 'Fortschritt',
			type: 'formUpdateHabit',
			description: '',
			icon: 'formUpdate.png',
			streak: 0,
			days: [0, 1, 2, 3, 4, 5, 6],
			notificationTime: '',
			executions: {},
		}
	) {
		this.setFormUpdateHabit(formUpdateHabit)
	}
	getFormUpdateHabit() {
		let habit = {}
		for (let key in this) {
			habit[key] = this[key]
		}
		return habit
	}
	setFormUpdateHabit(habit) {
		for (let key in habit) {
			this[key] = habit[key]
		}
	}
	async execute(newItem) {
		// add execution and save habit
		this.executions[moment().format('YYYY-MM-DD')] = newItem
		return await this.save()
	}
	async updateExecution(date, newItem) {
		this.executions[date] = newItem
		return await this.save()
	}
	async delete() {
		try {
			return await api.deleteHabit(this._id)
		} catch (e) {
			console.log(e)
		}
	}
	async save() {
		try {
			let habit = await api.updateHabit(this)
			this.setFormUpdateHabit(habit)
			return habit
		} catch (error) {
			console.log('Error updating or saving habit: ', error)
		}
	}
}
