<template>
    <v-card
        height="100%"
        style="position: relative"
        width="100%"
        min-height="300"
        class="d-flex justify-center align-center flex-column py-5 rounded-xl"
    >
        <v-avatar v-if="getPicture(lead)" size="80" color="primary">
            <img style="object-fit: cover" v-if="getPicture(lead)" :src="getPicture(lead)" />
        </v-avatar>
        <v-avatar size="80" color="primary" v-else>
            <span class="white--text text-h5">{{ getInitials(lead) }}</span>
        </v-avatar>

        <v-card-title class="pb-0 pt-2 mx-auto">
            <div>{{ lead.name }} {{ lead.surname }}</div>
        </v-card-title>

        <v-chip color="primary" small class="mb-0 mt-2" v-if="daysRemaining(lead) > 0">noch {{ daysRemaining(lead) }} Tage</v-chip>
        <v-chip color="orange" small class="mb-0 mt-2" v-else text-color="white">Vor {{ daysRemaining(lead)*-1 }} Tagen abgelaufen</v-chip>

        <v-card-subtitle v-if="lead.email" class="pb-0 pt-4 mx-auto">
            <div>{{ lead.email }}</div>
        </v-card-subtitle>

        <v-card-subtitle v-if="lead.phone" class="pb-0 pt-0 mx-auto">
            <div>{{ lead.phone }}</div>
        </v-card-subtitle>

        <v-chip small class="mb-2 mt-2">
            {{ formatDate(lead.memberCard.startDate) }} bis
            {{ formatDate(lead.memberCard.endDate) }}
        </v-chip>

        <v-card-text class="pb-5 text-center">
            Erstellt von: {{ lead.memberCard.employee.vorname }}
            {{ lead.memberCard.employee.nachname }}
        </v-card-text>
        <v-select
            v-model="lead.status"
            :items="status"
            label="Status"
            outlined
            class="px-5"
            @change="saveLead(lead)"
        ></v-select>
        <v-textarea
            class="px-5"
            style="width: 100%"
            @blur="saveLead(lead)"
            v-model="lead.notes"
            label="Anmerkungen"
            outlined
        ></v-textarea>

        <v-card-actions v-if="lead.roodyUser">
            <v-btn class="primary" @click="goToProfile(lead.roodyUser._id)">zum Profil</v-btn>
        </v-card-actions>
    </v-card>
</template>
  
  <script>
import api from '@/api'
export default {
    name: 'MemberCardLead',
    props: {
        lead: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            status: [
                'Wurde angeworben',
                'Kontaktiert',
                'Probetraining vereinbart',
                'Vertrag abgeschlossen',
                'Kein Abschluss',
                'Geschlossen',
            ],
        }
    },
    methods: {
        async saveLead(lead) {
            let status = await api.updateLead(lead).catch((e) => this.$toast.error('Es gab einen Fehler'))
            if (status) {
                this.$toast.success('Erfolgreich aktualisiert')
            }
        },
        getPicture(entry) {
            if (!entry.roodyUser) return null
            let picture = entry.roodyUser.tac_image
                ? 'https://app.wellcomefit.ch/api/checkin/pool/images/' + entry.roodyUser._id
                : null

            if (entry.roodyUser.bild) {
                picture = '/img/profilbilder' + entry.roodyUser.bild.split('.')[0] + '.webp'
            }

            return picture
        },
        getInitials(lead) {
            return lead.name.charAt(0) + lead.surname.charAt(0)
        },
        formatDate(date) {
            return this.$moment(date).format('DD.MM.YYYY')
        },
        daysRemaining(lead) {
            return this.$moment(lead.memberCard.endDate).diff(this.$moment(), 'days') + 1
        },
        goToProfile(id) {
            this.$router.push(`/dashboard/user/search/${id}`)
        },
    },
}
</script>
  