// src/services/AppointmentService.js
import axios from 'axios'
const apiUrl = '/api/popups'

function buildOptions(options = {}, signal = null) {
    if (!options.headers) {
        options.headers = {}
    }

    if (signal) options.signal = signal

    options.headers.Authorization = localStorage.getItem('token')
    return options
}

class PopupService {
    getActive() {
        return axios.get(`${apiUrl}/roody`, buildOptions()).then((res) => res.data);
    }

    getAll() {
        return axios.get(apiUrl, buildOptions()).then((res) => res.data);
    }

    get(id) {
        return axios.get(`${apiUrl}/${id}`, buildOptions()).then((res) => res.data);
    }

    create(data) {
        return axios.post(apiUrl, data, buildOptions())
    }

    update(id, data) {
        return axios.patch(`${apiUrl}/${id}`, data, buildOptions())
    }

    delete(id) {
        return axios.delete(`${apiUrl}/${id}`, buildOptions())
    }
}

export default new PopupService()