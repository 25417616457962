<template>
    <v-dialog s persistent fullscreen v-model="showDialog">
        <v-card style="padding-bottom: 200px" max-width="700px" flat class="mt-5 mx-auto">
            <v-card-title class="primary white--text">11 Tage Kampagnen</v-card-title>
            <v-row no-gutters>
                <v-col class="pa-5" cols="6">
                    <v-btn elevation="2" class="mr-auto ml-2 mt-2 black--text" @click="$router.push('/panel')" large
                        icon>
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="12">
                    <v-tabs v-model="tab" centered>
                        <v-tab>Laufend</v-tab>
                        <v-tab>Vergangen</v-tab>
                        <v-tab>Abgeschlossen</v-tab>
                        <v-tab>Kein Abschluss</v-tab>
                    </v-tabs>
                </v-col>

                <v-col cols="12">
                    <v-text-field style="max-width: 1090px" class="mx-auto px-5 mt-5" v-model="search"
                        append-inner-icon="mdi-magnify" placeholder="Suche" outlined hide-details></v-text-field>
                </v-col>
            </v-row>
            <v-row v-if="loading">
                <v-col cols="12" class="d-flex justify-center">
                    <v-progress-circular color="primary" indeterminate></v-progress-circular>
                </v-col>
            </v-row>
            <v-row v-else-if="leads.length === 0">
                <v-col cols="12" class="d-flex justify-center">
                    <v-alert max-width="700px" class="mx-5" type="info">
                        Keine Membercards ab dem 09.10.2024 gefunden
                    </v-alert>
                </v-col>
            </v-row>
            <div v-else class="d-flex mx-auto" style="max-width: 1200px">
                <v-row class="mx-5">
                    <v-col cols="12">
                        <v-tabs-items v-model="tab">
                            <v-tab-item>
                                <v-row class="ma-1">
                                    <v-col cols="12" class="d-flex justify-center">
                                        <v-card-title>Anzahl: {{ filteredLeads.length }}</v-card-title>
                                    </v-col>
                                    <v-col v-for="lead of filteredLeads" :key="lead._id" cols="12" sm="6" md="4">
                                        <membercard-lead :lead="lead"></membercard-lead>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <v-row >
                                    <v-col cols="12" class="d-flex justify-center">
                                        <v-card-title>Anzahl: {{ pastLeads.length }}</v-card-title>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="durationSelect" label="Zeitraum des Enddatums" outlined
                                            hide-details :items="['letzter Monat', 'letzte 2 Monate']"></v-select>
                                    </v-col>
                                    <v-col class="mt-0 pt-0" cols="12" v-if="pastLeads.length === 0">
                                        <v-alert type="info">
                                            Keine vergangenen Membercards ab dem 09.10.2024 gefunden
                                        </v-alert>
                                    </v-col>
                                    <v-col cols="12" v-else>
                                        <v-row class="ma-1">
                                            <v-col v-for="lead of pastLeads" :key="lead._id" cols="12" sm="6" md="4">
                                                <membercard-lead :lead="lead"></membercard-lead>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <v-row >
                                    <v-col cols="12" class="d-flex justify-center">
                                        <v-card-title>Anzahl: {{ acceptedPastLeads.length }}</v-card-title>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="durationSelect" label="Zeitraum des Enddatums" outlined
                                            hide-details :items="['letzter Monat', 'letzte 2 Monate']"></v-select>
                                    </v-col>
                                    <v-col class="mt-0 pt-0" cols="12" v-if="acceptedPastLeads.length === 0">
                                        <v-alert type="info">
                                            Keine vergangenen Membercards ab dem 09.10.2024 gefunden
                                        </v-alert>
                                    </v-col>
                                    <v-col cols="12" v-else>
                                        <v-row class="ma-1">
                                            <v-col v-for="lead of acceptedPastLeads" :key="lead._id" cols="12" sm="6"
                                                md="4">
                                                <membercard-lead :lead="lead"></membercard-lead>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <v-row >
                                    <v-col cols="12" class="d-flex justify-center">
                                        <v-card-title>Anzahl: {{ declinedPastLeads.length }}</v-card-title>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-select v-model="durationSelect" label="Zeitraum des Enddatums" outlined
                                            hide-details :items="['letzter Monat', 'letzte 2 Monate']"></v-select>
                                    </v-col>
                                    <v-col class="mt-0 pt-0" cols="12" v-if="declinedPastLeads.length === 0">
                                        <v-alert type="info">
                                            Keine vergangenen Membercards ab dem 09.10.2024 gefunden
                                        </v-alert>
                                    </v-col>
                                    <v-col cols="12" v-else>
                                        <v-row class="ma-1">
                                            <v-col v-for="lead of declinedPastLeads" :key="lead._id" cols="12" sm="6"
                                                md="4">
                                                <membercard-lead :lead="lead"></membercard-lead>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-col>
                </v-row>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import api from '@/api'
import membercardLead from './components/membercardLead.vue'
export default {
    components: { membercardLead },
    data() {
        return {
            search: '',
            durationSelect: 'letzter Monat',
            showDialog: true,
            leads: [],
            pastLeads: [],
            acceptedPastLeads: [],
            declinedPastLeads: [],
            loading: true,
            tab: null,
        }
    },
    created() {
        this.showDialog = true
        this.init()
    },
    computed: {
        filteredLeads() {
            return this.leads.filter((e) => {
                if (this.search.length === 0) return true
                let fullName = (e.name + ' ' + e.surname).toLowerCase()
                let searchTerms = this.search.toLowerCase().trim().split(/\s+/) // Suche nach mehreren Begriffen

                // Überprüfe, ob alle Suchbegriffe im vollständigen Namen vorkommen
                return searchTerms.every((term) => fullName.includes(term))
            })
        },
    },
    methods: {
        async fetchPastMembercards() {
            try {
                this.loading = true
                let amount = this.durationSelect === 'letzter Monat' ? 30 : 60
                let membercardLeads = await api.fetchPastMembercardLeads(amount)
                this.pastLeads = membercardLeads.filter(lead => lead.status !== 'Vertrag abgeschlossen' && lead.status !== 'Kein Abschluss')
                this.acceptedPastLeads = membercardLeads.filter(lead => lead.status === 'Vertrag abgeschlossen')
                this.declinedPastLeads = membercardLeads.filter(lead => lead.status === 'Kein Abschluss')
            } catch (e) {
                this.$toast.error('Fehler beim Laden')
            } finally {
                this.loading = false
            }
        },
        getInitials(entry) {
            return entry.name.substr(0, 1) + entry.surname.substr(0, 1)
        },
        getPicture(entry) {
            if (!entry.roodyUser) return null
            let picture = entry.roodyUser.tac_image
                ? 'https://app.wellcomefit.ch/api/checkin/pool/images/' + entry.roodyUser._id
                : null

            if (entry.roodyUser.bild) {
                picture = '/img/profilbilder' + entry.roodyUser.bild.split('.')[0] + '.webp'
            }

            return picture
        },
        async init() {
            try {
                this.loading = true
                let membercardLeads = await api.fetchMembercardLeads()
                this.leads = membercardLeads
            } catch (e) {
                this.$toast.error('Fehler beim Laden')
            } finally {
                this.loading = false
            }
        },
    },
    watch: {
        durationSelect() {
            this.pastLeads = []
            this.fetchPastMembercards()
        },
        tab() {
            if (this.tab === 1 || this.tab === 2 && !this.pastLeads.length || !this.acceptedPastLeads.length || this.tab === 3 && !this.pastLeads.length) {
                this.fetchPastMembercards() 
            } else {
            }
        },
    },
}
</script>