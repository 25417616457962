<template>
<div class="instruktoren" style="max-width: 400px; margin: auto">
  <div class="d-flex flex-column intern">
    <!---<v-btn class="reportProblem" color="error d-flex ml-auto mr-5" @click="$router.push('/intern/report')"> Problem melden </v-btn><br />---->
    <!---<v-btn
				color="primary"
				target="_blank"
				v-show="userService.hasRight('g_intern')"
				href="https://app.wellcomefit.ch/img/download/roody_vortrag.pdf"
				>Vortrag.pdf</v-btn
			>-->

    <div v-if="userService.getUser"></div>
    <!---<v-btn class="mt-2" v-show="userService.hasRight('af_downloadList')" @click="downloadOAFFList" color="primary">
				Openair Frauenfeld Ticket Reservierungen
			</v-btn>
			<v-btn class="mt-2" v-show="userService.hasRight('af_downloadList')" @click="downloadPTList" color="primary">
				Personal Training Buchungen
			</v-btn>--->

    <!---<v-btn class="mt-2" v-show="userService.hasRight('af_downloadList')" @click="downloadChristmasLeads" color="primary">
				Weihnachten Kampagne
			</v-btn>--->
    <v-btn class="mt-2 rounded-xl" @click="downloadMeScanExcel" x-large v-show="userService.hasRight('c_create')"
      color="primary">
      MeScan Allianz Einträge
    </v-btn>
    <v-btn v-if="false" class="mt-2 rounded-xl" x-large @click="showPartyDialog = true"
      v-show="userService.hasRight('c_create')" color="primary">
      well come FIT Party Anmeldungen
    </v-btn>
    <v-btn class="mt-2 rounded-xl" x-large @click="downloadKampagne" v-show="userService.hasRight('af_downloadList')"
      color="primary">
      Weiterempfehlungen & 10 Tage Liste
    </v-btn>
    <v-btn class="mt-2 rounded-xl" x-large @click="downloadMembercards()"
      v-if="userService.hasRight('m_downloadList') && wcf()" color="primary">
      Membercards (10 Tage)
    </v-btn>
    <v-btn class="mt-2 rounded-xl" x-large v-show="userService.hasRight('af_downloadList')" @click="downloadAlibeyList"
      color="primary">
      Alibey Buchungen
    </v-btn>

    <v-btn class="mt-2 rounded-xl" x-large @click="downloadGFHours()" v-if="userService.hasRight('gf_s_view') && wcf()"
      color="primary">
      Stundenabrechnung Group Fitness
    </v-btn>
    <v-btn class="mt-2 rounded-xl" x-large @click="downloadAllianceEntries()" v-if="userService.hasRight('c_create')"
      color="primary">
      Allianz Einträge herunterladen
    </v-btn>

    <v-btn class="mt-2 rounded-xl" x-large @click="$router.push('/')" color="primary">Zurück zu Roody</v-btn>

    <v-dialog v-model="selectingGFMonth" persistent max-width="350">
      <v-card>
        <v-date-picker width="100%" locale="de-CH" min="2022-05-01" :max="lastMonth" @input="downloadGFHours(1)"
          v-model="GFMonth" type="month"></v-date-picker>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="selectingGFMonth = false">Abbrechen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog fullscreen v-model="showPartyDialog">
      <v-card v-if="showPartyDialog">
        <v-card-title class="primary white--text">
          <v-btn class="mr-2" style="background-color: white" icon @click="showPartyDialog = false">
            <v-icon color="black">mdi-chevron-left</v-icon>
          </v-btn>
          Anmeldungen well come FIT Party
        </v-card-title>

        <!-- Overview of total signups and cancellations -->
        <v-container class="px-4 py-2">
          <v-row>
            <v-col cols="12" align="center">
              <v-btn @click="fetchPartySignups" text>Aktualisieren</v-btn>
            </v-col>
            <v-col cols="12" align="center">
              <v-btn @click="exportToExcel" color="primary" text>Excel herunterladen</v-btn>
            </v-col>

            <v-col class="text-h6" cols="6">
              Total Anmeldungen: {{ totalSignups }}
            </v-col>
            <v-col class="text-h6" cols="6">
              Total Abmeldungen: {{ totalCancellations }}
            </v-col>
          </v-row>
        </v-container>

        <!-- Search Field -->
        <v-container class="px-4">
          <v-text-field v-model="searchQuery" label="Suche nach Anmeldungen / Abmeldungen"
            prepend-inner-icon="mdi-magnify" outlined dense></v-text-field>
        </v-container>

        <!-- Tabs for Anmeldungen and Abmeldungen -->
        <v-tabs centered v-model="activeTab" background-color="primary" dark>
          <v-tab>Anmeldungen</v-tab>
          <v-tab>Abmeldungen</v-tab>
        </v-tabs>

        <v-tabs-items v-model="activeTab">
          <!-- Tab Anmeldungen -->
          <v-tab-item>
            <v-list two-line>
              <v-list-item v-for="signup in filteredSignups(true)" :key="signup._id" class="py-2 px-3">
                <v-list-item-content>
                  <v-list-item-title class="text-subtitle-1">
                    {{ signup.user.vorname }} {{ signup.user.nachname }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <div class="d-flex flex-column">
                      <span class="text-caption">
                        Telefon: {{ signup.user.telefon }}
                      </span>
                      <span class="text-caption">
                        E-Mail: {{ signup.user.email }}
                      </span>
                      <span class="text-caption" v-if="signup.plusOne">
                        Plus One: {{ signup.plusOne }}
                      </span>
                      <span class="text-caption">
                        Studio: {{ signup.user.studio_id }}
                      </span>
                    </div>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="mt-1">
                    <small>Letzte Änderung:
                      {{ formatDate(signup.updatedAt) }}</small>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-tab-item>

          <!-- Tab Abmeldungen -->
          <v-tab-item>
            <v-list two-line>
              <v-list-item v-for="signup in filteredSignups(false)" :key="signup._id" class="py-2 px-3">
                <v-list-item-content>
                  <v-list-item-title class="text-subtitle-1">
                    {{ signup.user.vorname }} {{ signup.user.nachname }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <div class="d-flex flex-column">
                      <span class="text-caption">
                        Telefon: {{ signup.user.telefon }}
                      </span>
                      <span class="text-caption">
                        E-Mail: {{ signup.user.email }}
                      </span>
                      <span class="text-caption" v-if="signup.plusOne">
                        Plus One: {{ signup.plusOne }}
                      </span>
                      <span class="text-caption">
                        Studio: {{ signup.user.studio_id }}
                      </span>
                    </div>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="mt-1">
                    <small>Letzte Änderung:
                      {{ formatDate(signup.updatedAt) }}</small>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>

    <!---<v-btn class="mt-2" v-show="userService.hasRight('z_downloadList')" @click="downloadTimestops" color="primary">
				Timestops (Zertifikatspflicht) 2020-2021
			</v-btn>
			<v-btn class="mt-2" v-show="userService.hasRight('z_downloadList')" @click="downloadZeitgutschriften" color="primary">
				Zeitgutschriften 2020-2021
			</v-btn>-->
  </div>
</div>
</template>
<script>
import api from "../../../api";
import $ from "jquery";
import { saveAs } from "file-saver";
import zipcelx from "zipcelx-on-steroids";
import zipcelxBasic from "zipcelx";
import writeXlsxFile from "write-excel-file";
import userService from "../../../services/userService";
import { mapState } from "vuex";
export default {
  data() {
    return {
      selectingGFMonth: false,
      GFMonth: null,
      userService,
      studios: null,
      userList: [],
      userListA: [],
      loading: false,
      showPartyDialog: false,
      activeTab: 0,
      searchQuery: "",
      partySignups: [],
    };
  },
  watch: {
    async showPartyDialog() {
      this.partySignups = [];
      if (this.showPartyDialog) {
        this.fetchPartySignups();
      }
    },
  },
  methods: {
    async downloadMeScanExcel() {

      try {
        this.$toast.success("Liste wird erstellt")
        let entries = await api.fetchMeScanEntries()
        const header = [
          { value: "Datum", type: "string" },
          { value: "Vorname", type: "string" },
          { value: "Nachname", type: "string" },
          { value: "Geburtsdatum", type: "string" },
          { value: "Telefon", type: "string" },
          { value: "E-Mail", type: "string" },
          { value: "PLZ", type: "string" },
          { value: "Stadt", type: "string" },
          { value: "Strasse", type: "string" },
        ];



        const today = this.$moment();

        const data = entries.map((signup) => {

          const bday = this.$moment(signup.user.geburtstag)



          return [
            { value: this.$moment(signup.date).format("DD.MM.YYYY HH:mm"), type: "string" },
            { value: signup.user.vorname, type: "string" },
            { value: signup.user.nachname, type: "string" },
            { value: bday.isValid() ? bday.format("DD.MM.YYYY") : "", type: "string" },
            { value: signup.user.telefon, type: "string" },
            { value: signup.user.email, type: "string" },
            { value: signup.user.plz, type: "string" },
            { value: signup.user.stadt, type: "string" },
            { value: signup.user.adresse, type: "string" },


          ]
        });

        // Kombinieren von Header und Daten
        const config = {
          filename: "allianzMeScanEntries",
          sheet: {
            data: [header, ...data],
          },
        };

        // Excel-Datei mit zipcelx erstellen
        zipcelxBasic(config);
      } catch (e) {
        this.$toast.error("Fehler beim herunterladen")
      }
    },
    exportToExcel() {
      console.log("exporting excel")
      // Struktur der Excel-Daten erstellen
      const header = [
        { value: "Vorname", type: "string" },
        { value: "Nachname", type: "string" },
        { value: "Geburtsdatum", type: "string" },
        { value: "Minderjährig", type: "string" },
        { value: "Mitarbeiter", type: "string" },
        { value: "Telefon", type: "string" },
        { value: "E-Mail", type: "string" },
        { value: "PlusOne", type: "string" },
        { value: "Studio", type: "string" },
        { value: "Angemeldet", type: "string" },
        { value: "Letzte Änderung", type: "string" },
      ];



      const today = this.$moment();

      const data = this.partySignups.map((signup) => {

        const bday = this.$moment(signup.user.geburtstag)
        let isUnderage = ''

        if (bday.isValid()) {
          const age = today.diff(bday, 'years');
          const ageThreshold = 18;
          isUnderage = age < ageThreshold ? "JA" : "NEIN"
        }

        return [
          { value: signup.user.vorname, type: "string" },
          { value: signup.user.nachname, type: "string" },
          { value: bday.isValid() ? bday.format("DD.MM.YYYY") : "", type: "string" },
          { value: isUnderage, type: "string" },
          { value: signup.employee ? 'Ja' : 'Nein', type: "string" },
          { value: signup.user.telefon, type: "string" },
          { value: signup.user.email, type: "string" },
          { value: signup.plusOne ? signup.plusOne : "Keine", type: "string" },
          { value: signup.user.studio_id || "Nicht angegeben", type: "string" },
          { value: signup.signedUp ? "Ja" : "Nein", type: "string" },
          { value: this.formatDate(signup.updatedAt), type: "string" },
        ]
      });

      // Kombinieren von Header und Daten
      const config = {
        filename: "Anmeldungen_well_come_FIT_Party",
        sheet: {
          data: [header, ...data],
        },
      };

      // Excel-Datei mit zipcelx erstellen
      zipcelxBasic(config);
    },
    async fetchPartySignups() {
      try {
        let status = await api.fetchPartySignups();
        this.partySignups = status.signUps;
        this.$toast.success("Anmeldungen aktualisiert");
      } catch (e) {
        this.$toast.error("Fehler beim Laden");
      }
    },
    filteredSignups(signedUpStatus) {
      return this.partySignups
        .filter((signup) => signup.signedUp === signedUpStatus)
        .filter((signup) => {
          const query = this.searchQuery.toLowerCase();

          // Suche auch im plusOne-Feld, wenn vorhanden
          const plusOneMatch = signup.plusOne
            ? signup.plusOne.toLowerCase().includes(query)
            : false;

          return (
            signup.user.vorname.toLowerCase().includes(query) ||
            signup.user.nachname.toLowerCase().includes(query) ||
            signup.user.email.toLowerCase().includes(query) ||
            signup.user.telefon.toLowerCase().includes(query) ||
            plusOneMatch
          );
        })
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    },
    // Funktion zum Formatieren des Datums
    formatDate(date) {
      return new Date(date).toLocaleString();
    },
    downloadGFList() {
      console.log("downloadGFList");
    },
    startPedometer() {
      if (window.flutter_inappwebview)
        window.flutter_inappwebview.callHandler("initializePedometer");
    },
    wcf() {
      if (
        location.host.includes("dev") ||
        location.host.includes("192") ||
        location.host.includes("local") ||
        location.host.includes("wellcomefit") ||
        location.host.includes("wintifit")
      ) {
        return true;
      } else {
        return false;
      }
    },
    async downloadAllianceEntries() {
      let status = await api.getAllianceEntriesExcel();

      if (window.flutter_inappwebview) {
        console.log("FLUTTER IOS DOWNLOAD PDF START");
        var reader = new FileReader();
        reader.readAsDataURL(status);
        let ref = this;
        reader.onloadend = function () {
          var dataUrl = reader.result;
          var base64 = dataUrl.split(",")[1];
          console.log(base64);
          window.flutter_inappwebview.callHandler(
            "blobToBase64Handler",
            base64.toString(),
            "xlsx",
            "Allianz_" + ref.$moment().format("YYYY-MM-DD")
          );
        };
      } else {
        saveAs(status, "Allianz_" + this.$moment().format("YYYY-MM-DD"));
      }
    },
    async downloadGFHours(confirmed = 0) {
      if (confirmed === 0) {
        this.selectingGFMonth = true;
        return false;
      }
      console.log(this.GFMonth);
      let monthAgo = 1;
      if (this.selectingGFMonth) {
        // check how many months ago the 31-01-2021 is with moment.JSON
        monthAgo = this.$moment(this.GFMonth).diff(this.$moment(), "months");
        monthAgo = monthAgo * -1;
        console.log(monthAgo);
      }
      let status = await api.getGFHours(
        this.selectingGFMonth ? monthAgo : null
      );

      if (window.flutter_inappwebview) {
        console.log("FLUTTER IOS DOWNLOAD PDF START");
        var reader = new FileReader();
        reader.readAsDataURL(status);
        let ref = this;
        reader.onloadend = function () {
          var dataUrl = reader.result;
          var base64 = dataUrl.split(",")[1];
          console.log(base64);
          window.flutter_inappwebview.callHandler(
            "blobToBase64Handler",
            base64.toString(),
            "xlsx",
            "StundenGF_" + ref.$moment(ref.GFMonth).format("YYYY-MM-DD")
          );
        };
      } else {
        saveAs(
          status,
          "StundenGF_" + this.$moment(this.GFMonth).format("YYYY-MM-DD")
        );
      }
    },
    async downloadGFHoursOld() {
      this.loading = true;
      let gfHours = await api.fetchGFHours();
      let sheetNames = [];
      let excelData = [];
      for (let studio in gfHours.studioTimeRecording) {
        let preExcelData = [];
        let total = 0;
        for (let instructor in gfHours.studioTimeRecording[studio]) {
          preExcelData.push([
            {
              value: gfHours.studioTimeRecording[studio][instructor].instructor,
              type: String,
              fontWeight: "bold",
              backgroundColor: "#52ae32",
            },
            {
              value: "",
              type: String,
              fontWeight: "bold",
              backgroundColor: "#52ae32",
            },
            {
              value: "",
              type: String,
              fontWeight: "bold",
              backgroundColor: "#52ae32",
            },
          ]);
          gfHours.studioTimeRecording[studio][instructor].courses =
            gfHours.studioTimeRecording[studio][instructor].courses.sort(
              (s, s1) => {
                if (s.date > s1.date) return 1;
                if (s.date < s1.date) return -1;
              }
            );
          gfHours.studioTimeRecording[studio][instructor].courses.forEach(
            (course) => {
              preExcelData.push([
                {
                  value: this.$moment(course.date).format("DD.MM.YY HH:MM"),
                  type: String,
                },
                {
                  value: course.courseName,
                  type: String,
                },
                {
                  value: course.time,
                  type: Number,
                },
              ]);
            }
          );
          preExcelData.push([
            {
              value: "Total",
              fontWeight: "bold",
              type: String,
            },
            {
              value: "",
              type: String,
            },
            {
              value: gfHours.studioTimeRecording[studio][instructor].time,
              type: Number,
              fontWeight: "bold",
            },
          ]);
          total += gfHours.studioTimeRecording[studio][instructor].time;
          preExcelData.push([]);
        }
        sheetNames.push(studio);
        preExcelData.push([
          {
            value: "Total " + studio + " (ohne Team)",
            fontWeight: "bold",
            type: String,
          },
          {
            value: "",
            type: String,
          },
          {
            value: total,
            type: Number,
            fontWeight: "bold",
          },
        ]);
        excelData.push(preExcelData);

        preExcelData = [];
        sheetNames.push(studio + " TEAM");
        for (let team in gfHours.studioTeamTimeRecording) {
          let prePreExcelData = [];
          let found = false;
          prePreExcelData.push([
            {
              value: team,
              type: String,
              fontWeight: "bold",
              backgroundColor: "#52ae32",
            },
            {
              value: "",
              type: String,
              fontWeight: "bold",
              backgroundColor: "#52ae32",
            },
            {
              value: "",
              type: String,
              fontWeight: "bold",
              backgroundColor: "#52ae32",
            },
          ]);
          gfHours.studioTeamTimeRecording[team].courses =
            gfHours.studioTeamTimeRecording[team].courses.sort((s, s1) => {
              if (s.date > s1.date) return 1;
              if (s.date < s1.date) return -1;
            });
          gfHours.studioTeamTimeRecording[team].courses.forEach((course) => {
            if (course.studio_id.toString() === studio.toString()) {
              found = true;
              total += course.time;
              prePreExcelData.push([
                {
                  value: this.$moment(course.date).format("DD.MM.YY HH:MM"),
                  type: String,
                },
                {
                  value: course.courseName,
                  type: String,
                },
                {
                  value: course.time,
                  type: Number,
                },
              ]);
            }
          });
          prePreExcelData.push([
            {
              value: "Total",
              fontWeight: "bold",
              type: String,
            },
            {
              value: "",
              type: String,
            },
            {
              value: gfHours.studioTeamTimeRecording[team].time,
              type: Number,
              fontWeight: "bold",
            },
          ]);
          prePreExcelData.push([]);
          if (found) {
            preExcelData = preExcelData.concat(prePreExcelData);
          }
        }
        preExcelData.push([
          {
            value: "Total " + studio + " (mit Team)",
            fontWeight: "bold",
            type: String,
          },
          {
            value: "",
            type: String,
          },
          {
            value: total,
            type: Number,
            fontWeight: "bold",
          },
        ]);
        excelData.push(preExcelData);
      }
      console.log(excelData);
      let ref = this;

      writeXlsxFile(excelData, {
        sheets: sheetNames,
        fileName: "GFStunden.xlsx",
      }).then((d) => {
        ref.loading = false;
      });
    },
    downloadMembercards() {
      api.getMembercards().then(async (data) => {
        let exceldata = [];
        exceldata.push([
          {
            value: "Datum",
            type: "string",
          },
          {
            value: "Vorname",
            type: "string",
          },
          {
            value: "Name",
            type: "string",
          },
          {
            value: "E-Mail",
            type: "string",
          },
          {
            value: "Studio",
            type: "string",
          },
          {
            value: "Geschlecht",
            type: "string",
          },
          {
            value: "Geburtstag",
            type: "string",
          },
          {
            value: "ABO Angefrage",
            type: "string",
          },
          {
            value: "ABO angefragt Datum",
            type: "string",
          },

          {
            value: "ABO Paket",
            type: "string",
          },
          {
            value: "ABO gelöst",
            type: "string",
          },
          {
            value: "Tag 1",
            type: "string",
          },
          {
            value: "Tag 2",
            type: "string",
          },
          {
            value: "Tag 3",
            type: "string",
          },
          {
            value: "Tag 4",
            type: "string",
          },
          {
            value: "Tag 5",
            type: "string",
          },
          {
            value: "Tag 6",
            type: "string",
          },
          {
            value: "Tag 7",
            type: "string",
          },
          {
            value: "Tag 8",
            type: "string",
          },
          {
            value: "Tag 9",
            type: "string",
          },
          {
            value: "Tag 10",
            type: "string",
          },
        ]);
        let studio_list = [];
        studio_list["ot"] = "Livestream";
        studio_list["frauenfeld"] = "Frauenfeld";
        studio_list["netstal"] = "Netstal";
        studio_list["buelach"] = "Bülach";
        studio_list["stgallenwest"] = "St. Gallen West";
        studio_list["stgallenost"] = "St. Gallen Ost";
        studio_list["stgalleneinstein"] = "St. Gallen Einstein";
        studio_list["dietlikon"] = "Dietlikon";
        studio_list["winterthur"] = "Winterthur";
        studio_list["pfungen"] = "Pfungen";
        for (let user of data) {
          exceldata.push([
            {
              value: this.$moment(user.tendays_date * 1000).format(
                "YYYY-MM-DD"
              ),
              type: "string",
            },
            {
              value: user.vorname,
              type: "string",
            },
            {
              value: user.nachname,
              type: "string",
            },
            {
              value: user.email,
              type: "string",
            },
            {
              value: studio_list[user.studio_id],
              type: "string",
            },
            {
              value: user.geschlecht,
              type: "string",
            },
            {
              value: user.geburtstag,
              type: "string",
            },
            {
              value: user.abo_anfrage,
              type: "string",
            },
            {
              value: user.abo_anfrage_datum
                ? this.$moment(user.abo_anfrage_datum * 1000).format(
                  "YYYY-MM-DD"
                )
                : "",
              type: "string",
            },
            {
              value: user.abo_anfrage_paket ? user.abo_anfrage_paket : "",
              type: "string",
            },
            {
              value: user.abo ? "Ja" : "Nein",
              type: "string",
            },
            {
              value: JSON.stringify(user.days[1]),
              type: "string",
            },
            {
              value: JSON.stringify(user.days[2]),
              type: "string",
            },
            {
              value: JSON.stringify(user.days[3]),
              type: "string",
            },
            {
              value: JSON.stringify(user.days[4]),
              type: "string",
            },
            {
              value: JSON.stringify(user.days[5]),
              type: "string",
            },
            {
              value: JSON.stringify(user.days[6]),
              type: "string",
            },
            {
              value: JSON.stringify(user.days[7]),
              type: "string",
            },
            {
              value: JSON.stringify(user.days[8]),
              type: "string",
            },
            {
              value: JSON.stringify(user.days[9]),
              type: "string",
            },
            {
              value: JSON.stringify(user.days[10]),
              type: "string",
            },
          ]);
        }
        const config = {
          filename: "Membercards",
          sheet: {
            data: exceldata,
          },
        };

        zipcelxBasic(config);
      });
    },
    loadUserList() {
      api
        .getAllUserList()
        .then(async (data) => {
          this.userList = data.user;
          let studio = null;

          let st_id = await api.getUserStudio().catch((e) => {
            window.app.$root.$children[0].checkStudio();
          });
          st_id = st_id.studio.code;

          let userlistA_tmp = [];
          for (let ul of data.user) {
            if (ul.studio_id == st_id) {
              userlistA_tmp.push(ul);
            }
          }
          this.userListA = userlistA_tmp;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    downloadKampagne() {
      api.getWeiterempfehlungen().then((data) => {
        const config = {
          filename: "Lead_Liste",
          sheet: {
            data: data,
          },
        };

        zipcelxBasic(config);
      });
    },
    downloadTimestops() {
      if (this.userService.hasRight("z_downloadList")) {
        let dt = null;
        api.getTimestops().then((data) => {
          let exceldata = [];
          exceldata.push([
            {
              value: "Datum",
              type: "string",
            },
            {
              value: "Vorname",
              type: "string",
            },
            {
              value: "Name",
              type: "string",
            },
            {
              value: "E-Mail",
              type: "string",
            },
            {
              value: "Studio",
              type: "string",
            },
            {
              value: "Variante",
              type: "string",
            },
            {
              value: "Bis",
              type: "string",
            },
          ]);

          let studio_list = [];
          studio_list["5f326a24ae8863640c859e75"] = "Frauenfeld";
          studio_list["5f3c1241d91ddb464c2648b0"] = "Netstal";
          studio_list["5f3c30afd91ddb464c2648b1"] = "Bülach";
          studio_list["5f3c30bfd91ddb464c2648b2"] = "St. Gallen West";
          studio_list["5f3c30d0d91ddb464c2648b3"] = "St. Gallen Ost";
          studio_list["5f3c30e1d91ddb464c2648b4"] = "St. Gallen Einstein";
          studio_list["5f3c30f2d91ddb464c2648b5"] = "Dietlikon";

          studio_list["5f3d482d5dd7955ad48de5e4"] = "Winterthur";
          studio_list["5f3d48195dd7955ad48de5e3"] = "Pfungen";

          for (let ts of data) {
            dt = new Date(ts.created * 1000);
            dt =
              dt.getDate() +
              "." +
              (dt.getMonth() + 1) +
              "." +
              dt.getFullYear() +
              " " +
              (dt.getHours() < 10 ? "0" + dt.getHours() : dt.getHours()) +
              ":" +
              (dt.getMinutes() < 10 ? "0" + dt.getMinutes() : dt.getMinutes());

            let exceldata_temp = [
              {
                value: dt,
                type: "string",
              },
              {
                value: ts.vorname,
                type: "string",
              },
              {
                value: ts.nachname,
                type: "string",
              },
              {
                value: ts.email,
                type: "string",
              },
              {
                value: studio_list[ts.studio],
                type: "string",
              },
              {
                value: ts.timestop_auswahl,
                type: "string",
              },
            ];
            if (ts.date) {
              exceldata_temp.push({
                value:
                  ts.date.split("-")[2] +
                  "." +
                  ts.date.split("-")[1] +
                  "." +
                  ts.date.split("-")[0],
                type: "string",
              });
            } else {
              exceldata_temp.push({
                value: "",
                type: "string",
              });
            }
            exceldata.push(exceldata_temp);
          }
          const config = {
            filename: "Timestops",
            sheet: {
              data: exceldata,
            },
          };

          zipcelxBasic(config);
        });
      }
    },
    downloadZeitgutschriften() {
      if (this.userService.hasRight("z_downloadList")) {
        let dt = null;
        api.getZeitgutschriften().then((data) => {
          let exceldata = [];
          exceldata.push([
            {
              value: "Datum",
              type: "string",
            },
            {
              value: "Vorname",
              type: "string",
            },
            {
              value: "Name",
              type: "string",
            },
            {
              value: "E-Mail",
              type: "string",
            },
            {
              value: "Studio",
              type: "string",
            },
            {
              value: "Art",
              type: "string",
            },
          ]);
          let zeitgutschrift = [];
          zeitgutschrift[1] =
            "SOLIDARITÄTS-ANGEBOT: Ich verzichte auf die Zeitgutschrift.";
          zeitgutschrift[2] =
            "HALBE-HALBE: Ich möchte nur die Hälfte der behördlich verordneten Schliessung als Zeitgutschrift.";
          zeitgutschrift[3] =
            "ALLES-ALLES: Ich möchte die vollständige Zeitgutschrift.";
          zeitgutschrift[4] =
            "GESCHENKT: Ich übertrage meine Zeitgutschrift an eine Person meiner Wahl, die noch nicht im well come FIT trainiert. Das well come FIT legt einen drauf und lässt die Person während dieses Zeitraums mit allen Zusatzpaketen trainieren.";
          zeitgutschrift[5] =
            "STAND BY ME: Ich verzichte auf die Zeitgutschrift und verlängere grad mein Abonnement. Bitte lasst mir die Rechnung zukommen.";
          zeitgutschrift[6] =
            "SOLIDARITÄTS-ANGEBOT: Ich verzichte auf die Zeitgutschrift.";
          zeitgutschrift[7] =
            "HALBE-HALBE: Ich möchte nur die Hälfte des Zeitraums als Zeitgutschrift.";
          zeitgutschrift[8] =
            "ALLES-ALLES: Ich möchte die vollständige Zeitgutschrift.";
          zeitgutschrift[9] =
            "GESCHENKT: Ich übertrage meine Zeitgutschrift an eine Person meiner Wahl, die noch nicht im winti FIT trainiert.";
          zeitgutschrift[10] =
            "STAND BY ME: Ich verzichte auf die Zeitgutschrift und verlängere grad mein Abonnement. Bitte lasst mir die Rechnung zukommen.";

          let studio_list = [];
          studio_list["5f326a24ae8863640c859e75"] = "Frauenfeld";
          studio_list["5f3c1241d91ddb464c2648b0"] = "Netstal";
          studio_list["5f3c30afd91ddb464c2648b1"] = "Bülach";
          studio_list["5f3c30bfd91ddb464c2648b2"] = "St. Gallen West";
          studio_list["5f3c30d0d91ddb464c2648b3"] = "St. Gallen Ost";
          studio_list["5f3c30e1d91ddb464c2648b4"] = "St. Gallen Einstein";
          studio_list["5f3c30f2d91ddb464c2648b5"] = "Dietlikon";

          studio_list["5f3d482d5dd7955ad48de5e4"] = "Winterthur";
          studio_list["5f3d48195dd7955ad48de5e3"] = "Pfungen";

          for (let zg of data) {
            dt = new Date(zg.datum * 1000);
            dt =
              dt.getDate() +
              "." +
              (dt.getMonth() + 1) +
              "." +
              dt.getFullYear() +
              " " +
              (dt.getHours() < 10 ? "0" + dt.getHours() : dt.getHours()) +
              ":" +
              (dt.getMinutes() < 10 ? "0" + dt.getMinutes() : dt.getMinutes());

            exceldata.push([
              {
                value: dt,
                type: "string",
              },
              {
                value: zg.vorname,
                type: "string",
              },
              {
                value: zg.nachname,
                type: "string",
              },
              {
                value: zg.email,
                type: "string",
              },
              {
                value: studio_list[zg.studio],
                type: "string",
              },
              {
                value: zeitgutschrift[zg.zeitgutschrift],
                type: "string",
              },
            ]);
          }
          const config = {
            filename: "Zeitgutschriften",
            sheet: {
              data: exceldata,
            },
          };

          zipcelxBasic(config);
        });
      }
    },
    generateBenutzerList() {
      /*if (this.userService.hasRight('u_downloadUserList')) {
        let exceldata = []
        exceldata.push([
          {
            value: 'ID',
            type: 'string',
          },
          {
            value: 'Vorname',
            type: 'string',
          },
          {
            value: 'Name',
            type: 'string',
          },
          {
            value: 'E-Mail',
            type: 'string',
          },
          {
            value: 'Telefon',
            type: 'string',
          },
        ])
        for (let user of this.userList) {
          exceldata.push([
            {
              value: user._id,
              type: 'string',
            },
            {
              value: user.vorname,
              type: 'string',
            },
            {
              value: user.nachname,
              type: 'string',
            },
            {
              value: user.email,
              type: 'string',
            },
            {
              value: user.telefon,
              type: 'string',
            },
          ])
        }
        const config = {
          filename: 'Benutzerliste_roody',
          sheet: {
            data: exceldata,
          },
        }

        zipcelxBasic(config)
      }
      */
    },
    downloadAlibeyList() {
      api.downloadListCategory("alibey").then(async (entries) => {
        let exceldata = [];

        exceldata.push([
          {
            value: "Datum",
            type: String,
            fontWeight: "bold",
          },
          {
            value: "Studio",
            type: String,
            fontWeight: "bold",
            align: "center",
          },
          {
            value: "An- und Abreisedatum",
            type: String,
            fontWeight: "bold",
            align: "center",
          },
          {
            value: "Übernachtungen",
            type: String,
            fontWeight: "bold",
            align: "center",
          },
          {
            value: "Raum",
            type: String,
            fontWeight: "bold",
            align: "center",
          },
          {
            value: "Preis pro Tag",
            type: String,
            fontWeight: "bold",
            align: "center",
          },
          {
            value: "Gesamtpreis",
            type: String,
            fontWeight: "bold",
            align: "center",
          },
          {
            value: "Personen",
            type: String,
            fontWeight: "bold",
            align: "center",
          },
        ]);
        let preExcel = [];
        entries.forEach((entry) => {
          let subtitle = entry.content.room.subtitle
            ? entry.content.room.subtitle
            : "";
          preExcel = [
            {
              value: new Date(entry.created * 1000).toLocaleDateString("de-CH"),
              type: String,
            },
            {
              value: entry.createdBy.studio_id,
              type: String,
            },
            {
              value:
                entry.content.dateRange[0] +
                " bis " +
                entry.content.dateRange[1],
              type: String,
            },
            {
              value: entry.content.duration.toString(),
              type: String,
            },
            {
              value: entry.content.room.title + " (" + subtitle + ")",
              type: String,
            },
            {
              value: entry.content.room.possibility.price.toString(),
              type: String,
            },
            {
              value: (
                Math.round(entry.content.room.possibility.price + (119 * entry.content.adults) / entry.content.duration) *
                entry.content.duration
              ).toString(),
              type: String,
            },
          ];

          entry.content.form.persons.forEach((person) => {
            preExcel.push({
              value: this.getNewExcelValue(person),
              type: String,
            });
          });
          exceldata.push(preExcel);
        });

        let blob = await writeXlsxFile(exceldata);
        if (window.flutter_inappwebview) {
          var reader = new FileReader();
          console.log("webview detected");

          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            var dataUrl = reader.result;
            var base64 = dataUrl.split(",")[1];

            window.flutter_inappwebview.callHandler(
              "blobToBase64Handler",
              base64.toString(),
              "xlsx",
              "AlibeyBuchungen"
            );
          };
        } else {
          saveAs(blob, `AlibeyBuchungen.xlsx`);
        }
      });
    },
    getNewExcelValue(person) {
      let newExcelValue =
        person.firstName +
        " " +
        person.lastName +
        " " +
        person.birthDayPicker.date +
        " Tshirt: " +
        person.shirtSize;

      person.email
        ? (newExcelValue = newExcelValue += " " + person.email + " | ")
        : (newExcelValue = newExcelValue += " | ");
      return newExcelValue;
    },
    downloadPTList() {
      api.downloadListCategory("personalTraining").then((entries) => {
        let exceldata = [];

        exceldata.push([
          {
            value: "Datum",
            type: String,
            fontWeight: "bold",
          },
          {
            value: "Studio",
            type: String,
            fontWeight: "bold",
            align: "center",
          },
          {
            value: "Vorname",
            type: String,
            fontWeight: "bold",
            align: "center",
          },
          {
            value: "Nachname",
            type: String,
            fontWeight: "bold",
            align: "center",
          },
          {
            value: "E-Mail",
            type: String,
            fontWeight: "bold",
            align: "center",
          },
          {
            value: "Telefon",
            type: String,
            fontWeight: "bold",
            align: "center",
          },
          {
            value: "Möchte kontaktiert werden",
            type: String,
            fontWeight: "bold",
            align: "center",
          },
          {
            value: "Hat angerufen",
            type: String,
            fontWeight: "bold",
            align: "center",
          },
        ]);
        entries.forEach((entry) => {
          exceldata.push([
            {
              value: new Date(entry.created * 1000).toLocaleDateString("de-CH"),
              type: String,
            },
            {
              value: entry.createdBy.studio_id,
              type: String,
            },
            {
              value: entry.createdBy.vorname,
              type: String,
            },
            {
              value: entry.createdBy.nachname,
              type: String,
            },
            {
              value: entry.createdBy.email,
              type: String,
            },
            {
              value: entry.createdBy.telefon,
              type: String,
            },
            {
              value: entry.content.contactMe.toString(),
              type: String,
            },
            {
              value: entry.content.phoneCall.toString(),
              type: String,
            },
          ]);
        });
        writeXlsxFile(exceldata, {
          fileName: "PersonalTraining_Buchungen.xlsx",
        }).then((d) => { });
      });
    },
    downloadOAFFList() {
      api.downloadListCategory("openair-frauenfeld").then((entries) => {
        console.log(entries);
        let exceldata = [];

        exceldata.push([
          {
            value: "Datum",
            type: String,
            fontWeight: "bold",
          },
          {
            value: "Studio",
            type: String,
            fontWeight: "bold",
            align: "center",
          },
          {
            value: "Vorname",
            type: String,
            fontWeight: "bold",
            align: "center",
          },
          {
            value: "Nachname",
            type: String,
            fontWeight: "bold",
            align: "center",
          },
          {
            value: "E-Mail",
            type: String,
            fontWeight: "bold",
            align: "center",
          },
          {
            value: "Telefon",
            type: String,
            fontWeight: "bold",
            align: "center",
          },
        ]);
        entries.forEach((entry) => {
          exceldata.push([
            {
              value: new Date(entry.created * 1000).toLocaleDateString("de-CH"),
              type: String,
            },
            {
              value: entry.createdBy.studio_id,
              type: String,
            },
            {
              value: entry.createdBy.vorname,
              type: String,
            },
            {
              value: entry.createdBy.nachname,
              type: String,
            },
            {
              value: entry.createdBy.email,
              type: String,
            },
            {
              value: entry.createdBy.telefon,
              type: String,
            },
          ]);
        });
        writeXlsxFile(exceldata, {
          fileName: "OpenairFrauenfeld_Ticket_reservierungen.xlsx",
        }).then((d) => { });
      });
    },
    downloadChristmasLeads() {
      api.downloadLeadCategory("christmasLead").then((entries) => {
        let exceldata = [];
        exceldata.push([
          {
            value: "Datum",
            type: String,
            fontWeight: "bold",
          },
          {
            value: "Studio",
            type: String,
            fontWeight: "bold",
            align: "center",
          },
          {
            value: "Vorname",
            type: String,
            fontWeight: "bold",
            align: "center",
          },
          {
            value: "Nachname",
            type: String,
            fontWeight: "bold",
            align: "center",
          },
          {
            value: "E-Mail",
            type: String,
            fontWeight: "bold",
            align: "center",
          },
          {
            value: "Telefon",
            type: String,
            fontWeight: "bold",
            align: "center",
          },
        ]);
        entries.forEach((entry) => {
          let studio = null;
          if (this.studios.filter((e) => e._id == entry.content.studio_id)[0]) {
            studio = this.studios.filter(
              (e) => e._id == entry.content.studio_id
            )[0].name;
          } else {
            studio = "Nicht definiert";
          }
          exceldata.push([
            {
              value: new Date(entry.created * 1000).toLocaleDateString("de-CH"),
              type: String,
            },
            {
              value: studio,
              type: String,
            },
            {
              value: entry.content.vorname,
              type: String,
            },
            {
              value: entry.content.nachname,
              type: String,
            },
            {
              value: entry.content.email ? entry.content.email : "",
              type: String,
            },
            {
              value: entry.content.telefon ? entry.content.telefon : "",
              type: String,
            },
          ]);
        });

        writeXlsxFile(exceldata, {
          fileName: "Weihnachten_Leads.xlsx",
        }).then((d) => { });
      });
    },
  },
  updated() { },
  computed: {
    ...mapState("userStore", ["user"]),
    totalSignups() {
      return this.partySignups
        .filter((signup) => signup.signedUp)
        .reduce((total, signup) => {
          return total + 1 + (signup.plusOne ? 1 : 0);
        }, 0);
    },
    // Berechnung der Gesamtzahl der Abmeldungen inklusive plusOne
    totalCancellations() {
      return this.partySignups
        .filter((signup) => !signup.signedUp)
        .reduce((total, signup) => {
          return total + 1 + (signup.plusOne ? 1 : 0);
        }, 0);
    },
    lastMonth() {
      return this.$moment()
        .set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 })
        .subtract(1, "month")
        .format("YYYY-MM-DD");
    },
  },
  created() {
    this.GFMonth = this.lastMonth;
    api.getUserRolle().then((data) => {
      this.rollen = data;
    });

    //this.loadUserList();

    api.getStudios().then((studios) => {
      this.studios = studios;
    });
  },
};
</script>
<style lang="scss" scoped>
.intern {
  margin: auto;
}

.back-navigation {
  background-color: #52ae32;
  color: white;
  padding: 0px 10px;
  font-weight: 500;
  display: flex;
  margin-bottom: 20px;

  span {
    margin-top: auto;
    margin-bottom: auto;
  }

  a {
    display: flex;
    padding: 5px;
    color: white;

    span {
      font-size: 34px;
    }
  }
}
</style>
