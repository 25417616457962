<template>
	<v-card flat>
		<v-btn @click="downloadMembershipFile">Download Membership</v-btn>
	</v-card>
</template>

<script>
	import api from '@/api'
	export default {
		data() {
			return {
				membership: null,
			}
		},
		async created() {
			this.membership = await api.fetchTACMembercategories()
			// Hier liegt das große Response-Objekt bereits in this.membership vor.
		},
		methods: {
			downloadMembershipFile() {
				// membership als JSON-String
				const jsonStr = JSON.stringify(this.membership)
				// Blob aus dem JSON-String erstellen
				const blob = new Blob([jsonStr], { type: 'application/json' })

				// Temporäre URL für den Download erzeugen
				const url = URL.createObjectURL(blob)

				// Download-Link erzeugen und klicken
				const a = document.createElement('a')
				a.href = url
				a.download = 'membership.json' // oder beliebiger Dateiname
				a.click()

				// URL wieder freigeben
				URL.revokeObjectURL(url)
			},
		},
	}
</script>
