<template>
    <v-app :style="cssProps">
        <shop-cart></shop-cart>
        <v-app-bar elevation="0" scroll-target="#scrolling" app class="app-bar mx-auto" prominent height="110px"
            color="white" v-if="
                $route.matched &&
                $route.matched[0] &&
                $route.matched[0].name !== 'dashboardHome' &&
                isAuthenticated &&
                $route.name !== 'courseListExternal' &&
                $route.name !== 'userProfile' &&
                $route.name !== 'userPayments' &&
                $route.name !== 'userTimestop' &&
                $route.name !== 'userPurchases' &&
                $route.name !== 'campaignTemplateCreator' &&
                $route.name !== 'contractCreatorExtern' &&
                $route.name !== 'contractCreatorExternForm' &&
                $route.name !== 'Landing' &&
                $route.name !== 'courseListWeeklyClean' &&
                $route.name !== 'WorkoutCreate' &&
                $route.name !== 'WorkoutEdit' &&
                $route.name !== 'EinsteinSaunaContest' &&
                $route.name !== 'kfjasdklfjakldsfjklsf' &&
                $route.matched[0].name !== 'shopMainpage' &&
                $route.matched[0].name !== 'nutritionMainpage' &&
                !$route.meta.hideAppBar
            " shrink-on-scroll>
            <v-row class="mx-auto" style="max-width: 1100px" no-gutters>
                <v-col cols="8" class="d-flex align-center"
                    :class="{ 'justify-start': !isShrunk, 'justify-start': isShrunk }">
                    <div v-if="!isShrunk" class="Home__greeting">
                        <v-card-subtitle class="Home__greeting-subtitle text-black py-0 px-1">
                            Hey {{ userFirstName }},
                        </v-card-subtitle>
                        <v-card-title class="Home__greeting-title h6 pb-0 px-1">
                            Bist Du bereit für
                            <br />
                            dein heutiges
                            <span class="Home__greeting-title-training">Training</span>
                            ?
                        </v-card-title>
                    </div>
                    <span class="navbarTitle" v-else-if="$route.meta && $route.meta.navbarTitle">
                        {{ $route.meta.navbarTitle }}
                    </span>
                </v-col>
                <v-col v-if="false" cols="2" class="d-flex align-center"
                    :class="{ 'justify-center': !isShrunk, 'justify-end': isShrunk }"></v-col>
                <v-col cols="4" class="avatar d-flex align-center justify-end">
                    <div class="mr-2" @click="$router.push('/panel')" v-if="userService.hasRight('ab_create')">
                        <v-icon color="primary" x-large>mdi-alpha-p-box</v-icon>
                    </div>
                    <div v-else-if="!alibeyStudio" class="Home__greeting-gift mr-2">
                        <v-img @click="$router.push('/referral')" :height="isShrunk ? '45px' : '60px'"
                            :width="isShrunk ? '45px' : '60px'" src="/img/icons/giftGreen.gif"></v-img>
                    </div>
                    <v-menu content-class="notificationBell" v-model="notificationBell" :close-on-content-click="false"
                        bottom max-height="40vh" :nudge-width="200" offset-x offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-badge v-if="userPicture" :value="!allNotificationsRead" color="red" dot offset-x="15"
                                offset-y="15">
                                <v-avatar v-bind="attrs" v-on="on" :height="isShrunk ? '45px' : '60px'"
                                    :width="isShrunk ? '45px' : '60px'">
                                    <img :src="'/img/profilbilder' + userPicture" alt="John" />
                                </v-avatar>
                            </v-badge>
                            <v-badge v-else :value="!allNotificationsRead" color="red" dot offset-x="15" offset-y="15">
                                <v-avatar v-bind="attrs" v-on="on" :height="isShrunk ? '45px' : '60px'"
                                    :width="isShrunk ? '45px' : '60px'" color="primary">
                                    <v-icon dark>mdi-account-circle</v-icon>
                                </v-avatar>
                            </v-badge>
                        </template>

                        <v-card>
                            <v-row no-gutters>
                                <v-col cols="6">
                                    <v-btn v-if="notifications.length > 0" @click="markAllNotificationsAsRead()" small
                                        color="red" rounded-lg elevation="0"
                                        class="mr-auto ml-2 mt-2 mb-2 d-flex text-white">
                                        <v-icon>mdi-forum-remove</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="6">
                                    <v-btn @click="$router.push('/user')" small color="primary" rounded-lg elevation="0"
                                        class="ml-auto mr-2 mt-2 mb-2 d-flex">
                                        zum Profil
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <v-list>
                                <v-list-item class="d-flex flex-column">
                                    <v-list-item-content v-show="showBellPush">
                                        <v-btn color="primary" @click="showPushInit = true">Push aktivieren</v-btn>
                                    </v-list-item-content>
                                    <v-list-item-content v-if="notifications.length === 0">
                                        <v-list-item-subtitle>
                                            Du hast noch keine Benachrichtigungen
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-content @click="notificationHandler(notification)"
                                        v-for="(notification, i) of notifications" :key="i + 'notification'"
                                        class="noticiation__item d-flex" style="width: 100%"
                                        :class="{ active: !notification.read }">
                                        <v-list-item-subtitle style="font-size: 0.8rem">
                                            {{ $moment(notification.createdAt).format('DD.MM.YY HH:mm') }}
                                            Uhr
                                        </v-list-item-subtitle>
                                        <v-list-item-title>
                                            {{ shorten(notification.title, 30) }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>{{ notification.text }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-menu>
                </v-col>
            </v-row>
        </v-app-bar>

        <v-sheet :style="$route.meta.hideAppBar ||
            $route.name === 'login' ||
            ($route.matched &&
                $route.matched[0] &&
                ($route.matched[0].name === 'shopMainpage' || $route.matched[0].name === 'nutritionMainpage')) ||
            $route.name === 'kfjasdklfjakldsfjklsf' ||
            $route.name === 'forgot' ||
            $route.name === 'campaignTemplateCreator' ||
            $route.name === 'courseListWeeklyClean'
            ? 'max-width: unset !important'
            : $route.matched && $route.matched[0] && $route.matched[0].name === 'dashboardHome'
                ? $vuetify.breakpoint.mobile
                    ? 'background-color: #343541;'
                    : 'background-color: #343541;'
                : 'background-color: white;'
            " id="scrolling" @scroll="handleScroll" class="overflow-y-auto" height="100vh">
            <v-container style="margin-top: 90px" class="fullHeight" :style="$route.meta.fullWidth
                ? 'max-width: unset !important; margin-top: 0'
                : $route.name === 'login' ||
                    $route.name === 'kfjasdklfjakldsfjklsf' ||
                    $route.name === 'forgot' ||
                    $route.name === 'campaignTemplateCreator' ||
                    $route.name === 'courseListWeeklyClean'
                    ? 'max-width: unset !important'
                    : $route.matched &&
                        $route.matched[0] &&
                        ($route.matched[0].name === 'shopMainpage' ||
                            $route.meta.hideAppBar ||
                            $route.matched[0].name === 'nutritionMainpage')
                        ? 'margin-top: 0'
                        : $route.matched && $route.matched[0] && $route.matched[0].name === 'dashboardHome'
                            ? $vuetify.breakpoint.mobile
                                ? 'background-color: #343541;'
                                : 'background-color: #343541; max-width: 80vw !important;'
                            : 'background-color: white;'
                " fluid :class="{
                    smallMarginTop: $route.name === 'Home',
                    removeMarginTop: marginTopClass,
                    'pa-0':
                        !isAuthenticated ||
                        $route.meta.hideAppBar ||
                        $route.name === 'Landing' ||
                        $route.name === 'campaignTemplateCreator' ||
                        $route.name === 'WorkoutRun' ||
                        $route.name === 'courseListWeeklyClean' ||
                        $route.name === 'EinsteinSaunaContest',
                }">
                <v-card class="app-container-card elevation-0" :class="{
                    loginCard:
                        $route.name === 'login' ||
                        $route.name === 'forgot' ||
                        $route.name === 'courseListWeeklyClean',
                    'rounded-xl mt-3 elevation-7':
                        !$vuetify.breakpoint.mobile && !$route.name === 'courseListWeeklyClean',
                    'mt-0':
                        $route.name === 'campaignTemplateCreator' ||
                        $route.name === 'login' ||
                        ($route.matched &&
                            $route.matched[0] &&
                            ($route.matched[0].name === 'shopMainpage' ||
                                $route.meta.hideAppBar ||
                                $route.matched[0].name === 'nutritionMainpage')) ||
                        $route.name === 'courseListWeeklyClean',
                    'elevation-0': $vuetify.breakpoint.mobile,
                }">
                    <!-- v-model="loadFromStorePopup" -->
                    <v-dialog v-model="loadFromStorePopup" max-width="450" persistent>
                        <v-card dark>
                            <v-btn large absolute right top icon @click="loadFromStorePopup = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-row class="justify-content-center ma-0">
                                <v-col cols="auto">
                                    <lottie-player mode="normal" style="height: 100px; width: 120px" name="roody_icon"
                                        autoplay src="/img/lottiefiles/roody_animated.json"></lottie-player>
                                </v-col>
                            </v-row>
                            <v-card-title class="text-center" style="word-break: break-word">
                                Jetzt auch im App- und Playstore
                            </v-card-title>
                            <v-row class="justify-content-center ma-0">
                                <v-col cols="auto">
                                    <a title="Play Store Link" target="__blank"
                                        href="https://play.google.com/store/apps/details?id=ch.wellcomefit.roody&gl=US&pli=1">
                                        <v-img class="text-center" width="150" height="50"
                                            src="/img/einmalig/googlePlayBadge.png"></v-img>
                                    </a>
                                </v-col>
                                <v-col cols="6">
                                    <a target="__blank" title="App Store Link"
                                        href="https://apps.apple.com/de/app/roody/id1641472611">
                                        <v-img contain class="text-center" width="150" height="50"
                                            src="/img/einmalig/appstoreBadge.svg"></v-img>
                                    </a>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-dialog>
                    <router-view v-on:checkPopup="checkPopup" v-on:checkVersion="checkVersion()"
                        v-on:fetchNotifications="fetchNotifications()" v-on:showTour="startTour()"
                        v-on:showPushPopUp="showPushInit = true" />
                </v-card>
            </v-container>
        </v-sheet>
        <bottom-navigation v-if="
            isAuthenticated &&
            $route.name !== 'Landing' &&
            $route.name !== 'WorkoutRun' &&
            $route.name !== 'kfjasdklfjakldsfjklsf' &&
            $route.name !== 'courseListWeeklyClean'
        " app></bottom-navigation>
        <!--	<v-footer class="footer" color="primary darken-4" padless app>
			<v-row justify="center" no-gutters>
				<v-btn color="white" text href="/impressum">Impressum</v-btn>
				<v-btn color="white" text href="/datenschutz">Datenschutz</v-btn>
			</v-row>
		</v-footer> -->
        <!-- <v-dialog v-if="popupEvent" v-model="popupEventActive" width="500" persistent>
      <v-card>
        <v-img :src="'/img/event/' + popupEvent.headerImage" contain></v-img>
        <v-card-title> {{ popupEvent.title }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="popupEventActive = false">
            Schliessen
          </v-btn>
          <v-btn color="primary" text @click="pushToPopUpEvent()">
            Hier anmelden
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
        <v-dialog v-model="consentPopup" max-width="800px" :fullscreen="$vuetify.breakpoint.mobile" persistent>
            <consent-declaration-card @savedConsent="savedConsent()" withSave></consent-declaration-card>
        </v-dialog>
        <v-dialog v-model="nonDisclosureAgreementPopup" max-width="800px" :fullscreen="$vuetify.breakpoint.mobile"
            persistent>
            <non-disclosure-agreement @savedConsent="savedNonDisclosureAgreement()" withSave></non-disclosure-agreement>
        </v-dialog>
        <popup-view ref="popup"></popup-view>
        <anamnesebogen ref="anamnesebogen"></anamnesebogen>
        <anamnese-bogen-create @submitted="showGFRecommendations" ref="anamneseBogenRef"></anamnese-bogen-create>
        <!--	<studio-picker ref="studiopicker" v-on:save-studio="saveNewStudio"></studio-picker>-->
        <nfc-scanner v-on:change-visibility-nfc="changeNFCScannerVisibility" :visible="showNFCScanner"></nfc-scanner>
        <studio-selection v-on:studio-picked="studioPicked" v-on:change-visibility="changeStudioSelectionVisibility"
            :visible="showStudioSelection"></studio-selection>
        <init-push v-on:acceptPush="acceptPush" v-on:declinePush="declinePush" :visible="showPushInit"></init-push>
        <div v-show="showcookies && false" class="cookies">
            Diese Website (sowie integrierte Drittanbieter-Tools) verwendet Cookies, die für die volle Funktionalität
            notwendig sind. Sind Sie damit nicht einverstanden, gehen Sie wie auf der Datenschutzseite beschrieben vor.
            <br />
            <router-link to="/datenschutz">zum Datenschutz</router-link>
            <button @click.prevent="saveCookies()">Verstanden</button>
        </div>
        <v-tour :callbacks="tourCallbacks" :options="tourOptions" name="Home" :steps="steps"></v-tour>
        <v-snackbar class="d-flex" :color="color" :timeout="-1" v-model="offline">
            <v-icon color="white">mdi-wifi-off</v-icon>

            <span>{{ snackbarText }}</span>
            <!---<v-btn color="white" text @click="offline = false">Schließen</v-btn>-->
        </v-snackbar>
    </v-app>
</template>
<script>
import { EventBus } from './event-bus'
import StudioPicker from './components/StudioPicker'
import api from './api'
import userService from './services/userService'
import BottomNavigation from './components/BottomNavigation'
import Anamnesebogen from './components/Anamnesebogen'
import anamneseBogenCreate from './components/anamneseBogenCreate'
import studioSelection from './components/studioSelection/newStudioSelection.vue'
import nfcScanner from './components/nfc/nfcScanner.vue'
import initPush from './components/notification/initPush.vue'

import popupView from './components/notification/popupView.vue'
import { eventBus } from '@/plugins/websocketPlugin'
import abortService from './services/abortService'
import { mapGetters, mapActions, mapState } from 'vuex'
import consentDeclarationCard from './components/consentDeclaration/consentDeclarationCard.vue'
import nonDisclosureAgreement from './components/nonDisclosureAgreement/nonDisclosureAgreement.vue'
import shopCart from './views/shop/shopCart.vue'

export default {
    components: {
        shopCart,
        consentDeclarationCard,
        nfcScanner,
        popupView,
        initPush,
        BottomNavigation,
        StudioPicker,
        Anamnesebogen,
        anamneseBogenCreate,
        studioSelection,
        nonDisclosureAgreement,
    },
    data() {
        return {
            abortController: null,
            consentPopup: false,
            isShrunk: false,
            scrollPosition: 0,
            showAnamnesebogen: false,
            color: 'red',
            snackbarText: 'Internetverbindung unterbrochen',
            offline: !navigator.onLine,
            isOnline: navigator.onLine,
            showNFCScanner: false,
            refreshSnackbar: false,
            loadFromStorePopup: false,
            tourCallbacks: {
                onFinish: this.tourFinished,
                onSkip: this.tourSkipped,
            },
            showHelp: false,
            notifications: [],
            tourOptions: {
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Überspringen',
                    buttonPrevious: 'Zurück',
                    buttonNext: 'Weiter',
                    buttonStop: 'Abschliessen',
                },
            },
            steps: [
                {
                    target: '[data-v-step="0"]', // We're using document.querySelector() under the hood
                    header: {
                        title: 'Dein Training',
                    },
                    content: `Deine Trainingserfolge von dieser Woche`,
                },
                {
                    target: '[data-v-step="1"]', // We're using document.querySelector() under the hood
                    header: {
                        title: 'Aktuelles',
                    },
                    content: `Hier siehst du unsere aktuellen Kampagnen`,
                },
                {
                    target: '[data-v-step="2"]', // We're using document.querySelector() under the hood
                    header: {
                        title: 'Group Fitness',
                    },
                    content: 'Die nächsten Group Fitness Kurse',
                },
                {
                    target: '[data-v-step="3"]', // We're using document.querySelector() under the hood
                    header: {
                        title: 'Navigation',
                    },
                    content:
                        'Home, Studioauswahl, Group Fitness, Deine Trainingspläne und vieles mehr. Swipe nach Links, um alle Funktionen zu sehen.',
                },
                {
                    target: '[data-v-step="4"]', // We're using document.querySelector() under the hood
                    header: {
                        title: 'Group Fitness Kursliste',
                    },
                    content: 'Hier kannst Du den Wochentag auswählen',
                    before: (type) =>
                        new Promise((resolve, reject) => {
                            this.$router.push('/kurse')
                            this.setSelectedNavigation('groupFitness')

                            resolve('foo')
                        }),
                },
                {
                    target: '.v-step-5', // We're using document.querySelector() under the hood
                    header: {
                        title: 'Group Fitness Kursliste',
                    },
                    content: 'Kurs anklicken, um mehr zu erfahren',
                },
            ],
            showStudioSelection: false,
            isAdmin: false,
            loadAnamnese: false,
            nonDisclosureAgreement: true,
            nonDisclosureAgreementPopup: false,
            userService: userService,
            isAuthenticated: false,
            standort: '',
            userEmail: '',
            studio_art: '',
            version: '',
            showcookies: false,
            loading_animation: false,
            profilbild: false,
            friendrequests: [],
            newsCounter: 0,
            events: [],
            cssProps: null,
            popupEvent: null,
            popupEventActive: true,
            eventId: null,
            roodyLogoAnimation: null,
            notificationBell: false,
            showPushInit: false,
            hideBell: false,
            userPicture: null,
            userFirstName: null,
            userConsent: true,
        }
    },
    metaInfo() {
        return {
            title: 'roody',

            meta: [
                {
                    name: 'description',
                    content:
                        'Melde dich für Kurse an, verwalte deine Trainingspläne und noch vieles mehr mit unserer neuen App roody',
                },
                {
                    property: 'og:title',
                    content: 'roody | Die ' + this.studio_art + ' Fitness-App',
                },
                { property: 'og:site_name', content: 'roody' },
                { property: 'og:type', content: 'website' },
                { name: 'robots', content: 'index,follow' },
            ],
        }
    },
    sockets: {
        connect: function () {
            console.log('socket connected')
        },
        customEmit: function (data) {
            console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)')
        },
    },
    mounted() {
        eventBus.$on('websocketMessage', this.handleMessage)
    },
    async created() {
        this.abortController = new abortService()
        window.addEventListener('offline', this.handleOffline)
        window.addEventListener('online', this.handleOnline)
        this.setCssProps()
        EventBus.$on('login', this.login)
        window.addEventListener('initPush', this.initPush)

        try {
            const data = await api.renewToken(this.abortController.signal)
            await this.handleRenewToken(data)
            // this.handleNavigationItems()
            await this.fetchAdditionalData()
            this.isAuthenticated = true
            if (!this.userConsent) {
                this.consentPopup = true
            } else if (!this.nonDisclosureAgreement) {
                this.nonDisclosureAgreementPopup = true
            }
        } catch (error) {
            this.handleErrorCase(error)
        }

        if (
            !window.flutter_inappwebview &&
            this.$vuetify.breakpoint.mobile &&
            !this.userService.hasRight('u_search', 'tp_create', 'g_daycare')
        ) {
            if (
                !location.href.includes('/contract') &&
                !location.href.includes('/shop') &&
                !location.href.includes('/olma') &&
                !location.href.includes('/wheel')
            )
                this.loadFromStorePopup = true
            console.log('should be false')
        }
    },
    destroyed: function () {
        window.removeEventListener('initPush', this.initPush)
    },
    computed: {
        ...mapState('navigationStore', ['preSelectedNavigation', 'selectedNavigation']),
        ...mapState('navigationStoreNotPersisted', ['navigationItems', 'navigationVisible']),
        marginTopClass() {
            if (
                this.$route.matched &&
                this.$route.matched[0] &&
                this.$route.matched[0].name !== 'dashboardHome' &&
                this.isAuthenticated &&
                this.$route.name !== 'courseListExternal' &&
                this.$route.name !== 'userProfile' &&
                this.$route.name !== 'userPayments' &&
                this.$route.name !== 'userTimestop' &&
                this.$route.name !== 'userPurchases' &&
                this.$route.name !== 'campaignTemplateCreator' &&
                this.$route.name !== 'contractCreatorExtern' &&
                this.$route.name !== 'contractCreatorExternForm' &&
                this.$route.name !== 'Landing' &&
                this.$route.name !== 'courseListWeeklyClean' &&
                this.$route.name !== 'WorkoutCreate' &&
                this.$route.name !== 'WorkoutEdit' &&
                this.$route.name !== 'EinsteinSaunaContest' &&
                this.$route.name !== 'kfjasdklfjakldsfjklsf'
            )
                return false
            else return true
        },
        roodyGeneral() {
            if (
                location.host.includes('wellcomefit') ||
                location.host.includes('localhost') ||
                location.host.includes('wintifit')
            )
                return true
            return false
        },
        alibeyStudio() {
            let user = JSON.parse(localStorage.getItem('user'))
            let studioCode = null

            if (user && user.user) studioCode = user.user.studio_id

            if (studioCode && (studioCode === 'Alibey' || studioCode === 'alibey' || studioCode === 'ali')) {
                return true
            } else {
                return false
            }
        },
        isAndroid() {
            return navigator.userAgent.toLowerCase().indexOf('android') > -1
        },
        dashboard() {
            return location.pathname.includes('dashboard')
        },
        allNotificationsRead() {
            if (this.notifications.length === 0) return true
            let read = this.notifications.find((e) => !e.read)
            if (read) return false
            return true
        },
        showBellPush() {
            if (localStorage.getItem('pushToken') || this.hideBell) {
                return false
            }

            if (window.flutter_inappwebview) {
                return true
            }
            return false
        },
    },
    updated() {
        if (localStorage.getItem('version')) {
            if (this.version !== localStorage.getItem('version').toString()) {
                this.version = localStorage.getItem('version').toString()
            }
        }
        var textarea = document.getElementsByTagName('textarea')
        for (var i = 0; i < textarea.length; i += 1) {
            textarea[i].setAttribute('autocomplete', 'off')
        }
        var inputs = document.getElementsByTagName('input')
        for (var i = 0; i < inputs.length; i += 1) {
            inputs[i].setAttribute('autocomplete', 'off')
        }
        let elements = document.querySelectorAll('[autocomplete="off"]')

        if (!elements) {
            return
        }

        elements.forEach((element) => {
            element.setAttribute('readonly', 'readonly')
            element.style.backgroundColor = 'inherit'

            setTimeout(() => {
                element.removeAttribute('readonly')
            }, 500)
        })
    },
    beforeDestroy() {
        this.abortController.abort('not needed anymore')
        window.removeEventListener('offline', this.handleOffline)
        window.removeEventListener('online', this.handleOnline)

        eventBus.$off('websocketMessage', this.handleMessage)
        // delete this.$options.sockets.onmessage
    },

    methods: {
        ...mapActions('studioStore', ['setStudios']),

        ...mapActions('navigationStore', ['setSelectedNavigation']),
        ...mapActions('navigationStoreNotPersisted', [
            'toggleNavigationBarVisibility',
            'setNavigationBarText',
            'setNavigationbarVisibility',
        ]),
        ...mapActions('userStore', ['setUserRights', 'setUser']),
        showGFRecommendations(userId) {
            if (this.userService.hasRight('ab_create')) this.$router.push('/gfrecommendations/' + userId)
        },
        async handleRenewToken(data) {
            localStorage.setItem('token', data.token)
            try {
                let studio = await api.getUserStudio()
                // Rest deiner Logik bleibt gleich
            } catch (e) {
                this.showStudioSelection = true
            }
        },
        handleNavigationItems() {
            let foundNav = false
            this.navigationItems.forEach((navigationItem) => {
                if (navigationItem.title === this.selectedNavigation && this.$route.name === 'Home') {
                    foundNav = true
                    this.$router.push(navigationItem.to)
                }
            })
        },
        async fetchAdditionalData() {
            this.loadFriendrequests()
            const newsData = await api.getNewsCount()
            this.newsCounter = newsData.count
            this.fetchNotifications()
            const userData = await api.getUser()
            this.userPicture = userData.user.bild
            this.userFirstName = userData.user.vorname
            localStorage.setItem('user', JSON.stringify({ user: userData.user }))
            this.userEmail = userData.user.email
            this.userConsent = !!userData.user.consent1

            const rightsData = await api.getUserRights()
            localStorage.setItem('userRights', JSON.stringify({ userRights: rightsData }))
            this.setUserRights(JSON.parse(JSON.stringify(rightsData)))
            try {
                const studios = await api.fetchStudios()

                const teams = await api.fetchTeamsForTitle()
                if (Array.isArray(studios) && Array.isArray(teams)) {
                    this.setStudios(
                        studios.concat(teams).map((stud) => {
                            return {
                                _id: stud._id,
                                code: stud.code,
                                name: stud.name,
                                growth: stud.growth,
                                vacationDays: stud.vacationDays,
                                tacPropertyId: stud.tacPropertyId,
                                tacResortId: stud.tacResortId,
                            }
                        })
                    )
                } else {
                    console.log('Either studios or teams is not an array', studios, teams)
                }
            } catch (e) {
                console.log(e)
                this.error = true
            }
        },
        isPathExcluded(path) {
            const excludedPaths = [
                '/forgot',
                '/datenschutz',
                '/impressum',
                '/login',
                '/contract',
                '/shop',
                '/schnupperwoche',
                '/schnupperwochen',
                '/register',
                '/wheel',
            ]

            const excludedPathIncludes = [
                '/contract/create',
                '/timestop',
                '/zeitgutschrift',
                '/pub/',
                '/l/',
                '/adventsgewinnspiel',
                '/adventsverlosung',
                '/zeitgutschrift',
                '/kursliste/',
                '/weihnachten',
                '/r/',
                '/olma',
            ]

            const isPathInList = excludedPaths.includes(path)
            const doesPathInclude = excludedPathIncludes.some((el) => path.includes(el))
            const isReferralPath = path.includes('/referral') && !path.includes('/intern')

            return isPathInList || doesPathInclude || isReferralPath
        },
        handleErrorCase(error) {
            if (!this.isPathExcluded(location.pathname)) {
                if (error.message.includes('aborted') || error.message.includes('Network Error')) {
                    console.log('Request was aborted or network error, not signing out.')
                    return
                }

                this.signOutUser()
            }
        },
        signOutUser() {
            console.log('signing out')
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            this.isAuthenticated = false

            const redirectPath = location.pathname.includes('/bewerten')
                ? '/login?redirect=' + location.pathname.replace('/', '')
                : location.pathname.includes('/weiterempfehlung')
                    ? '/login?forward=weiterempfehlung'
                    : '/login'

            this.$router.push(redirectPath)

            if (!localStorage.getItem('cookies')) {
                this.showcookies = true
            }
        },
        savedConsent() {
            this.consentPopup = false
        },
        savedNonDisclosureAgreement() {
            this.nonDisclosureAgreementPopup = false
        },
        handleMessage(message) {
            console.log('WEBSOCKY message', message)
            if (message.topic === 'notification' && message.type === 'newNotification') {
                this.$toast.success(message.title + ' ' + message.body)
                this.fetchNotifications()
            }
        },
        handleScroll() {
            this.scrollPosition = document.getElementById('scrolling').scrollTop
            // Adjust the threshold value as per your requirement
            this.isShrunk = this.scrollPosition > 35 // Set a threshold value to determine if the bar should be considered shrunk
        },
        handleOffline() {
            this.offline = true
            this.isOnline = false
            this.snackbarText = 'Internetverbindung unterbrochen'
            this.color = 'red'

            // Hier könnte man eine Benachrichtigung anzeigen
        },
        handleOnline() {
            this.offline = false
            this.isOnline = true
            this.snackbarText = 'Internetverbindung wiederhergestellt'
            this.color = 'green'
            // Hier könnte man eine Benachrichtigung schließen oder ausblenden
        },
        setCssProps() {
            var themeColors = {}
            Object.keys(this.$vuetify.theme.themes.light).forEach((color) => {
                themeColors[`--v-${color}`] = this.$vuetify.theme.themes.light[color]
            })
            let pathinami = location.pathname
            if (pathinami.includes('dashboard')) {
                themeColors.backgroundColor = '#343541'
            } else {
                themeColors.backgroundColor = 'white'
            }
            this.cssProps = themeColors
        },
        /*  initWebsocket() {
            let token = localStorage.getItem('token')
            if (token) {
                let server = location.hostname.includes('local') ? 'ws://localhost:4000' : 'wss://app.wellcomefit.ch'

                this.$connect(server + '/api/ws?token=' + token)
            }
        },*/
        checkPopup() {

            if (this.$refs['popup']) this.$refs['popup'].fetchPopups()
        },
        async checkVersion() {
            let version = await api.getVersion().catch((e) => {
                console.log('Failed to load verison')
            })
            version = version.version
            if (!version) this.reloadLocation()
            if (!localStorage.getItem('version')) localStorage.setItem('version', version.toString())

            if (localStorage.getItem('version') !== version.toString()) {
                localStorage.setItem('version', version.toString())
                this.reloadLocation()
            }
        },
        skipLocation() {
            let skipUrls = [
                '/plan',
                '/l/',
                '/login',
                '/register',
                '/referral',
                '/r/',
                '/plan',
                '/schnupperwoche',
                '/forget',
                '/landing',
                '/kursliste',
            ]
            let skip = false

            let href = location.href.toString()
            skipUrls.forEach((s) => {
                if (href.includes(s)) skip = true
            })

            return skip
        },
        reloadLocation() {
            this.refreshSnackbar = false
            if (localStorage.getItem('isApp')) {
                window.flutter_inappwebview.callHandler('deleteCache')
            } else {
                console.log('reloading webpage')
                location.reload()
            }
        },
        tourFinished() {
            localStorage.setItem('tourFinished', this.moment().format())
        },
        tourSkipped() {
            localStorage.setItem('tourSkipped', this.$moment().format())
        },
        startTour(name) {
            /*this.showHelp = false
            if (this.$route.name !== name) this.$router.push({ name: name })

            if (this.$tours['name']) this.$tours['name'].start()*/
        },
        markAllNotificationsAsRead() {
            api.markAllNotificationsAsRead().then(() => {
                this.fetchNotifications()
            })
        },
        async notificationHandler(notification) {
            await api.readNotification(notification._id)

            if (notification.link !== '') {
                this.notificationBell = false
                this.$router.push(notification.link)
            }
            this.fetchNotifications()
        },
        acceptPush() {
            this.showPushInit = false
            if (window.flutter_inappwebview) window.flutter_inappwebview.callHandler('initPush')
        },
        declinePush() {
            localStorage.setItem('initPushCooldown', this.$moment())
            this.showPushInit = false
        },
        initPush() {
            let pushToken = localStorage.getItem('pushToken')
            let deviceInfo = localStorage.getItem('deviceInfo')
            this.hideBell = true
            if (pushToken) api.updatePushToken({ pushToken, deviceInfo })
        },
        animateRoodyIcon(animation) {
            this.roodyLogoAnimation = animation
        },
        showAnamesebogen() {
            this.$refs.anamneseBogenRef.setVisible()
            this.loadAnamnese = true
        },
        pushToPopUpEvent() {
            this.$router.push('/event/' + this.popupEvent._id)
            this.popupEventActive = false
        },
        shorten(text, length = 25) {
            if (text.length > length) text = text.substr(0, length) + '...'
            return text
        },
        getIconStyle() {
            if (location.pathname.includes('/schnupperwoche')) {
                if (location.host.includes('wellcomefit')) {
                    return 'width: 190px !important;height: 56px !important;right: calc(50% - 90px) !important;'
                } else {
                    return 'width: 190px !important;height: 49px !important;padding-top:2px !important;right: calc(50% - 90px) !important;'
                }
            } else {
                return ''
            }
        },
        toHomeScreen() {
            if (this.$route.name !== 'Home') {
                this.setSelectedNavigation('home')
                /*	this.roodyLogoAnimation.stop()
                this.roodyLogoAnimation.play()*/
                if (this.isAuthenticated) {
                    if (!location.pathname.includes('/schnupperwoche')) {
                        this.$router.push('/')
                    }
                } else {
                    if (!location.pathname.includes('/schnupperwoche')) {
                        this.$router.push('/login')
                    }
                }
            }
        },
        async fetchNotifications() {
            let notifications = await api.fetchNotifications()
            this.notifications = notifications
        },
        loadFriendrequests() {
            api.getFriendrequests().then((data) => {
                this.friendrequests = data
            })
        },
        openNav() {
            document.getElementById('mySidenav').style.width = '250px'
            document.getElementById('page-content').style.marginLeft = '250px'
            document.body.style.backgroundColor = 'rgba(0,0,0,0.4)'
            api.getUser().then((data) => {
                this.profilbild = data.user.bild
            })
        },

        /* Set the width of the side navigation to 0 and the left margin of the page content to 0 */
        closeNav() {
            document.getElementById('mySidenav').style.width = '0'
            document.getElementById('page-content').style.marginLeft = '0'
            document.body.style.backgroundColor = 'white'
        },
        saveCookies() {
            localStorage.setItem('cookies', true)
            this.showcookies = false
        },
        async checkStudio(force = false) {
            try {
                let studio = await api.getUserStudio()
                studio = studio.studio
                this.studio_art = studio.art
                this.standort = studio.name
            } catch (e) {
                this.$refs['studiopicker'].show(force)
            }

            this.$refs['studiopicker'].show(force)
        },
        async login(data) {
            console.log('logging in')
            this.eventId = data.eventId
            // token
            // user info
            this.error = ''

            localStorage.setItem('token', data.token)
            localStorage.setItem('user', JSON.stringify(data.user))

            api.getUserRights().then((r) => {
                localStorage.setItem('userRights', JSON.stringify({ userRights: r }))
                this.setUserRights(JSON.parse(JSON.stringify(r)))
            })
            api.getUser().then((u) => {
                this.userPicture = u.user.bild
                this.userFirstName = u.user.vorname
                this.userConsent = !!u.user.consent1
                if (!this.userConsent) {
                    this.consentPopup = true
                }
            })
            api.getUserRolle()
            this.isAuthenticated = true
            this.isAdmin = data.user.isAdmin
            // check Push Token from app
            console.log('hoi', this.userConsent)

            try {
                let studio = await api.getUserStudio()
            } catch (e) {
                this.showStudioSelection = true
            }

            if (this.$route.query.forward) {
                this.$router.push('/' + this.$route.query.forward)
            } else {
                if (this.$route.query.redirect) {
                    this.$router.push('/' + this.$route.query.redirect.split('%2F').join('/'))
                } else {
                    this.$router.push('/')
                }
            }
        },
        changeStudioSelectionVisibility(visible) {
            this.showStudioSelection = visible
            if (!visible) {
                let actualNavigation = this.selectedNavigation
                this.setSelectedNavigation(null)
                this.setSelectedNavigation(actualNavigation)
            }
        },
        changeNFCScannerVisibility(visible) {
            this.showNFCScanner = visible
        },
        studioPicked() {
            this.showStudioSelection = false
            console.log('studio Picked')
            console.log(Object.keys(localStorage))
            //if (localStorage.getItem('isIOS')) {
            //window.flutter_inappwebview.callHandler('deleteCache')
            //} else {
            if (this.eventId) {
                this.$router.push({ path: '/event/' + this.eventId })
            } else {
                this.$router.push('/redirect/' + this.$route.name)
            }
            //}
        },
        saveNewStudio(StudioID) {
            api.pickStudio(StudioID)
                .then((data) => {
                    this.$refs['studiopicker'].hide()
                    if (this.eventId) {
                        this.$router.push({ path: '/event/' + this.eventId })
                    } else {
                        this.$router.push('/redirect/' + this.$route.name)
                    }
                })
                .catch((err) => {
                    this.isAuthenticated = false
                    this.$refs['studiopicker'].hide()
                    this.$router.push('/login')
                })
        },
    },
    watch: {
        consentPopup(val) {
            if (
                !this.$route.matched ||
                (this.$route.matched && this.$route.matched[0] && !this.$route.matched[0].name.includes('dashboard'))
            ) {
                console.log('test2')

                this.setNavigationbarVisibility(!val)
            }

            if (!val && !this.nonDisclosureAgreement) {
                this.nonDisclosureAgreementPopup = true
            }
        },
        nonDisclosureAgreementPopup(val) {
            if (
                !this.$route.matched ||
                (this.$route.matched && this.$route.matched[0] && !this.$route.matched[0].name.includes('dashboard'))
            ) {
                console.log('test')
                this.setNavigationbarVisibility(!val)
            }
        },
        $route(to, from) {
            if (to && from && to.matched && from.matched && to.matched[0] && from.matched[0]) {
                if (
                    (to.matched[0].path.includes('dashboard') && !from.matched[0].path.includes('dashboard')) ||
                    (from.matched[0].path.includes('dashboard') && !to.matched[0].path.includes('dashboard'))
                ) {
                    this.setCssProps()
                }
            }
        },
        showPushInit(value) {
            this.setNavigationbarVisibility(!value)
        },
        showNFCScanner(value) {
            if (value && this.navigationVisible) this.toggleNavigationBarVisibility()

            if (!value && !this.navigationVisible) this.toggleNavigationBarVisibility()
            if (!value && this.navigationVisible) this.setNavigationBarText(null)
        },
        showStudioSelection(value) {
            console.log('SHOW STUDUIO SELEC')
            if (value && this.navigationVisible) this.toggleNavigationBarVisibility()

            if (
                !value &&
                !this.navigationVisible &&
                this.$route.matched &&
                this.$route.matched[0] &&
                this.$route.matched[0].name !== 'dashboardHome'
            )
                this.toggleNavigationBarVisibility()
            if (!value && this.navigationVisible) this.setNavigationBarText(null)
        },
    },
}
</script>

<style lang="scss" scoped>
.smallMarginTop {
    margin-top: 75px !important;
}

.avatar.active::before {
    content: '';
    height: 25px;
    width: 25px;
    background-color: #f00;
    border: 5px solid white;
    border-radius: 50%;
    position: absolute;
    left: 35px;
    top: 2px;
    z-index: 9999;
}

.avatar.activeShrunk::before {
    content: '';
    height: 15px;
    width: 15px;
    background-color: #f00;
    border: 3px solid white;
    border-radius: 50%;
    position: absolute;
    left: 43px;
    top: 1px;
    z-index: 9999;
}

.fullHeight {
    height: 100vh;
}

.navbarTitle {
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: bold;
}

::v-deep .v-toolbar__content {
    display: flex;
    align-items: center;
}

.removeMarginTop {
    margin-top: 0px !important;
}

.Home__greeting {
    display: block;
    position: relative;

    &-referral {
        height: 110px;
        width: 160px;
        background-size: contain;
        background-image: url(/img/kampagne/refer/referAFriend100.png);
        position: absolute;
        top: 0px;
        right: 0px;
    }

    &-subtitle {
        color: black !important;
        letter-spacing: 1px;
        font-weight: bold;
        padding-bottom: 0px;
    }

    &-title {
        letter-spacing: 1px;
        padding-top: 0px;
        display: block;
        font-weight: bold;

        &-training {
            color: orange;
        }
    }
}

::v-deep .fixKeyboardApple ::v-deep .fixKeyboardApple {
    align-self: flex-start;
}

::v-deep .lf-spinner {
    display: none;
}

@font-face {
    font-family: roodyFont;
    src: url(/fonts/Jost-Regular.ttf);
}

.noticiation__item.active {
    padding-left: 25px;
    display: flex !important;
    width: 100% !important;
}

.noticiation__item.active::before {
    content: '';
    height: 7px;
    width: 7px;
    background-color: #f00;
    border-radius: 10px;
    position: absolute;
    left: 20px;
}

.app-bar__lottie.active::after {
    content: '';
    background-color: #f00;
    height: 10px;
    width: 10px;
    top: 5px;
    right: 9px;
    position: absolute;
    border-radius: 10px;
}

.notificationBell .help {
    right: 1px;
    left: auto !important;
    z-index: 99999 !important;
    max-height: calc(100vh - 181px);
    overflow-y: scroll;
}

::v-deep .v-list-item::after {
    min-height: 0px !important;
}

* {
    /* Weise die CSS-Klasse zu */
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

*:not(.material-icons, .fa) {
    font-family: roodyFont !important;
}

.loginCard {
    border-radius: 0px !important;
}

.app-bar {
    &__image {
        display: block;
        margin: auto;
    }

    &__certificate {
        height: 45px;
        width: 45px;
        margin-left: -16px;
        background-image: url('/img/einmalig/roody_certificate.PNG');
        background-size: cover;
    }

    &__lottie {
        display: block;
    }
}

.app-container-card {
    min-height: 60vh;
    background-color: unset !important;
}

.footer {}
</style>
