<template><v-row>
	<v-col align="center" cols="12">
		<v-dialog class="" v-model="timestopRangeEditActive">
			<v-card class="text-center" max-width="860px"
				v-if="timestopRangeEdit && timestopRangeEdit.customerInfos && timestopRangeEdit.tacData">
				<div>
					<v-card-title>Zeitraum anpassen TS von: {{ timestopRangeEdit.customerInfos.firstName + ' ' +
						timestopRangeEdit.customerInfos.lastName }}</v-card-title>
				</div>
				<v-row no-gutters>
					<v-col cols="6">
						<v-date-picker locale="de-CH" label="Startdatum"
							v-model="timestopRangeEdit.tacData.TimeSpan.Start"></v-date-picker>
					</v-col>
					<v-col cols="6">
						<v-date-picker locale="de-CH" label="Enddatum"
							v-model="timestopRangeEdit.tacData.TimeSpan.End"></v-date-picker>
					</v-col>
				</v-row>
				<v-row no-gutters class="justify-content-center">
					<v-col cols="4">
						<v-text-field class="mx-2" label="Gebühr" suffix="CHF"
							v-model="timeRangeEditFee"></v-text-field>
					</v-col>
				</v-row>
				<v-row no-gutters class="justify-content-center pb-2">
					<v-col cols="4">
						<v-btn @click="saveEditedTimestopRange">Zurück an Kunden</v-btn>
					</v-col>
				</v-row>
			</v-card>
		</v-dialog>
		<v-card max-width="800px" dark class="px-5 py-2" flat>
			<v-row no-gutters>
				<v-col cols="9">
					<v-select :loading="loading" :disabled="loading" label="Studios" :items="availableStudios"
						v-model="selectedStudios" multiple></v-select>
				</v-col>
				<v-col cols="3">
					<v-select @change="fetchTimestops()" class="pl-5" label="Status" :items="availableStates"
						v-model="selectedStatus"></v-select>
				</v-col>
				<v-col cols="9">
					<v-text-field label="Suche (Vor- oder Nachname)" v-model="searchFilter"></v-text-field>
				</v-col>
			</v-row>
		</v-card>
	</v-col>

	<v-col align="center" cols="12">
		<v-btn class="mx-2" @click="createTimestopAsCustomer = true">Timestop erstellen Kundenansicht</v-btn>
		<v-btn class="mx-2" v-if="userService.hasRight('d_v_timestops')" @click="showPaymentsForCustomer = true">
			Zahlungen
			Kundenansicht </v-btn>
		<v-btn class="mx-2" v-if="userService.hasRight('d_v_timestops')" @click="showTimestopCalculator = true">
			Timestop
			ausrechnen </v-btn>
	</v-col>
	<v-col align="center" cols="12" v-if="loading">
		<v-progress-circular color="primary" indeterminate></v-progress-circular>
	</v-col>
	<v-col align="center" cols="12" v-if="timestopsFiltered.length === 0">
		<v-alert max-width="800" type="info">
			Mit den ausgewählten Filtern, wurden keine Timestops gefunden.
			{{ timestops.length }} Timestops in anderen Studios gefunden
		</v-alert>
	</v-col>
	<v-col align="center" cols="12">
		<v-expansion-panels style="max-width: 800px" dark>
			<v-expansion-panel v-for="timestop of timestopsFiltered" :key="timestop._id">
				<v-expansion-panel-header>
					<v-card-title class="py-0">
						<v-chip class="mr-2">
							{{ timestop.studio.name + ' ' + $moment(timestop.createdAt).format('DD.MM.YYYY') }}
						</v-chip>
						{{ timestop.customerInfos.firstName + ' ' + timestop.customerInfos.lastName }}
						<v-chip class="ml-3"
							:color="getTimestopDeadlineColor($moment(timestop.updatedAt).add(8, 'days').diff($moment(), 'days'))"
							v-if="timestop.status === 'onHold'">
							Frist:
							{{ $moment(timestop.updatedAt).add(8, 'days').diff($moment(), 'days') + ' Tage' }}
						</v-chip>
						<v-chip class="ml-1" v-if="timestop.allOpenEntries.length">
							<span v-for="(openEntry, index) of timestop.allOpenEntries" :key="openEntry + index">
								{{ index !== 0 ? ' , ' : '' }}
								{{ '1 ' + timestopStateMap[openEntry] }}</span>
						</v-chip>
					</v-card-title>
				</v-expansion-panel-header>

				<v-expansion-panel-content>
					<v-card class="rounded-lg" align="left" max-width="700px" dark flat>
						<v-card-subtitle class="pa-0">
							{{ timestop.customerInfos.email }}
							<br />
							{{ timestop.customerInfos.birthday }}
							<br />
							{{ timestop.customerInfos.street }}
							<br />
							{{ timestop.customerInfos.city + ' ' + timestop.customerInfos.zip }}
							<br />
						</v-card-subtitle>
						<v-card-title class="h5 pl-0">Zeitpunkt</v-card-title>
						Zeitstpanne:
						{{ $moment(timestop.tacData.TimeSpan.Start).format('DD.MM.YYYY') + ' - ' +
							$moment(timestop.tacData.TimeSpan.End).format('DD.MM.YYYY') }}
						<br />
						Dauer: {{ timestop.tacData.Duration + ' Tage' }}

						<v-card-title class="h5 pl-0">Grund</v-card-title>
						<v-row>
							<v-col v-for="(galleryItem, index) of timestop.timestopInfos.gallery" :cols="4"
								:key="galleryItem.src">
								<template v-if="galleryItem.src.endsWith('.pdf')">
									<!-- PDF Vorschau oder Link -->
									<v-card @click="openGalleryItem(timestop._id, index)">
										<v-card-title class="justify-center">Anhang {{ index + 1 }}</v-card-title>
										<v-card-subtitle class="text-center">PDF anzeigen</v-card-subtitle>
									</v-card>
								</template>
								<template v-else>
									<v-card @click="openGalleryItem(timestop._id, index, true)">
										<v-card-title class="justify-center">Bild {{ index + 1 }}</v-card-title>
										<v-card-subtitle class="text-center">Bild anzeigen</v-card-subtitle>
									</v-card>
								</template>
							</v-col>
						</v-row>

						<v-textarea label="Kommentar BÜRO"
							@blur="updateTimestopOfficeComment(timestop._id, timestop.officeComment)"
							v-model="timestop.officeComment"></v-textarea>
						<v-textarea label="Kommentar" disabled v-model="timestop.tacData.Comment"></v-textarea>
						<v-select mandatory :items="tacReasons.Reasons.Reason.map((reason) => {
							return {
								text: reason.ReasonCode,
								value: reason.ReasonID.UniqueID,
							}
						})
							" v-model="timestop.tacData.ReasonId" label="Grund des Unterbruchs" required></v-select>
						<div style="background: white; width: 200px; height: 100px">
							<v-img class="pt-1" width="200px" height="100px" contain
								:src="timestop.customerInfos.signature"></v-img>
						</div>
						<v-row class="mt-2"
							v-if="timestop.status !== 'payed' && timestop.status !== 'accepted' && timestop.status !== 'onHold'">
							<v-col cols="4" class="text-center" @click="() => {
								timestop.withAttest = true
								timestop.officeChangedFee = false
							}
								">
								<div style="border: 3px dashed" class="pa-2"
									:style="timestop.withAttest && !timestop.officeChangedFee ? 'border-color: green' : ''">
									<span class="font-weight-bold">Mit Attest</span>
									<br />
									<span>{{ timestop.fee.withAttest + 'CHF' }}</span>
									<br />
									<br />

									<v-btn icon>
										<v-icon :color="timestop.withAttest ? 'primary' : ''"> mdi-check-circle-outline
										</v-icon>
									</v-btn>
								</div>
							</v-col>
							<v-col cols="4" class="text-center" @click="() => {
								timestop.withAttest = false
								timestop.officeChangedFee = false
							}
								">
								<div style="border: 3px dashed"
									:style="!timestop.withAttest && !timestop.officeChangedFee ? 'border-color: green' : ''"
									class="pa-2">
									<span class="font-weight-bold">Ohne Attest</span>
									<br />
									<span>{{ timestop.fee.withoutAttest + 'CHF' }}</span>
									<br />
									<br />
									<v-btn icon>
										<v-icon
											:color="timestop.withAttest || timestop.officeChangedFee ? '' : 'primary'">
											mdi-check-circle-outline
										</v-icon>
									</v-btn>
								</div>
							</v-col>
							<v-col cols="4" class="text-center" @click="() => {
								timestop.withAttest = false
								timestop.officeChangedFee = true
							}
								">
								<div style="border: 3px dashed"
									:style="timestop.officeChangedFee ? 'border-color: green' : ''" class="pa-2">
									<span class="font-weight-bold">Neue Gebühr</span>
									<v-text-field style="text-align-last: center"
										v-model="timestop.officeChangedFeeAmount" dark type="Number" suffix="CHF"
										label="Gebühr" class="pa-0 ma-0 elevation-0" solo flat
										hide-details></v-text-field>
									<v-btn icon>
										<v-icon :color="timestop.officeChangedFee ? 'primary' : ''">
											mdi-check-circle-outline </v-icon>
									</v-btn>
								</div>
							</v-col>
							<v-col cols="12">
								<v-select v-model="preSelectableRejections" label="Ablehnungsgrund"
									@change="changeRejectionValue($event, timestop)" :items="rejectionItems"></v-select>
							</v-col>
							<v-col cols="12">
								<v-textarea label="Rückmeldung an Kunden" v-model="timestop.officeNote"></v-textarea>
							</v-col>
						</v-row>
						<v-row v-else class="mt-2 mb-5 flex-column">
							<v-card-title v-if="selectedStatus === 'onHold'">
								Offene Zahlung:
								{{ timestop.tacData.IdlePeriodFee.value + ' CHF' }}
								<br />
							</v-card-title>
							<v-card-title v-if="selectedStatus === 'payed'">
								Bezahlt: {{ timestop.tacData.IdlePeriodFee.value + ' CHF' }}
								<br />
							</v-card-title>
							<v-col cols="auto">
								<v-btn v-if="selectedStatus === 'onHold'" color="primary"
									@click="saveToTAC(timestop, 'payed')"> Als
									bezahlt
									markieren </v-btn>
							</v-col>
							<v-col cols="auto">
								<v-btn v-if="selectedStatus === 'onHold'" color="primary"
									@click="sendReminder(timestop)"> Reminder
									senden </v-btn>
							</v-col>
							<v-col cols="auto">
								<v-btn v-if="selectedStatus === 'onHold'" color="red"
									@click="setTimestopToLate(timestop)">
									Zahlungsfrist abgelaufen
								</v-btn>
							</v-col>

							<v-col cols="12" v-if="timestop.officeNote">
								<v-textarea label="Rückmeldung an Kunden" v-model="timestop.officeNote"></v-textarea>
							</v-col>
						</v-row>
						<v-row v-if="timestop.tacPayResponse">
							<v-card-title>
								TAC RECHNUNG (Nr.
								{{ timestop.tacPayResponse.InvoiceNumber }}):
							</v-card-title>
							<v-card v-if="false" @click="openPdf(timestop.tacPayResponse.ReceiptImage.MediaData, true)">
								<v-card-title class="justify-center">Rechnung anzeigen</v-card-title>
							</v-card>
						</v-row>
						<v-card-actions>
							<v-btn color="red" @click="deleteTimestop(timestop)">
								<v-icon>mdi-delete</v-icon>
								Löschen
							</v-btn>
							<v-btn v-if="selectedStatus === 'payed'"
								@click="updateStatusWithoutNotification(timestop, 'onHold')">Zurück in
								Zahlungsaufforderung</v-btn>
							<v-btn v-if="selectedStatus === 'open'" color="info" @click="editTimestopRange(timestop)">
								<v-icon>mdi-pen</v-icon>
								Zeitraum Bearbeiten
							</v-btn>
						</v-card-actions>
						<v-card-actions v-if="selectedStatus === 'open' || selectedStatus === 'payed'">
							<v-btn v-if="selectedStatus !== 'payed'" color="red"
								@click="saveToTAC(timestop, 'rejected')"> Ablehnen
							</v-btn>

							<v-btn v-if="false" color="orange" :disabled="!isAcceptButtonEnabled(timestop)"
								@click="saveToTAC(timestop, 'onHold')"> Zurück an Kunden </v-btn>
							<!-- 

                                    :disabled="isAcceptButtonEnabled(timestop)"
                                -->
							<v-btn color="primary"
								@click="timestop.status === 'open' ? saveToTAC(timestop, 'onHold') : saveToTAC(timestop, 'accepted')">
								{{ timestop.status === 'open' ? 'Zahlungsaufforderung senden' : 'Abschliessen und in TAC
								übertragen' }}
							</v-btn>
							<v-btn color="primary" @click="saveToTAC(timestop, 'acceptedNoSave')"> Abschliessen ohne TAC
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
		<v-dialog max-width="600px" :value="fullSizeImage" persistent @click:outside="fullSizeImage = null">
			<v-card>
				<v-img contain :src="fullSizeImage"></v-img>
			</v-card>
		</v-dialog>
		<v-dialog max-width="500px" v-model="showPaymentsForCustomer">
			<timestop-view-user-payments></timestop-view-user-payments>
		</v-dialog>
		<v-dialog max-width="500px" style="background: white" v-model="createTimestopAsCustomer">
			<timestop-view-user-create-timestop v-if="createTimestopAsCustomer"></timestop-view-user-create-timestop>
		</v-dialog>
		<v-dialog fullscreen style="background: white" v-model="showTimestopCalculator">
			<timestop-calculator @close="showTimestopCalculator = false"
				v-if="showTimestopCalculator"></timestop-calculator>
		</v-dialog>
	</v-col> </v-row></template>
<script>
import api from '@/api'
import timestopViewUserPayments from './timestopViewUserPayments'
import timestopViewUserCreateTimestop from './timestopviewUserCreateTimestop'
import timestopCalculator from './timestopCalculator'
import userService from '../../../services/userService'

export default {
	components: {
		timestopViewUserPayments,
		timestopCalculator,
		timestopViewUserCreateTimestop,
	},
	data() {
		return {
			timestopRangeEdit: {},
			ignoreWatch: false,
			searchFilter: '',
			timestopRangeEditActive: false,
			showTimestopCalculator: false,
			preSelectableRejections: null,
			userService,
			createTimestopAsCustomer: false,
			showPaymentsForCustomer: false,
			tacReasons: null,
			loading: true,
			selectedStudios: [],
			timestops: [],
			fullSizeImage: null,
			selectedStatus: 'open',
			availableStates: [
				{
					text: 'Offen',
					value: 'open',
				},
				{
					text: 'Abgelehnt',
					value: 'rejected',
				},
				{
					text: 'Abgeschlossen',
					value: 'accepted',
				},
				{
					text: 'Zahlungsaufforderung',
					value: 'onHold',
				},
				{
					text: 'Zahlungsfrist abgelaufen',
					value: 'toLate',
				},
				{
					text: 'Bezahlt / Nicht eingetragen',
					value: 'payed',
				},
				{
					text: 'Durch Kunden abgelehnt',
					value: 'rejectedCustomer',
				},
				{
					text: 'Zeitraum angepasst',
					value: 'timeRangeChanged',
				},
			],
			rejectionItems: [
				{
					text: 'Mehr als zwei Timestops',
					value: 0,
					rejectionText: 'Du hast bereits zwei Timestops während der Laufzeit deines Abonnements eingelöst.',
				},
				{
					text: 'Länger als 6 Monate',
					value: 1,
					rejectionText: 'Du hattest bereits einen Timestop über eine Laufzeit von 6 Monaten.',
				},
				{
					text: 'Laufzeit des Attests',
					value: 2,
					rejectionText: 'Die Zeitdauer des Attests entspricht nicht der von dir gewählten Zeitdauer.',
				},
				{
					text: 'Falsches Dokument',
					value: 3,
					rejectionText: 'Leider entspricht das beigefügte Dokument nicht dem Grund für den Timestop.',
				},
			],
			timestopStateMap: {
				open: 'Offen',
				rejected: 'Abgelehnt',
				onHold: 'Zahlungsaufforderung',
				toLate: 'Zahlungsfrist abgelaufen',
				payed: 'Bezahlt / Nicht eingetragen',
				accepted: 'Abgeschlossen',
				acceptedNoSave: 'Abgeschlossen',
				timeRangeChanged: 'Zeitraum angepasst',
			},
			timeRangeEditFee: null,
		}
	},
	created() {
		this.fetchTimestops()
		if (!this.userService.hasRight('d_v_timestops')) {
			this.selectedStatus = 'onHold'
			this.availableStates = [
				{
					text: 'Zahlungsaufforderung',
					value: 'onHold',
				},
				{
					text: 'Zahlungsfrist abgelaufen',
					value: 'toLate',
				},
				{
					text: 'Abgelehnt',
					value: 'rejected',
				},
			]
		}
	},
	methods: {
		async updateStatusWithoutNotification(timestop, status) {
			timestop.status = status
			let updated = await api.updateTimeStopFormEntryNoNotification(timestop)
			this.$toast.success('Timestop wurde in Zahlungsaufforderung verschoben')
			this.fetchTimestops()
		},
		async updateTimestopOfficeComment(id, comment) {
			try {
				await api.updateTimestopOfficeComment(id, comment)
				this.$toast.success('Kommentar abgespeichert')
			} catch (e) {
				this.$toast.error('Fehler beim Abspeichern')
			}
		},
		getTimestopDeadlineColor(days) {
			return days > 3 ? 'green' : days > -1 ? 'orange' : 'red'
		},
		async sendReminder(timestop) {
			if (
				confirm(
					'Folgender Reminder wird gesendet\n Hallo ' +
					timestop.customerInfos.firstName +
					',\nwir möchten dich daran erinnern, dass dein Timestop-Antrag bereits genehmigt wurde. Damit wir den Timestop aktivieren können, fehlt noch die Begleichung der dafür anfallenden Gebühr.\nBitte denke daran, die Zahlung innerhalb der nächsten Tage auf Roody oder direkt in deinem Center vorzunehmen.\n Sportliche Grüsse\nDein well come Fit Team '
				)
			) {
				await api.sendReminderEmail(timestop)
			}
		},
		async openGalleryItem(id, index, image = false) {
			let dataURL = await api.fetchTimestopGalleryItemDataUrl(id, index)
			const link = document.createElement('a')
			link.href = dataURL
			if (image) {
				link.download = 'bild.webp'
			} else {
				link.download = 'dokument.pdf'
			}

			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		},
		changeRejectionValue(val, timestop) {
			timestop.officeNote = this.rejectionItems[val].rejectionText
		},
		async deleteTimestop(timestop) {
			if (confirm('Der Timestop wird unwiederruflich gelöscht. Der Kunde wird nicht kontaktiert')) {
				let deletedTS = await api.deleteTimestopById(timestop._id)
				this.fetchTimestops()
			}
		},
		async editTimestopRange(timestop) {
			this.timestopRangeEdit = JSON.parse(JSON.stringify(timestop))
			this.timeRangeEditFee = JSON.parse(JSON.stringify(timestop.tacData.IdlePeriodFee.value))
			this.timestopRangeEditActive = true
		},
		async saveEditedTimestopRange() {
			if (confirm('Der Timestop geht mit angepasstem Zeitraum / Gebühr zurück an den Kunden und muss neu unterschrieben werden')) {
				console.log(this.timeRangeEditFee)
				let newVal = JSON.parse(JSON.stringify(this.timestopRangeEdit))

				newVal.tacData.IdlePeriodFee.value = this.timeRangeEditFee
				newVal.status = 'timeRangeChanged'
				let upated = await api.updateTimeStopFormEntry(newVal)
				this.fetchTimestops()
				this.timestopRangeEdit = null
			}
		},
		async setTimestopToLate(timestop) {
			if (confirm('Der Timestop wird in die Rubrik Zahlungsfrist abgelaufen geschoben. Der Kunde wird per Mail informiert und kann den Timestop nicht mehr an seinem Handy zahlen.')) {
				let deletedTS = await api.setTimestopToLate(timestop._id)
				this.fetchTimestops()
			}
		},

		isAcceptButtonEnabled(timestop) {
			// Initial Fee has changed

			if (timestop.officeChangedFee && timestop.officeChangedFeeAmount !== timestop.initialFee) return true

			// New Field clicked
			if (timestop.officeChangedFee && timestop.initialState !== 'officeChangedFee') return true
			if (timestop.withAttest && timestop.initialState !== 'withAttest') return true
			if (timestop.initialState === 'withAttest' && !timestop.withAttest) return true
			if (timestop.initialState === 'withoutAttest' && timestop.withAttest) return true
			return false
		},
		checkOfficeChangedFeeEntered(timestop) {
			console.log('uwu')
			if (timestop.officeChangedFee && timestop.officeChangedFeeAmount) this.$toast.error('Setze eine Gebühr fest')
		},
		async saveToTAC(timestop, state) {
			if (state === 'rejected' && !timestop.officeNote) return this.$toast.error('Bitte schreibe dem Kunden eine Rückmeldung bevor du den Timestop ablehnst / zurück gibst')

			timestop.officeChangedFeeAmount = Number(timestop.officeChangedFeeAmount)
			let confirmSpan = 'Der Timestop wird auf ' + this.timestopStateMap[state] + ' gesetzt.'

			let confirmFee = 0
			if (timestop.withAttest) {
				confirmFee = timestop.fee.withAttest
			} else if (timestop.officeChangedFee) {
				confirmFee = timestop.officeChangedFeeAmount
			} else {
				confirmFee = timestop.fee.withoutAttest
			}
			if (state === 'onHold') {
				confirmSpan =
					'Der Timestop wird akzeptiert und geht mit einer Zahlungsaufforderung zurück an den Kunden. \nEr wird noch nicht in TAC eingetragen \nDem Kunden werden ' +
					confirmFee +
					' CHF in Rechnung gestellt.'
			}

			if (state === 'accepted') {
				confirmSpan = 'Der Timestop des Kunden wir nun im TAC eingetragen und automatisch die dazugehörige Rechnung im TAC erstellt..'
			}
			if (state === 'payed') {
				confirmSpan = 'Der Timestop wird auf bezahlt gesetzt und ans Büro weitergeleitet'
			}
			if (confirm(confirmSpan)) {
				timestop.status = state

				timestop.tacData.IdlePeriodFee.value = confirmFee

				try {
					if (state === 'accepted') {
						let tacTimestop = await api.createTACTimestop(timestop)
					}
					if (state === 'acceptedNoSave') timestop.status = 'accepted'
					let upated = await api.updateTimeStopFormEntry(timestop)
					this.fetchTimestops()
				} catch (e) {
					this.$toast.error(e.response.data)
				}
			}
			//let test = await api.createTACTimestop(timestop.tacData)
		},
		async downloadPDF(id) {
			try {
				await api.fetchTimestopPDF(id)
			} catch (e) { }
			console.log(id)
		},
		async fetchTimestops() {
			try {
				this.tacReasons = await api.getTACReasons(1)
				let timestops = await api.fetchTimestopsAdmin(this.selectedStatus)
				this.timestops = await Promise.all(
					timestops.map(async (timestop) => {
						if (!timestop.changeLog) timestop.changeLog = []
						timestop.initialFee = JSON.parse(JSON.stringify(timestop.tacData.IdlePeriodFee.value))
						if (timestop.officeChangedFee) {
							timestop.officeChangedFeeAmount = timestop.tacData.IdlePeriodFee.value || 0
							timestop.initialState = 'officeChangedFee'
						} else {
							timestop.initialState = timestop.withAttest ? 'withAttest' : 'withoutAttest'
						}
						timestop.changeLog.push(
							JSON.parse(
								JSON.stringify({
									state: timestop.initialState,
									fee: timestop.initialFee,
									note: timestop.officeNote,
								})
							)
						)
						return timestop
					})
				)

				this.selectedStudios = [this.userService.getStudio().name]
				this.loading = false
			} catch (e) {
				console.log(e)
				this.$toast.error('Fehler beim laden')
			}
		},
	},
	computed: {
		timestopsFiltered() {
			if (this.timestops.length > 0) {
				return this.timestops.filter((e) => {
					return this.searchFilter
						? this.selectedStudios.includes(e.studio.name) &&
						(e.customerInfos.firstName.toLowerCase().includes(this.searchFilter.toLowerCase()) ||
							e.customerInfos.lastName.toLowerCase().includes(this.searchFilter.toLowerCase()))
						: this.selectedStudios.includes(e.studio.name)
				})
			} else {
				return []
			}
		},
		availableStudios() {
			let names = []
			if (this.timestops.length > 0) {
				names = this.timestops.map((r) => r.studio.name).sort()
			}
			let allStudioNames = new Set(names)
			console.log(this.selectedStudios.length, allStudioNames.size + 1)
			if (this.selectedStudios.length === allStudioNames.size + 1) {
				return [{ text: 'Alle abwählen', value: 'all' }, ...new Set(names)]
			}
			return [{ text: 'Alle auswählen', value: 'all' }, ...new Set(names)]
		},
	},
	watch: {
		selectedStudios(newVal, oldVal) {
			// Find the added studio
			const addedStudio = newVal.find((studio) => !oldVal.includes(studio))

			// Find the removed studio
			const removedStudio = oldVal.find((studio) => !newVal.includes(studio))

			if (this.ignoreWatch) {
				this.ignoreWatch = false
				return
			}

			if (addedStudio) {
				if (addedStudio === 'all' || (addedStudio.value && addedStudio.value === 'all')) {
					this.ignoreWatch = true
					this.selectedStudios = this.availableStudios
				}
			}

			if (removedStudio) {
				if (removedStudio === 'all' || (removedStudio && removedStudio.value && removedStudio.value === 'all')) {
					this.ignoreWatch = true
					this.selectedStudios = []
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.studio-name {
	font-weight: bold;
}

.time {
	font-size: 12px;
	color: #999;
}

.contract-name {
	font-style: italic;
}
</style>
